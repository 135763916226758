import React from 'react'
import { UserPage } from "../site/UserPage"
import { useDispatch, useSelector } from "react-redux"
import { InputUrlsSource } from '@datagrab/datagrab-common/constants'
import {
    fetchAllScrapers,
    FETCH_ALL_SCRAPERS, startScraper, stopScraper, deleteScraper, createScraper
} from "./actions"
import { apiRequestStateSelector } from "../api/selectors"
import { ScrapersListPage } from "./ScrapersListPage"
import ErrorBoundary from "../common/ErrorBoundary"
import { LoadingContainer } from "../common/LoadingContainer"
import { fetchUserProfile } from "../settings/actions"

import { useCurrentProfile } from "../profile/hooks"
import { useScrapers } from "./hooks"
import { useExtensionVersion } from "../hooks"
import { EXTENSION_NOT_INSTALLED } from "../constants"
import { useScraperLiveMonitor } from '../live-events/hooks'
import { processLiveEvent } from '../live-events/actions'


const reqSelector = apiRequestStateSelector([FETCH_ALL_SCRAPERS])

/**
 * Container component for managing the list of scrapers of a user. It wraps the presentational component in an error boundary.
 */
const ScrapersListPageContainer = () => {
    const userId = useSelector(state => !state.profile ? undefined : state.profile.userId)
    const extVersion = useExtensionVersion()
    const [loading,] = useSelector(state => reqSelector(state))
    let scrapers = useScrapers()
    const noScrapers = !scrapers || scrapers.length === 0
    const { subscriptions, bulkCredits, maxParallelScrapers, runningScrapers } = useCurrentProfile()

    // NOTE: We only consider the platform subscription (at most one at the moment)
    const subscription = !subscriptions ? undefined : subscriptions.find(s => s.category === 'platform')

    const parallelLimitReached = runningScrapers >= maxParallelScrapers

    const dispatch = useDispatch()

    // Refresh the user's plan
    React.useEffect(() => {
        dispatch(fetchUserProfile())
    }, [dispatch])

    // Refresh the scrapers on mount
    React.useEffect(() => {
        dispatch(fetchAllScrapers())
    }, [dispatch])

    // Monitor live events
    const handleLiveEvent = React.useCallback( (event) => {
        try {
            const eventData = JSON.parse(event)
            dispatch(processLiveEvent(eventData))
        } catch (e) {
            console.error('Error parsing live event. ', e)
        }
    },  [dispatch])
    useScraperLiveMonitor(userId, handleLiveEvent)

    const handleStart = React.useCallback((id) => {
        dispatch(startScraper(id))
    }, [dispatch])

    const handleStop = React.useCallback((id) => {
        dispatch(stopScraper(id))
    }, [dispatch])

    const handleClone = React.useCallback((details) => {
        const scraper = {
            name: details.name,
            inputUrls: {
                source: InputUrlsSource.LIST,
                list: details.urls
            },
            cloudEnabled: true, // This is mandatory, otherwise it won't show up in the Web Portal
            requestInterval: details.requestInterval,
            maxRequests: details.maxRequests,
            concurrency: details.concurrency,
            rendering: details.rendering,
            template: details.template
        }
        dispatch(createScraper({ scraper }))
    }, [dispatch])

    const handleDelete = React.useCallback((id) => {
        dispatch(deleteScraper(id))
    }, [dispatch])

    return <UserPage activeTab="scrapers" breadcrumbs={noScrapers ? [] : [{ label: 'Scrapers', link: '/scrapers' }]}>
        <ErrorBoundary>
            <LoadingContainer loading={loading}>
                <ScrapersListPage extensionMissing={extVersion === EXTENSION_NOT_INSTALLED}
                    scrapers={scrapers}
                    subscription={subscription}
                    bulkCredits={bulkCredits}
                    maxParallel={maxParallelScrapers}
                    parallelLimitReached={parallelLimitReached}
                    onStart={handleStart}
                    onStop={handleStop}
                    onClone={handleClone}
                    onDelete={handleDelete} />
            </LoadingContainer>

        </ErrorBoundary>
    </UserPage>
}

ScrapersListPageContainer.displayName = 'ScrapersListPageContainer'

export default ScrapersListPageContainer