import {combineReducers} from 'redux'
import {LOCATION_CHANGE} from "connected-react-router"
import {EMAIL_SIGNUP_SUCCESS} from "./actions"
import {SOCIAL_SIGNIN_SUCCESS} from "../auth/actions"


const notify = (state = {
    signupSuccessful: false,
    social: false,
    accountValidationFailed: false
}, action = null) => {
    switch (action.type) {
        case EMAIL_SIGNUP_SUCCESS:
            return {
                ...state,
                signupSuccessful: true,
                social: false
            }

        case SOCIAL_SIGNIN_SUCCESS:
            return {
                ...state,
                signupSuccessful: action.newUser,
                social: true
            }

        case LOCATION_CHANGE:
            return {
                ...state,
                signupSuccessful: false,
                social: false,
                accountValidationFailed: false
            }

        default:
            return state
    }
}

const account = combineReducers({
    notify
})

export default account