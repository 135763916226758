import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box/Box"


const useStyles = makeStyles( theme => ({
    label: {
        fontSize: theme.fontSize[2],
        color: theme.palette.primary[600],

        '&.success': {
            color: theme.palette.success[700],
        }
    },
    value: {
        fontSize: theme.fontSize[7],
        color: theme.palette.primary[700],

        '&.success': {
            color: theme.palette.success[800],
        }
    }
}))

/**
 * Displays a label and a value (the value with a large font). Supports two colors: primary and success.
 */
export const Metric = ({label, value, color = 'primary', ...others}) => {
    const classes = useStyles()

    return <Box display="flex" flexDirection="column" {...others}>
        <span className={`${classes.label} ${color}`}>{label}</span>
        <span className={`${classes.value} ${color}`}>{value}</span>
    </Box>
}