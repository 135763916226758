import {styled} from '@mui/material/styles'


export const LongLink = styled("a")(({ theme }) => ({
    color: theme.palette.primary[700],
    textDecoration: "none",
    width: "100%",
    minWidth: 0,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    "&:hover, &:focus": {
        textDecoration: "underline",
    },
}))