import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Link } from "react-router-dom"
import { Stack } from '@mui/material'


const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%'
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.grey[500],
        fontSize: theme.fontSize[3],
        paddingBottom: theme.spacing(2),

        '&:hover, &:focus': {
            color: theme.palette.grey[800]
        },

        '&.active': {
            color: theme.palette.grey[800],
            borderBottom: `3px solid ${theme.palette.primary[500]}`
        }
    },
    ruler: {
        margin: 0,
        border: 'none',
        height: 1,
        color: theme.palette.grey[200],
        backgroundColor: theme.palette.grey[200],
    }
}))

/**
 * Displays module tabs as links, allows navigating to their respective pages.
 */
export const LinkTabs = ({ tabs, activeTab, className = '', ...others }) => {
    const classes = useStyles()

    return <div className={`${classes.wrapper} ${className}`} {...others}>
        <Stack direction="row" spacing={6}>
            {tabs.map((tab, i) => <Link className={`${classes.link} ${tab.link === activeTab ? 'active' : ''}`} key={i} to={tab.link}>{tab.label}</Link>)}
        </Stack>
        <hr className={classes.ruler} />
    </div>
}