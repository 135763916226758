import {PlanStatus} from '@datagrab/datagrab-common/constants'


export const STATUS_CHIP_INFO = {
    [PlanStatus.OFFERED]: {
        color: 'info',
        label: 'Offered'
    },
    [PlanStatus.ACTIVE]: {
        color: 'success',
        label: 'Active'
    },
    [PlanStatus.SUSPENDED]: {
        color: 'error',
        label: 'Suspended'
    },
    [PlanStatus.CANCELLING]: {
        color: 'error',
        label: 'Canceling'
    },
    [PlanStatus.ENDED]: {
        color: 'default',
        label: 'Ended'
    }
}