import {useSelector} from "react-redux"


/**
 * Hook for getting all scrapers.
 */
export const useScrapers = () => useSelector(state => state.scrapers)

/**
 * Hook for getting a scraper by ID.
 * @param id the id
 */
export const useScraper = (id) => useSelector(state => state.scrapers.find(s => s.id === id))