import React from 'react'
import Typography from "@mui/material/Typography"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import makeStyles from '@mui/styles/makeStyles'
import { Card, CardContent, CardHeader } from '@mui/material'


const useStyles = makeStyles(() => ({
    label: {
        display: 'inline',
        cursor: 'pointer'
    },
    content: {
        width: 500,
        height: 400,
        overflow: 'auto'
    }
}))

/**
 * Displays a text styled as a link which expands a popper rendering the given data object as a JSON, if clicked upon.
 */
export const DetailsPopper = ({ label, data }) => {
    const classes = useStyles()
    const labelRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = React.useCallback(event => {
        if (labelRef.current && labelRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }, [labelRef])

    return <React.Fragment>
        <Typography className={classes.label} variant="body2" color="primary" ref={labelRef} onClick={handleToggle}>{label}</Typography>

        <Popper className={classes.paper} open={open} anchorEl={labelRef.current} role={undefined} disablePortal>
            <ClickAwayListener onClickAway={handleClose}>
                <Card>
                    <CardHeader title="Data" />
                    <CardContent className={classes.content}>
                        <pre>{JSON.stringify(data, null, 2)}</pre>
                    </CardContent>
                </Card>
            </ClickAwayListener>
        </Popper>
    </React.Fragment>
}