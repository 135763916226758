/**
 * Creates a selector for watching the loading state and the error state of one or more API actions.
 * Actions are given by their prefixes (i.e. without the REQUEST, etc. endings). The selector returns an array [loading, error, action],
 * where "loading" defines the loading state, "error" is an object containing the error code and message of the API
 * request that is in error state, and "action" is the current action.
 * In case multiple actions are watching, the resulting array will contain the status of the first API request that is in
 * either loading or error state.
 * If none of the watched actions are in one of these states, null is returned.
 *
 * @param actions an array of action prefixes
 */
export const apiRequestStateSelector = (actions) => (state) => {
    for (const action of actions) {
        if (state.api.loading[action]) {
            return [true, null, action]
        }

        if (state.api.error[action]) {
            return [false, state.api.error[action], action]
        }
    }

    return [false, null, null];
};