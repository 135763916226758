
// Notification messages
export const CREATE_TEMPLATE_SUCCESS_MSG = 'Template created.';
export const CREATE_TEMPLATE_FAILURE_MSG = 'Failed to create template.';
export const CREATE_SCRAPER_SUCCESS_MSG = 'Scraper created.';
export const CREATE_SCRAPER_FAILURE_MSG = 'Failed to create scraper.';
export const START_SCRAPER_SUCCESS_MSG = 'The scraper has started.';
export const START_SCRAPER_FAILURE_MSG = 'Failed to start scraper.';
export const STOP_SCRAPER_SUCCESS_MSG = 'The scraper is being stopped.';
export const STOP_SCRAPER_FAILURE_MSG = 'Failed to stop the scraper.';
export const UPDATE_SCRAPER_SUCCESS_MSG = 'Settings updated.';
export const UPDATE_SCRAPER_FAILURE_MSG = 'Failed to update the settings.';

// Automatically hide the notification after 3 seconds
export const NOTIFY_AUTO_HIDE_DURATION = 3000;

// Whether the notification should be dismissed when clicking away
export const NOTIFY_DISMISS_ON_CLICK_AWAY = false;