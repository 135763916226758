import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { fetchUserActivity } from "./actions"
import { DataTable } from "../common/DataTable"
import {
    ACTION_CANCEL_FIELD_SAVE,
    ACTION_STOP_SCRAPER,
    ACTION_CANCEL_SCRAPER_SETUP,
    ACTION_CANCEL_NEXT_PAGE_LINK,
    ACTION_CLICKED_CREATE_SCRAPER,
    ACTION_CREATE_SCRAPER,
    ACTION_DATA_PREVIEW,
    ACTION_DELETE_FIELD,
    ACTION_DELETE_SCRAPER, ACTION_EXPORT_DATA,
    ACTION_LOGOUT,
    ACTION_MOVE_FIELD,
    ACTION_NEXT_STEP,
    ACTION_PREV_STEP,
    ACTION_SELECTED_TEMPLATE,
    ACTION_SET_TEMPLATE_PROP,
    ACTION_SET_URLS,
    ACTION_START_SCRAPER,
    ACTION_SUBMIT_FIELD_SAVE,
    ACTION_SUBMIT_SCRAPER,
    ACTION_SUBMIT_NEXT_PAGE_LINK,
    ACTION_TOGGLED_PREVIEW,
    ACTION_TOGGLED_TOOL,
    ACTION_UPDATE_SCRAPER
} from "../tracking/constants"
import { DetailsPopper } from "../common/DetailsPopper"
import { Card, CardContent, CardHeader } from '@mui/material'


const getConfig = (data) => ({
    general: data.general,
    fields: data.fields,
    urls: data.urls,
    templates: data.templates
})

const renderAction = (action) => {
    switch (action.type) {

        // Frontend actions (including legacy actions):
        case ACTION_CLICKED_CREATE_SCRAPER:
            return 'Clicked "Create Scraper".'

        case ACTION_CANCEL_SCRAPER_SETUP:
            return 'Cancelled scraper setup.'

        case ACTION_SELECTED_TEMPLATE:
            return <span>Selected <strong>{action.data.template}</strong> template, set <strong>{action.data.url}</strong> as starting URL.</span>

        case ACTION_TOGGLED_TOOL:
            return <span>Toggled <strong>{action.data.tool}</strong> tool.</span>

        case ACTION_PREV_STEP:
            return <span>Went back to previous wizard step (step <strong>{action.data.newStep + 1}</strong>) {(!!action.data.general) ? 'after setting this ' + <DetailsPopper label="config" data={action.data.general} /> : ''}.</span>

        case ACTION_NEXT_STEP:
            return <span>Moved forward to next wizard step (step <strong>{action.data.newStep + 1}</strong>) after setting this <DetailsPopper label="config" data={getConfig(action.data)} />.</span>

        case ACTION_TOGGLED_PREVIEW:
            return <span>Triggered data preview with this <DetailsPopper label="config" data={getConfig(action.data)} />.</span>

        case ACTION_SUBMIT_SCRAPER:
            return <span>Submitted scraper for {(action.data.edit ? 'update' : 'creation')} with this <DetailsPopper label="config" data={getConfig(action.data)} />.</span>

        case ACTION_SUBMIT_FIELD_SAVE:
            return <span>Saved {action.data.field.type} <DetailsPopper label="field" data={action.data.field} />.</span>

        case ACTION_CANCEL_FIELD_SAVE:
            return <span>Cancelled saving {!!action.data.field ? action.data.field.type : ''} {!!action.data.field ? <DetailsPopper label="field" data={action.data.field} /> : 'field'}.</span>

        case ACTION_MOVE_FIELD:
            return <span>Moved <DetailsPopper label="field" data={action.data.field} /> <strong>{action.data.direction}</strong> in the list.</span>

        case ACTION_DELETE_FIELD:
            return <span>Deleted <DetailsPopper label="field" data={action.data.field} />.</span>

        case ACTION_SET_TEMPLATE_PROP:
            switch (action.data.name) {
                case 'paginated':
                    return <span>{(action.data.value ? 'Enabled' : 'Disabled')} pagination.</span>

                case 'maxPages':
                    return <span>Set max pages to <strong>{action.data.value}</strong></span>

                case 'maxPagesValue':
                    return <span>Set value of max pages to <strong>{action.data.value}</strong></span>

                case 'incrementalMode':
                    return <span>{(action.data.value ? 'Enabled' : 'Disabled')} incremental mode.</span>

                case 'incrementalItemOrder':
                    return <span>Set incremental item order to <strong>{(action.data.value === 'asc' ? 'ascending' : 'descending')}</strong>.</span>

                default:
                    return `Set template property '${action.data.name}' to value '${action.data.value}'.`

            }

        case ACTION_SUBMIT_NEXT_PAGE_LINK:
            return <span>Set next page link <DetailsPopper label="config" data={action.data} />.</span>

        case ACTION_CANCEL_NEXT_PAGE_LINK:
            return <span>Cancelled setting next page link.</span>

        case ACTION_SET_URLS:
            return <span>Set <DetailsPopper label="URLs" data={action.data.urls} /> for simple template.</span>

        case ACTION_EXPORT_DATA:
            return action.data.runID === 'latest'
                ? <span>Exported the latest data for scraper <strong>{action.data.scraper}</strong> in <strong>{action.data.format.toUpperCase()}</strong> format.</span>
                : <span>Exported the data of one the previous runs for scraper <strong>{action.data.scraper}</strong> in <strong>{action.data.format.toUpperCase()}</strong> format.</span>

        case ACTION_LOGOUT:
            return <span>Logged out.</span>


        // Backend actions (including legacy actions)
        case ACTION_CREATE_SCRAPER:
            return <span>Created scraper <DetailsPopper label={action.data.name} data={action.data} />.</span>

        case ACTION_UPDATE_SCRAPER:
            return <span>Updated scraper <DetailsPopper label={action.data.new_name} data={action.data} />.</span>

        case ACTION_DELETE_SCRAPER:
            return <span>Deleted <DetailsPopper label="scrapers" data={action.data.scraper_ids} />.</span>

        case ACTION_START_SCRAPER:
            return <span>Started scraper <strong>{action.data.name}</strong>.</span>

        case ACTION_STOP_SCRAPER:
            return <span>Stopped scraper <strong>{action.data.name}</strong>.</span>

        case ACTION_DATA_PREVIEW:
            return <span>Triggered data preview on Backend with this <DetailsPopper label="config" data={action.data.config} />.</span>

        default:
            return null
    }
}

const columns = [
    {
        id: 'created',
        header: 'Timestamp',
        cell: ({ row }) => new Date(row.created * 1000).toLocaleString(),
        sortable: true
    },
    {
        id: 'type',
        header: 'Action',
        cell: ({ row }) => renderAction(row),
        sortable: true
    }
]

/**
 * Displays a history of the current user's activity. Used in the Admin App.
 */
export const UserActivityPanel = ({ userID }) => {

    const dispatch = useDispatch()

    // Refresh the user's activity on mount
    React.useEffect(() => {
        if (!!userID) {
            dispatch(fetchUserActivity(userID))
        }
    }, [dispatch, userID])

    const userActivity = useSelector(state => state.admin.users.current.activity)

    return <Card>
        <CardHeader title="Activity" />
        <CardContent>
            <DataTable size="small" columns={columns} data={userActivity} emptyMessage="There is no activity." />
        </CardContent>
    </Card>
}