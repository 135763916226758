import {FETCH_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_SUCCESS} from "../settings/actions"


const profile = (state = {
    userId: null,
    email: null,
    fullName: null,
    provider: null,
    optedInForMarketing: false,
    bulkCredits: null,
    subscriptions: [],
    maxParallelScrapers: null,
    runningScrapers: null,
    connectedAccounts: null
}, action = null) => {
    switch (action.type) {
        case FETCH_USER_PROFILE_SUCCESS:
            return {
                userId: action.result.userId,
                email: action.result.email,
                fullName: action.result.fullName,
                provider: action.result.provider,
                optedInForMarketing: action.result.optedInForMarketing,
                bulkCredits: action.result.bulkCredits,
                subscriptions: action.result.subscriptions,
                maxParallelScrapers: action.result.maxParallelScrapers,
                runningScrapers: action.result.runningScrapers,
                connectedAccounts: action.result.connectedAccounts
            }

        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                connectedAccounts: action.result.connectedAccounts
            }


        default:
            return state
    }
}

export default profile