import { ScraperStatus, ScraperRunStatus, Rendering, LogMsg } from "@datagrab/datagrab-common/constants"
import { getSpreadsheetUrl } from '@datagrab/datagrab-common/utils'


// The mapping between the statuses of scrapers received from the Backend and their corresponding names to be displayed
export const SCRAPER_STATUS_DISPLAY_NAMES = {
    [ScraperStatus.IDLE]: 'Idle',
    [ScraperStatus.STARTING]: 'Starting',
    [ScraperStatus.RUNNING]: 'Running',
    [ScraperRunStatus.COMPLETED]: 'Completed',
    [ScraperStatus.STOPPING]: 'Stopping',
    [ScraperRunStatus.STOPPED]: 'Stopped',
    [ScraperRunStatus.FAILED]: 'Failed',
    'unknown': 'Unknown'
}

// The threshold for displaying the latest run's status (Completed, Stopped, or Failed) instead of the scraper's (Idle) in the list
export const RECENTLY_FINISHED_RUN_THRESHOLD_SECONDS = 60

// The date/time format for displaying the scrapers' run dates
export const DATE_TIME_FORMAT = 'YYYY-MM-DD, HH:mm'

/* The ID of the Chrome Extension. */
export const CHROME_EXTENSION_ID = 'kfonioidcoibmccipgaihpkjiakcadkh'

// The Google Web Store URL where the extension is published.
export const CHROME_EXTENSION_STORE_URL = 'https://chrome.google.com/webstore/detail/datagrab/kfonioidcoibmccipgaihpkjiakcadkh'

// The type of SSE (server-sent event)
export const ScraperEventType = {
    STARTED: 'started',
    PAGE_SCRAPED: 'page_scraped',
    PAGE_FAILED: 'page_failed',
    COMPLETED: 'completed',
    STOPPED: 'stopped',
    FAILED: 'failed',
}

// Dropdown options for the rendering type (static parsing or JS rendering)
export const RenderingOptions = [
    { value: Rendering.AUTO, label: 'Auto' },
    { value: Rendering.STATIC, label: 'Static' },
    { value: Rendering.DYNAMIC, label: 'Dynamic' },
]

// Options for the scheduling dropdown
export const ScheduleOptions = [
    { value: 'none', label: 'Not Scheduled' },
    { value: 'hourly', label: 'Hourly' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' }
]

// The default interval between consecutive requests
export const DEFAULT_REQUEST_INTERVAL = 1000

export const ROOT_TEMPLATE_ID = 'root'

// The message type that is send to the Chrome extension to load the given scraper, and open a new tab with its setup URL to update it
export const LOAD_SCRAPER_FOR_SETUP = 'load_scraper_for_setup'

export const LogRenderInfo = {
    [LogMsg.SESSION_STARTED]: {
        color: () => '',
        text: () => 'Scraping session started.',
        html: () => 'Scraping session started.'
    },
    [LogMsg.RENDERING_CHOSEN]: {
        color: () => '',
        text: (log) => log.rendering === Rendering.DYNAMIC
            ? 'Going with JS rendering. Each request will consume 2 credits.'
            : 'Going with static rendering. Each request will consume 1 credit.',
        html: (log) => log.rendering === Rendering.DYNAMIC
            ? 'Going with JS rendering. Each request will consume 2 credits.'
            : 'Going with static rendering. Each request will consume 1 credit.'
    },
    [LogMsg.URLS_LOADED_FROM_GS]: {
        color: () => '',
        text: (log) => `${log.rows} input ${log.rows === 1 ? 'URL' : 'URLs'} loaded from sheet ${getSpreadsheetUrl(log.sheetId)}.`,
        html: (log) => <div>{log.rows} input {log.rows === 1 ? 'URL' : 'URLs'} loaded from <a className='link' href={getSpreadsheetUrl(log.sheetId)}>{log.sheetTitle}</a>.</div>
    },
    [LogMsg.PAGE_SCRAPED]: {
        color: (log) => log.rows === 0 ? 'warning' : '',
        text: (log) => `${log.rows} ${log.rows === 1 ? 'row' : 'rows'} scraped from ${log.url}.`,
        html: (log) => <div>{log.rows} {log.rows === 1 ? 'row' : 'rows'} scraped from <a className='link' href={log.url}>{log.url}</a>.</div>
    },
    [LogMsg.PAGE_FAILED]: {
        color: () => 'error',
        text: (log) => `Failed to scrape ${log.url} (code: ${log.code}${log.error ? `, error: ${log.error}` : ''}).`,
        html: (log) => <div>Failed to scrape <a className='link' href={log.url}>{log.url}</a> (code: {log.code}{log.error ? `, error: ${log.error}` : ''}).</div>
    },
    [LogMsg.SCROLLED_DOWN]: {
        color: () => '',
        text: (log) => log.times > 0 ? `Scrolled down to the bottom of the page ${log.times} times.` : 'Could not scroll down. Already at the bottom of the page.',
        html: (log) => log.times > 0 ? `Scrolled down to the bottom of the page ${log.times} times.` : 'Could not scroll down. Already at the bottom of the page.'
    },
    [LogMsg.CLICKED_ELEMENT]: {
        color: () => '',
        text: (log) => log.times > 0 ? `Clicked an element ${log.times} times.` : 'Could not click element as it does not exist.',
        html: (log) => log.times > 0 ? `Clicked an element ${log.times} times.` : 'Could not click element as it does not exist.'
    },
    [LogMsg.SESSION_COMPLETED]: {
        color: () => '',
        text: (log) => `Scraping session completed. Page stats: ${log.pageStats.scraped} scraped, ${log.pageStats.failed} failed. Total credits used: ${log.creditsUsed}`,
        html: (log) => `Scraping session completed. Page stats: ${log.pageStats.scraped} scraped, ${log.pageStats.failed} failed. Total credits used: ${log.creditsUsed}`
    },
    [LogMsg.SESSION_STOPPED]: {
        color: () => '',
        text: (log) => `Scraping session stopped by the user. Page stats: ${log.pageStats.scraped} scraped, ${log.pageStats.failed} failed. Total credits used: ${log.creditsUsed}`,
        html: (log) => `Scraping session stopped by the user. Page stats: ${log.pageStats.scraped} scraped, ${log.pageStats.failed} failed. Total credits used: ${log.creditsUsed}`
    },
    [LogMsg.SESSION_FAILED]: {
        color: () => 'error',
        text: (log) => `Scraping session failed. Error: ${log.error}. Page stats: ${log.pageStats.scraped} scraped, ${log.pageStats.failed} failed. Total credits used: ${log.creditsUsed}`,
        html: (log) => `Scraping session failed. Error: ${log.error}. Page stats: ${log.pageStats.scraped} scraped, ${log.pageStats.failed} failed. Total credits used: ${log.creditsUsed}`
    },
    [LogMsg.GS_DATA_PUSHED]: {
        color: () => '',
        text: (log) => `Pushed data to sheet ${getSpreadsheetUrl(log.sheetId)}.`,
        html: (log) => <div>Pushed data to <a className='link' href={getSpreadsheetUrl(log.sheetId)}>{log.sheetTitle}</a>.</div>
    },
    [LogMsg.GS_DATA_PUSH_FAILED]: {
        color: () => 'error',
        text: (log) => <div>Failed to push data to <a className='link' href={getSpreadsheetUrl(log.sheetId)}>{log.sheetTitle}</a> (error: {log.error}).</div>,
        html: (log) => <div>Failed to push data to <a className='link' href={getSpreadsheetUrl(log.sheetId)}>{log.sheetTitle}</a> (error: {log.error}).</div>
    },
    [LogMsg.NOTIFY_EMAIL_SENT]: {
        color: () => '',
        text: () => 'Notification email sent out to user.',
        html: () => 'Notification email sent out to user.'
    },
    [LogMsg.NOTIFY_EMAIL_SEND_FAILED]: {
        color: () => 'error',
        text: (log) => `Failed to send notification email (error: ${log.error}).`,
        html: (log) => `Failed to send notification email (error: ${log.error}).`
    },
}

export const FINISHED_STATES = [ScraperRunStatus.COMPLETED, ScraperRunStatus.STOPPED, ScraperRunStatus.FAILED]