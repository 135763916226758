import React from 'react'
import {subscriptions} from "@datagrab/datagrab-common/products"
import {ModalDialog} from "../../common/ModalDialog"
import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@mui/material/Box"
import makeStyles from '@mui/styles/makeStyles';
import {Check} from "@mui/icons-material"
import Grid from "@mui/material/Grid"
import {PlanButton} from "./PlanButton"
import { Switch } from '@mui/material'


const products = subscriptions(process.env.NODE_ENV === 'development')


const useStyles = makeStyles( (theme) => ({
    monthlyYearlyWrapper: {
        maxWidth: theme.spacing(12),
        width: '100%',
        fontSize: theme.fontSize[3]
    },
    saveMessage: {
        position: 'absolute',
        color: theme.palette.primary[700],
        fontSize: theme.fontSize[2],
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
    },
    priceValue: {
        fontWeight: 'bold',
        fontSize: theme.fontSize[6],
    },
    featureList: {
        marginTop: 0,
        marginBottom: theme.spacing(4),
        padding: 0,
        listStyleType: 'none',

        '& li': {
            color: theme.palette.grey[900],
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            lineHeight: 2,
        }
    },
    featureCheckmark: {
        color: theme.palette.success[400],
        marginRight: theme.spacing(3)
    }
}))

/**
 * A modal dialog shown for the user to pick a plan for the subscription, along with yearly or monthly billing.
 */
export const PlanSelectionDialog = ({onClose = f=>f, onSubmit = f=>f, submitting}) => {
    const classes = useStyles()
    const [yearlyBilling, setYearlyBilling] = React.useState(true)
    const billingInterval = yearlyBilling ? 'yearly' : 'monthly'
    const [plan, setPlan] = React.useState('business')

    const handleSubmit = () => onSubmit({plan, billingInterval})

    return (
        <ModalDialog title="Choose Plan"
                            closeButton
                            onClose={onClose}
                            maxWidth="md"
                            buttons={[
                                <LoadingButton key="submit_button"
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              onClick={handleSubmit}
                                              loading={submitting}>Go To Checkout</LoadingButton>
                            ]}>

            <Box display="flex" justifyContent="center" mb={7}>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.monthlyYearlyWrapper}>
                    <span>Pay Monthly</span>
                    <Switch
                        checked={yearlyBilling}
                        onChange={(e) => setYearlyBilling(e.target.checked)}
                        color="primary"
                        name="billingInterval"
                        inputProps={{ 'aria-label': 'billing interval' }}
                    />
                    <Box position="relative">Pay Yearly <div className={classes.saveMessage}>Save up to 20%</div></Box>
                </Box>
            </Box>

            <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={7}>
                    <PlanButton name="Personal" active={plan === 'personal'} onClick={() => setPlan('personal')} price={<React.Fragment>
                        <span className={classes.priceValue}>${products.find(p => p.plan === 'personal' && p.billingInterval === billingInterval).monthlyPrice}</span>
                        <span className='muted'>/month</span>
                    </React.Fragment>} />

                    <Box mt={5}>
                        <PlanButton name="Business" active={plan === 'business'} onClick={() => setPlan('business')} price={<React.Fragment>
                            <span className={classes.priceValue}>${products.find(p => p.plan === 'business' && p.billingInterval === billingInterval).monthlyPrice}</span>
                            <span className='muted'>/month</span>
                        </React.Fragment>} />
                    </Box>

                    <Box mt={5}>
                        <PlanButton name="Enterprise" active={plan === 'enterprise'} onClick={() => setPlan('enterprise')} price={<React.Fragment>
                            <span className={classes.priceValue}>${products.find(p => p.plan === 'enterprise' && p.billingInterval === billingInterval).monthlyPrice}</span>
                            <span className='muted'>/month</span>
                        </React.Fragment>} />
                    </Box>
                </Grid>

                <Grid item component="ul" xs={12} md={5} className={classes.featureList}>
                    <li><Check className={classes.featureCheckmark}/>{products.find(p => p.plan === plan).monthlyCredits.toLocaleString()} Cloud Credits</li>
                    <li><Check className={classes.featureCheckmark}/>{products.find(p => p.plan === plan).concurrency} Concurrent Requests</li>
                    <li><Check className={classes.featureCheckmark}/>Pagination</li>
                    <li><Check className={classes.featureCheckmark}/>Detail Pages</li>
                    <li><Check className={classes.featureCheckmark}/>Dynamic Sites</li>
                    <li><Check className={classes.featureCheckmark}/>Export to CSV, JSON</li>
                    <li><Check className={classes.featureCheckmark}/>Proxies</li>
                    <li><Check className={classes.featureCheckmark}/>Data Retention</li>
                </Grid>
            </Grid>
        </ModalDialog>
    );
}