import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {Box} from "@mui/material"
import {Metric} from "./Metric"
import {Button} from "@mui/material"


const useStyles = makeStyles( (theme) => ({
    wrapper: {
        color: theme.palette.primary[900],
        fontSize: theme.fontSize[3]
    }
}))

/**
 * Displays the available bulk credits of the user, allows buying more.
 */
const BulkCreditsPanel = ({bulkCredits, onBuyCredits = f => f}) => {
    const classes = useStyles()

    return <div className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={6}>
            <Metric label="Available Credits" value={bulkCredits ? bulkCredits.toLocaleString() : 0}/>

            <Button variant="outlined" onClick={onBuyCredits}>Buy More Credits</Button>
        </Box>

    </div>
}

export default BulkCreditsPanel