import React from 'react'
import LoadingButton from "@mui/lab/LoadingButton"
import { Stack, TextField } from "@mui/material"
import { ModalDialog } from "../common/ModalDialog"
import { Formik } from "formik"
import { getURLsFromString } from "./utils"


/**
 * A modal dialog for cloning a scraper. It displays a small form to pick a name and a list of URLs for the new scraper.
 *
 * @param scraper the original scraper
 * @param onClose called when the dialog is closed without cloning
 * @param submitting whether the clone operation is submitting
 * @param onSubmit called when the clone operation is submitted
 */
export const CloneScraperDialog = ({ scraper, onClose, submitting, onSubmit }) => {

    const handleValidation = React.useCallback((values) => {
        const errors = {}

        if (!values.name) {
            errors.name = 'Name is required.'
        }

        if (getURLsFromString(values.urls).length === 0) {
            errors.urls = 'At least one URL is required.'
        }

        return errors
    }, [])

    const handleSubmit = React.useCallback((details) => {
        onSubmit({
            ...details,

            // Convert the URL string to a list of URLs
            urls: getURLsFromString(details.urls)
        })
    }, [onSubmit])

    return <Formik validate={handleValidation}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
            name: scraper.name,
            urls: scraper.inputUrls.list.join('\n'),
            requestInterval: scraper.requestInterval,
            maxRequests: scraper.maxRequests,
            concurrency: scraper.concurrency,
            rendering: scraper.rendering,
            template: scraper.template,
        }}
        onSubmit={handleSubmit}>
        {
            ({ values, errors, setFieldValue }) =>
                <ModalDialog form
                    title="Clone Scraper"
                    closeButton
                    onClose={onClose}
                    maxWidth="sm"
                    buttons={[
                        <LoadingButton key="submit_button"
                            type="submit"
                            color="primary"
                            loading={submitting}>Clone</LoadingButton>
                    ]}>
                    <Stack spacing={5}>
                        <TextField
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            error={!!errors.name}
                            helperText={errors.name}
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                        />

                        <TextField autoFocus
                            fullWidth
                            multiline
                            rows={5}
                            name="urls"
                            label="URLs"
                            tabIndex={3}
                            helperText="Separate them with whitespace."
                            error={errors.urls}
                            value={values.urls}
                            onChange={(e) => setFieldValue('urls', e.target.value)} />
                    </Stack>
                </ModalDialog>
        }
    </Formik>
}