import React from 'react'
import { OutputType } from '@datagrab/datagrab-common/constants'
import { Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles'
import LoadingButton from "@mui/lab/LoadingButton"
import { ModalDialog } from "../../../common/ModalDialog"
import { TextField, Button, Stack } from '@mui/material'
import { createSpreadsheet, redirectToGoogleAuth } from '../../../integrations/actions'
import { getSpreadsheetUrl } from '@datagrab/datagrab-common/utils'
import { GoogleSheetLoader } from './GoogleSheetLoader'


const useStyles = makeStyles(theme => ({
    inlinePanel: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary[100],
        width: '100%'
    },
}))


export const ConnectOutputGoogleSheetDialog = ({ connectedAccounts, scraper, onClose = f => f, onSubmit = f => f, submitting }) => {
    const classes = useStyles()

    const googleAccounts = !connectedAccounts ? [] : connectedAccounts.filter(ca => ca.provider === 'google')
    const accOptions = googleAccounts.length === 0
        ? [{ label: '- Select -', value: '-' }]
        : googleAccounts.map(a => ({ label: a.email, value: a.email }))
    const [account, setAccount] = React.useState(scraper.output && scraper.output.type === OutputType.GOOGLE_SHEET && scraper.output.account
        ? scraper.output.account
        : googleAccounts.length > 0 ? googleAccounts[0].email : '-')
    const [createSpreadsheetToggled, setCreateSpreadsheetToggled] = React.useState(false)
    const [spreadsheetCreating, setSpreadsheetCreating] = React.useState(false)
    const [spreadsheet, setSpreadsheet] = React.useState(scraper.output ? getSpreadsheetUrl(scraper.output.spreadsheetId) : '')
    const [spreadsheetTitle, setSpreadsheetTitle] = React.useState('')
    const [loadedSpreadsheet, setLoadedSpreadsheet] = React.useState(null)
    const [sheet, setSheet] = React.useState(scraper.output && scraper.output.sheet ? scraper.output.sheet : '-')
    const spreadsheetSet = account !== '-' && spreadsheet !== '' && sheet !== '-'
    const sheetOptions = !loadedSpreadsheet || !loadedSpreadsheet.data.sheets ? [] : loadedSpreadsheet.data.sheets.map(s => ({ label: s.title, value: s.title }))
    const [overwrite, setOverWrite] = React.useState(scraper.output && scraper.output.overwrite ? scraper.output.overwrite : false)


    const handleLoadSpreadsheet = React.useCallback((loaded) => {
        setLoadedSpreadsheet(loaded)

        // Set the sheet to the first one by default
        if (loaded.data.sheets && loaded.data.sheets.length > 0) {
            setSheet(scraper.output ? scraper.output.sheet : loaded.data.sheets[0].title)
        }
    }, [scraper])


    const handleCreateSpreadsheet = React.useCallback(() => {
        createSpreadsheet({
            account, title: spreadsheetTitle, callback: (result) => {
                setSpreadsheetCreating(result.loading)

                if (result.data.id) {
                    setSpreadsheet(getSpreadsheetUrl(result.data.id))
                }
                setCreateSpreadsheetToggled(false)
            }
        })
    }, [account, spreadsheetTitle])

    const handleConnectSpreadsheet = React.useCallback(() => {
        onSubmit({ type: OutputType.GOOGLE_SHEET, account, spreadsheetId: loadedSpreadsheet.data.id, spreadsheetTitle: loadedSpreadsheet.data.title, sheet, overwrite })
        onClose()
    }, [account, loadedSpreadsheet, sheet, overwrite, onSubmit, onClose])


    return <ModalDialog title="Connect Google Sheet"
        fullWidth
        maxWidth="sm"
        closeButton
        onClose={onClose}
        buttons={[
            <LoadingButton key="submit_button"
                type="button"
                color="primary"
                variant="contained"
                onClick={handleConnectSpreadsheet}
                loading={submitting}
                disabled={!spreadsheetSet}>Connect</LoadingButton>
        ]}>
        <Stack spacing={5}>
            <div>
                <FormControl fullWidth>
                    <InputLabel>Account</InputLabel>
                    <Select
                        id="account"
                        value={account}
                        onChange={(e) => setAccount(e.target.value)}>
                        {accOptions.map(opt => <MenuItem key={opt.value}
                            value={opt.value}>{opt.label}</MenuItem>)}
                    </Select>
                </FormControl>

                <Button sx={{ mt: 2 }} type="button" variant="outlined" onClick={redirectToGoogleAuth}>Add Account</Button>
            </div>

            {account !== '-' && <div>
                {!createSpreadsheetToggled ? <div>
                    <GoogleSheetLoader account={account}
                        spreadsheet={spreadsheet}
                        loadedSpreadsheet={loadedSpreadsheet}
                        onChange={setSpreadsheet}
                        onLoad={handleLoadSpreadsheet} />

                    <Button sx={{ mt: 2 }} type="button" variant="outlined" onClick={() => setCreateSpreadsheetToggled(true)}>Create New</Button>
                </div> : <Stack spacing={3} className={classes.inlinePanel}>
                    <TextField autoFocus
                        fullWidth
                        horizontal
                        value={spreadsheetTitle}
                        label="Title"
                        onChange={(e) => setSpreadsheetTitle(e.target.value)} />

                    {spreadsheetCreating
                        ? <CircularProgress size={18} />
                        : <Stack direction="row" spacing={3} justifyContent="flex-end">
                            <Button disabled={spreadsheetCreating} variant="outlined" color="secondary" onClick={() => setCreateSpreadsheetToggled(false)}>Cancel</Button>
                            <LoadingButton loading={spreadsheetCreating} variant="contained" color="secondary" onClick={handleCreateSpreadsheet}>Create</LoadingButton>
                        </Stack>}
                </Stack>}
            </div>}

            {!createSpreadsheetToggled && loadedSpreadsheet && !loadedSpreadsheet.loading && !loadedSpreadsheet.error && <div>
                <FormControl fullWidth>
                    <InputLabel>Sheet</InputLabel>
                    <Select fullWidth
                        id="sheet"
                        labelId="sheet-label"
                        label="Sheet"
                        value={sheet}
                        onChange={(e) => setSheet(e.target.value)}>
                        {sheetOptions.map(opt => <MenuItem key={opt.value}
                            value={opt.value}>{opt.label}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControlLabel sx={{ mt: 2 }} control={<Checkbox checked={overwrite} onChange={(e) => setOverWrite(e.target.checked)} />}
                    label="Overwrite existing data" />
            </div>}
        </Stack>
    </ModalDialog>
}