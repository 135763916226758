import {fork} from 'redux-saga/effects';
import {
    FETCH_ALL_LOGS,
    FETCH_ALL_USERS_DATA,
    FETCH_QUOTAS,
    FETCH_SYSTEM_STATS,
    FETCH_USER_ACTIVITY, FETCH_USER_DATA, FETCH_USER_FEEDBACK,
    FETCH_USER_SCRAPERS,
    UPDATE_QUOTAS
} from "./actions";
import {createApiRequestWatcher} from "../saga-helpers";
import {jsonWithAuth} from "../utils";
import {API_URL} from "../constants";


const fetchAllUsersDataFlow = createApiRequestWatcher({
    actionPrefix: FETCH_ALL_USERS_DATA,
    fetcher: () => fetch(`${API_URL}/admin/users`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const fetchUserDataFlow = createApiRequestWatcher({
    actionPrefix: FETCH_USER_DATA,
    fetcher: (id) => fetch(`${API_URL}/admin/users/${id}`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const fetchSystemStatsFlow = createApiRequestWatcher({
    actionPrefix: FETCH_SYSTEM_STATS,
    fetcher: () => fetch(`${API_URL}/admin/stats`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const fetchQuotasFlow = createApiRequestWatcher({
    actionPrefix: FETCH_QUOTAS,
    fetcher: () => fetch(`${API_URL}/admin/quotas`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const updateQuotasFlow = createApiRequestWatcher({
    actionPrefix: UPDATE_QUOTAS,
    fetcher: (data) => fetch(`${API_URL}/admin/quotas`, {
        method: 'PATCH',
        headers: jsonWithAuth(),
        body: JSON.stringify(data)
    }).then( resp => resp.json() ),

    notify: {
        success: 'Quota setting has been saved.',
        failure: ({code}) => 'Failed to save quota setting.' + (code === 0 ? ' The server may be down for maintenance.' : '')
    }
});

const fetchUserScrapersFlow = createApiRequestWatcher({
    actionPrefix: FETCH_USER_SCRAPERS,
    fetcher: (userID) => fetch(`${API_URL}/admin/users/${userID}/scrapers`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const fetchUserActivityFlow = createApiRequestWatcher({
    actionPrefix: FETCH_USER_ACTIVITY,
    fetcher: (userID) => fetch(`${API_URL}/admin/users/${userID}/activity`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const fetchUserFeedbackFlow = createApiRequestWatcher({
    actionPrefix: FETCH_USER_FEEDBACK,
    fetcher: () => fetch(`${API_URL}/feedback`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

const fetchAllLogsFlow = createApiRequestWatcher({
    actionPrefix: FETCH_ALL_LOGS,
    fetcher: () => fetch(`${API_URL}/logger`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});


export function* adminFlows() {
    yield fork(fetchAllUsersDataFlow);
    yield fork(fetchUserDataFlow);
    yield fork(fetchSystemStatsFlow);
    yield fork(fetchQuotasFlow);
    yield fork(updateQuotasFlow);
    yield fork(fetchUserScrapersFlow);
    yield fork(fetchUserActivityFlow);
    yield fork(fetchUserFeedbackFlow);
    yield fork(fetchAllLogsFlow);
}