import React from 'react'
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import makeStyles from '@mui/styles/makeStyles'
import log from 'loglevel'
import remote from 'loglevel-plugin-remote'
import { API_URL } from "../constants"
import RobotPortraitSad from "../resources/icons/robot_portrait_sad.svg"
import { Stack } from '@mui/material'

if (process.env.NODE_ENV !== 'development') {
    const customJSON = log => ({
        level: log.level.label,
        msg: log.message,
        ts: log.timestamp
    })

    remote.apply(log, { format: customJSON, url: `${API_URL}/logger` })
    log.enableAll()
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        fontSize: '8rem',
        color: 'hsla(215, 45%, 92%, 0.7)'
    },
    title: {
        margin: 0,
        textAlign: 'center',
        fontSize: theme.fontSize[5],
        color: theme.palette.grey[700]
    },
    body: {
        margin: 0,
        textAlign: 'justify',
        fontSize: theme.fontSize[3],
        color: theme.palette.grey[500],
        lineHeight: 1.5,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }
}))

/**
 * The presentational component displaying a generic error message and a button to reload the page.
 */
const ErrorMessage = () => {
    const classes = useStyles()

    return <Container className={classes.wrapper} maxWidth="xs">
        <Stack spacing={6} alignItems="center">
            <Box display="flex" justifyContent="center">
                <img src={RobotPortraitSad} alt="sad robot" />
            </Box>

            <h1 className={classes.title}>Something went wrong.</h1>

            <p className={classes.body}>You ran into a bug. Sorry for that. Some details about the problem have been captured, so we'll take a look. Until then, please refresh the page and try again.</p>

            <Box display="flex" justifyContent="center">
                <Button color="primary"
                    onClick={() => { window.location.reload() }}>Refresh</Button>
            </Box>
        </Stack>
    </Container>
}

/**
 * An error boundary used to catch component errors and display a generic error message.
 */
class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    // Send the error log to the Backend
    componentDidCatch(error, info) {
        this.setState({ hasError: true })
        log.error(JSON.stringify({ error: error.toString(), componentStack: info.componentStack }))
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage />
        }

        return this.props.children
    }
}

export default ErrorBoundary