import React from "react"
import { Stack, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"

const Badge = styled("span")(({ theme }) => ({
    color: "white",
    backgroundColor: theme.palette.success[600],
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: theme.spacing(3),
    padding: 2,
    borderRadius: theme.borderRadius[2],
    minWidth: 20,

    "&.success": {
        backgroundColor: theme.palette.success[800],
    },
    "&.failure": {
        backgroundColor: theme.palette.error.dark,
    },
    "&.warning": {
        backgroundColor: theme.palette.warning.main,
    },
    "&.muted": {
        opacity: 0.2,
    },
}))

export const Stats = ({ pagesScraped, pagesFailed }) => (
    <Stack direction="row" spacing={2} ml={3}>
            {pagesScraped !== null && (
                <Tooltip
                    arrow
                    title="Pages scraped"
                    placement="bottom"
                    enterDelay={500}
                >
                    <Badge className={`success ${pagesScraped === 0 ? "muted" : ""}`}>
                        {pagesScraped}
                    </Badge>
                </Tooltip>
            )}
            {pagesFailed !== null && (
                <Tooltip
                    arrow
                    title="Pages failed"
                    placement="bottom"
                    enterDelay={500}
                >
                    <Badge className={`failure ${pagesFailed === 0 ? "muted" : ""}`}>
                        {pagesFailed}
                    </Badge>
                </Tooltip>
            )}
        </Stack>
)
