import React from "react"
import makeStyles from '@mui/styles/makeStyles'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from "@mui/material/IconButton"
import Logo from "../resources/icons/logo_color.svg"
import { FEEDBACK_TYPES } from "./constants"
import { Stack, TextField, Box, Button } from "@mui/material"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { sendFeedback } from "./actions"


const feedbackSchema = Yup.object({
    comment: Yup.string().required("Cannot be empty."),
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        "&.closed": {
            display: "none",
        },
    },
    widgetBtn: {
        position: "fixed",
        right: 0,
        top: `calc(50% - ${theme.spacing(7)})`,
        zIndex: 1000,
        width: theme.spacing(6),
        height: theme.spacing(9),
        backgroundColor: theme.palette.success[300],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: theme.borderRadius[2],
        borderBottomLeftRadius: theme.borderRadius[2],
        outline: "none",
        border: 0,
        padding: 0,
        cursor: "pointer",
        transition: "all 0.3s ease 0s",

        "&:hover, &:focus": {
            backgroundColor: theme.palette.success[400],
        },

        "&.expanded": {
            right: 300,
        },
    },
    widgetLabel: {
        color: theme.palette.grey[700],
        fontSize: 16,
        writingMode: "vertical-lr",
        transform: "rotate(-180deg)",
    },
    panel: {
        position: "fixed",
        width: 300,
        zIndex: 9999,
        left: "100%",
        top: theme.spacing(8),
        bottom: theme.spacing(8),
        backgroundColor: theme.palette.grey[100],
        borderTopLeftRadius: theme.borderRadius[3],
        borderBottomLeftRadius: theme.borderRadius[3],
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        transition: "all 0.3s ease 0s",
        overflowY: "auto",

        "&.expanded": {
            left: "calc(100% - 300px)",
        },
    },
    header: {
        padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    brandWrapper: {
        display: "flex",
        alignItems: "center",
    },
    logo: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    brand: {
        fontFamily: '"Share Tech", Arial, sans-serif',
        fontSize: 20,
        color: theme.palette.grey[700],
        marginLeft: theme.spacing(3),
    },
    body: {
        padding: theme.spacing(4),
    },
}))

const ToggleButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[900],
    fontSize: 14,
    borderRadius: theme.borderRadius[2],
    width: "100%",
    textTransform: "none",
    fontWeight: 400,

    "&:hover, &:focus": {
        backgroundColor: theme.palette.primary[200],
    },

    "&.active": {
        backgroundColor: theme.palette.primary[300],
    },
}))


/**
 * A toggleable widget for submitting user feedback.
 */
export const FeedbackWidget = () => {
    const classes = useStyles()

    const [state, setState] = React.useState("collapsed")
    const [submitted, setSubmitted] = React.useState(false)
    const dispatch = useDispatch()

    const handleSubmitFeedback = React.useCallback(
        (values) => {
            dispatch(sendFeedback(values))
            setSubmitted(true)
        },
        [dispatch]
    )

    return (
        <div className={`${classes.wrapper} ${state}`}>
            <button
                className={`${classes.widgetBtn} ${state}`}
                onClick={() =>
                    setState(state === "expanded" ? "collapsed" : "expanded")
                }
            >
                <span className={classes.widgetLabel}>Feedback</span>
            </button>

            <div className={`${classes.panel} ${state}`}>
                <div className={classes.header}>
                    <div className={classes.brandWrapper}>
                        <img className={classes.logo} src={Logo} alt="logo" />
                        <span className={classes.brand}>DataGrab</span>
                    </div>

                    <IconButton
                        aria-label="close"
                        sx={{ color: (theme) => theme.palette.grey[500] }}
                        onClick={() => setState("closed")}>
                        <CloseIcon />
                    </IconButton>

                </div>
                <div className={classes.body}>
                    {!submitted ? (
                        <Formik
                            validationSchema={feedbackSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={{
                                comment: "",
                                rating: null,
                                type: null,
                            }}
                            onSubmit={handleSubmitFeedback}
                        >
                            {({ values, errors, setFieldValue }) => (
                                <Form>
                                    <Stack spacing={7}>
                                        <div>
                                            <p>
                                                Please rate your experience with
                                                DataGrab today.
                                            </p>

                                            <Stack spacing={3}>
                                                <ToggleButton
                                                    className={`${values.rating === 1
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            "rating",
                                                            values.rating === 1
                                                                ? null
                                                                : 1
                                                        )
                                                    }
                                                >
                                                    Terrible
                                                </ToggleButton>
                                                <ToggleButton
                                                    className={`${values.rating === 2
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            "rating",
                                                            values.rating === 2
                                                                ? null
                                                                : 2
                                                        )
                                                    }
                                                >
                                                    Poor
                                                </ToggleButton>
                                                <ToggleButton
                                                    className={`${values.rating === 3
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            "rating",
                                                            values.rating === 3
                                                                ? null
                                                                : 3
                                                        )
                                                    }
                                                >
                                                    Average
                                                </ToggleButton>
                                                <ToggleButton
                                                    className={`${values.rating === 4
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            "rating",
                                                            values.rating === 4
                                                                ? null
                                                                : 4
                                                        )
                                                    }
                                                >
                                                    Good
                                                </ToggleButton>
                                                <ToggleButton
                                                    className={`${values.rating === 5
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            "rating",
                                                            values.rating === 5
                                                                ? null
                                                                : 5
                                                        )
                                                    }
                                                >
                                                    Excellent
                                                </ToggleButton>
                                            </Stack>
                                        </div>

                                        <div>
                                            <p>
                                                What type of feedback would you
                                                like to provide?
                                            </p>

                                            <Stack spacing={3}>
                                                {FEEDBACK_TYPES.map((f) => (
                                                    <ToggleButton
                                                        key={f.value}
                                                        className={`${values.type ===
                                                                f.value
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        onClick={() =>
                                                            setFieldValue(
                                                                "type",
                                                                values.type ===
                                                                    f.value
                                                                    ? null
                                                                    : f.value
                                                            )
                                                        }
                                                    >
                                                        {f.label}
                                                    </ToggleButton>
                                                ))}
                                            </Stack>
                                        </div>

                                        <div>
                                            <p>Please provide your feedback.</p>

                                            <TextField
                                                id="comment"
                                                name="comment"
                                                fullWidth
                                                multiline
                                                rows="12"
                                                error={!!errors.comment}
                                                helperText={errors.comment}
                                                value={values.comment}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        "comment",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>

                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Send Feedback
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <div>
                            <p className={classes.centerText}>
                                Thank you for taking the time to complete this
                                survey.
                            </p>
                            <p className={classes.centerText}>
                                Your feedback has been recorded.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
