import React from "react"
import { BaseAccountPage } from "./BaseAccountPage"
import LoadingButton from "@mui/lab/LoadingButton"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import { Form, Formik } from "formik"
import {Box, Stack, Link, TextField, Typography} from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import * as Yup from "yup"
import { REQUEST_PASSWORD_RESET, requestPasswordReset } from "./actions"
import { ToastNotifications } from "../notify/ToastNotifications"
import { apiRequestStateSelector } from "../api/selectors"


const requestPasswordResetSchema = Yup.object({
    email: Yup.string()
        .required("Cannot be empty")
        .email("Please give a valid email address"),
})

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
    },
    gutters: {
        margin: theme.spacing(5, 0, 4),
    },
}))

const reqSelector = apiRequestStateSelector([REQUEST_PASSWORD_RESET])

/**
 * Allows the user to request the resetting of his/her password.
 *
 * @author Robert Balazsi
 */
export const ForgotPasswordPage = () => {
    const classes = useStyles()
    const [submitting] = useSelector((state) => reqSelector(state))
    const dispatch = useDispatch()

    const handleRequestPasswordReset = React.useCallback(
        (details) => {
            dispatch(requestPasswordReset(details.email))
        },
        [dispatch]
    )

    return (
        <BaseAccountPage title="Reset password" maxWidth="xs">
            <ToastNotifications />

            <Formik
                validationSchema={requestPasswordResetSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleRequestPasswordReset}
                initialValues={{
                    email: "",
                }}
            >
                {({ values, setFieldValue, errors }) => (
                    <Form noValidate className={classes.form}>
                        <Stack spacing={6}>
                            <Typography variant="body1" align="justify">
                                We'll send an email with a link to reset your
                                password. Please give your email address below.
                            </Typography>

                            <TextField
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={!!errors.email}
                                helperText={errors.email}
                                value={values.email}
                                onChange={(e) =>
                                    setFieldValue("email", e.target.value)
                                }
                            />

                            <Box display="flex" flexDirection="column">
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={submitting}
                                >
                                    Send link
                                </LoadingButton>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    marginTop={3}
                                >
                                    <Link
                                        aria-disabled={submitting}
                                        component={RouterLink}
                                        to="/signin"
                                        variant="body2"
                                    >
                                        Back to Sign In
                                    </Link>
                                </Box>
                            </Box>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </BaseAccountPage>
    )
}
