import React from 'react'
import Tooltip from "@mui/material/Tooltip"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {ConfirmationDialog} from "../common/ConfirmationDialog"
import makeStyles from '@mui/styles/makeStyles'
import {ScraperStatus} from "@datagrab/datagrab-common/constants"
import {CloneScraperDialog} from './CloneScraperDialog'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import PlayIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    menuItem: {
        color: theme.palette.grey[700],
        position: 'relative',

        '& svg': {
            color: theme.palette.grey[400],
        },

        '& .label': {
            fontSize: theme.fontSize[2],
            marginLeft: theme.spacing(3)
        },
    }
}))

/**
 * A dropdown with actions to be performed on a scraper. These are: starting/stopping it, cloning it, and deleting it.
 */
export const ActionsDropdown = ({scraper, startDisabled, onStart, onStop, onClone, onDelete}) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [stopRequested, setStopRequested] = React.useState(false)
    const [cloneRequested, setCloneRequested] = React.useState(false)
    const [deleteRequested, setDeleteRequested] = React.useState(false)

    const handleMenu = React.useCallback((e) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const handleClose = React.useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleStart = React.useCallback(() => {
        onStart(scraper.id)
        handleClose()
    }, [onStart, handleClose, scraper.id])

    const handleStop = React.useCallback(() => {
        setStopRequested(false)
        onStop(scraper.id)
        handleClose()
    }, [onStop, handleClose, scraper.id])

    const handleClone = React.useCallback((details) => {
        setCloneRequested(false)
        onClone({...scraper, ...details})
        handleClose()
    }, [onClone, handleClose, scraper])

    const handleDelete = React.useCallback(() => {
        setDeleteRequested(false)
        onDelete(scraper.id)
        handleClose()
    }, [onDelete, handleClose, scraper.id])

    return <div>
        <Tooltip arrow title="Actions" placement="bottom" enterDelay={500}>
            <span>
                <IconButton onClick={handleMenu} aria-controls="actions-menu" aria-haspopup="true">
                    <MoreIcon />
                </IconButton>
            </span>
        </Tooltip>

        <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            disableScrollLock
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}
        >
            {(scraper.status === ScraperStatus.STARTING || scraper.status === ScraperStatus.RUNNING)
                ? <MenuItem className={classes.menuItem} onClick={() => setStopRequested(true)}>
                    <StopIcon />
                    <span className="label">Stop</span>
                </MenuItem>
                : <MenuItem className={classes.menuItem} disabled={startDisabled} onClick={handleStart}>
                    <PlayIcon />
                    <span className="label">Start</span>
                </MenuItem>
            }

            <MenuItem className={classes.menuItem} onClick={() => {setCloneRequested(true); handleClose()}}>
                <FileCopyIcon />
                <span className="label">Clone</span>
            </MenuItem>

            {(scraper.status === ScraperStatus.STARTING || scraper.status === ScraperStatus.RUNNING || scraper.status === ScraperStatus.STOPPING)
                ? <Tooltip arrow placement="left" title="Can't delete a running scraper.">
                    <span>
                        <MenuItem className={classes.menuItem} disabled onClick={() => {setDeleteRequested(true); handleClose()}}>
                            <DeleteIcon />
                            <span className="label">Delete</span>
                        </MenuItem>
                    </span>
                </Tooltip>
                : <MenuItem className={classes.menuItem} onClick={() => {setDeleteRequested(true); handleClose()}}>
                    <DeleteIcon />
                    <span className="label">Delete</span>
                </MenuItem>}

        </Menu>

        {stopRequested && <ConfirmationDialog title="Confirm Stop"
                                              cancelButton={{label: 'No'}}
                                              submitButton={{label: 'Yes, stop', danger: true}}
                                              onCancel={() => setStopRequested(false)}
                                              onSubmit={handleStop}>
            <p>Are you sure you want to stop <strong>{scraper.name}</strong>?</p>
            <p>Note that data scraped up to this point is retained so you can still download it.</p>
        </ConfirmationDialog>}

        {cloneRequested && <CloneScraperDialog scraper={scraper}
                                               onClose={() => setCloneRequested(false)}
                                               onSubmit={handleClone}/>}

        {deleteRequested && <ConfirmationDialog title="Confirm Deletion"
                                                cancelButton={{label: 'No'}}
                                                submitButton={{label: 'Yes, delete it', danger: true}}
                                                onCancel={() => setDeleteRequested(false)}
                                                onSubmit={handleDelete}>
            Deleting a scraper will also purge all its data. Are you sure you want to delete it?
        </ConfirmationDialog>}
    </div>
}