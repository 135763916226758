import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import InputLabel from "@mui/material/InputLabel"
import { ACCOUNT_STATUS_DISPLAY_NAMES } from "./constants"
import { Box, Card, CardContent, CardHeader, Stack } from '@mui/material'
import { formatDistance } from 'date-fns'


const useStyles = makeStyles(() => ({
    userProfileCard: {
        minWidth: 300
    }
}))

/**
 * Displays general profile information about a user.
 */
export const UserProfilePanel = ({ user }) => {
    const classes = useStyles()

    return <Card className={classes.userProfileCard}>
        <CardHeader title="Profile" />
        <CardContent>
            <Stack spacing={5}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel sx={{marginRight: 32}}>Email</InputLabel>
                    <span>{user.email}</span>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel sx={{marginRight: 32}}>Full Name</InputLabel>
                    <span>{user.fullName ? user.fullName : user.firstName + ' ' + user.lastName}</span>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel sx={{marginRight: 32}}>Account Status</InputLabel>
                    <span>{ACCOUNT_STATUS_DISPLAY_NAMES[user.accountStatus]}</span>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel sx={{marginRight: 32}}>Joined</InputLabel>
                    <span>{formatDistance(new Date(user.dateJoined * 1000), new Date())}</span>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel sx={{marginRight: 32}}>Last Online</InputLabel>
                    <span>{!!user.lastOnline ? formatDistance(new Date(user.lastOnline * 1000), new Date()) : '-'}</span>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel sx={{marginRight: 32}}>Use Cases</InputLabel>
                    <span>{!!user.useCases && user.useCases.length > 0 ? user.useCases.join(', ') : '-'}</span>
                </Box>
            </Stack>
        </CardContent>
    </Card>
}