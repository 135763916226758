import React, {useCallback} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import {useTheme} from "@mui/material";
import {AdminSideNav} from "./AdminSideNav";
import {Breadcrumbs} from "../common/Breadcrumbs";
import {ToastNotifications} from "../notify/ToastNotifications";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        minHeight: '100vh'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: theme.spacing(9),
        backgroundColor: theme.palette.success[500],
        border: 0,
        overflow: 'hidden'
    },
    contentWrapper: {
        width: '100%',
        flexGrow: 1,
        padding: theme.spacing(6),
        marginTop: theme.spacing(7),
        overflowY: 'auto',

        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginTop: theme.spacing(8),
            maxHeight: '100%'
        }
    },
}));

/**
 * Base page for the admin panel.
 * Displays user navigation element, including the header with the logout option and the side navigation.
 *
 * @author Robert Balazsi
 */
export const AdminPage = ({activeTab, breadcrumbs, children}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = useCallback( () => {
        setMobileOpen(!mobileOpen);
    }, [mobileOpen, setMobileOpen] );

    return (
        <div className={classes.root}>
            <ToastNotifications/>

            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                        <AdminSideNav activeTab={activeTab} />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <AdminSideNav activeTab={activeTab} />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.contentWrapper}>
                {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

                <div>{children}</div>
            </main>
        </div>
    );
};