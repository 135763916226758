import React from 'react'
import { ScraperRunStatus } from '@datagrab/datagrab-common/constants'
import { Box, CircularProgress, MenuItem, Select, Tooltip } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import StopIcon from '@mui/icons-material/Stop'
import ErrorIcon from '@mui/icons-material/Error'
import {styled} from '@mui/material/styles'


const collectRuns = (current, history) => {
    let result = []
    if (current) {
        result.push(current)
    }

    return (history && history.length > 0) ? [...result, ...history] : result
}

const RunIndex = styled('span')( ({theme}) => ({
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
}))

const SCRAPER_STATUS_ICONS = {
    [ScraperRunStatus.RUNNING]: <Tooltip title="Running" placement="bottom" enterDelay={500}><CircularProgress color='success' size={14} /></Tooltip>,
    [ScraperRunStatus.COMPLETED]: <Tooltip title="Completed" placement="bottom" enterDelay={500}><DoneIcon color='success' fontSize='small' /></Tooltip>,
    [ScraperRunStatus.STOPPED]: <Tooltip title="Stopped" placement="bottom" enterDelay={500}><StopIcon color='error' fontSize='small' /></Tooltip>,
    [ScraperRunStatus.FAILED]: <Tooltip title="Failed" placement="bottom" enterDelay={500}><ErrorIcon color='error' fontSize='small' /></Tooltip>
}

const renderRunLabel = (run) => `${new Date(run.started).toLocaleString()} - ${run.finished ? new Date(run.finished).toLocaleString() : ''}`

const renderOptions = (runs) => runs.map( (run, i) => <MenuItem key={i} value={run.runId}>
    <Box display="flex" alignItems="center">
        {SCRAPER_STATUS_ICONS[run.status]}
        <RunIndex>{`#${runs.length - i}`}</RunIndex>
        {renderRunLabel(run)}
    </Box>
</MenuItem>)

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  }

export const RunSelector = ({ currentRun, runHistory, selected, onSelect = f => f }) => {
    const runs = collectRuns(currentRun, runHistory)

    return <Select fullWidth MenuProps={MenuProps} value={selected} onChange={onSelect}>
        {renderOptions(runs)}
    </Select>
}