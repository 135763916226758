import {requestAction, successAction} from "../saga-helpers";


export const FETCH_SYSTEM_PREFS = 'systemPrefs/fetch';
export const FETCH_SYSTEM_PREFS_REQUEST = requestAction(FETCH_SYSTEM_PREFS);
export const FETCH_SYSTEM_PREFS_SUCCESS = successAction(FETCH_SYSTEM_PREFS);


export const fetchSystemPrefs = () => ({
    type: FETCH_SYSTEM_PREFS_REQUEST
});