import React from "react"
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import { Link } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"
import Logo from "../resources/icons/logo_white.svg"
import { styled } from "@mui/material/styles"
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import MapIcon from '@mui/icons-material/Map'
import SettingsIcon from '@mui/icons-material/Settings'
import {Robot as RobotIcon} from '../resources/icons'


const Item = styled(ListItem)(({ theme }) => ({
    padding: 0,
    flexFlow: "column",
    color: theme.palette.primary[300],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderBottom: "1px solid hsla(220, 84%, 67%, 0.5)",
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        "& > .MuiListItemIcon-root": {
            color: "white",
        },

        "& svg": {
            stroke: "white",
        },
    },
}))

const Icon = styled(ListItemIcon)( ({theme}) => ({
    color: theme.palette.primary[300],
        minWidth: 0,
        fontSize: theme.fontSize[6],

        "& > svg": {
            stroke: theme.palette.primary[300],
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
}))

const Text = styled(ListItemText)( ({theme}) => ({
    "& > span": {
        fontSize: theme.fontSize[2],
        letterSpacing: 0.5,
    },
}))


const useStyles = makeStyles((theme) => ({
    brand: {
        display: "flex",
        height: theme.spacing(9),
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "1px solid hsla(220, 84%, 67%, 0.5)",
    },
}))

/**
 * A side navigation displayed to users.
 *
 * @author Robert Balazsi
 */
export const UserSideNav = ({ activeTab }) => {
    const classes = useStyles()

    return (
        <div>
            <a href="https://datagrab.io" className={classes.brand}>
                <img style={{width: 32, height: 32}} src={Logo} alt="logo" />
            </a>

            <List disablePadding>
                <Item
                    component={Link}
                    selected={activeTab === "scrapers"}
                    to="/scrapers"
                >
                    <Icon>
                        <RobotIcon />
                    </Icon>
                    <Text
                        primary="Scrapers"
                    />
                </Item>

                <Item
                    component="a"
                    selected={activeTab === "guide"}
                    href="https://datagrab.io/guide"
                >
                    <Icon>
                        <HelpCenterIcon />
                    </Icon>
                    <Text primary="Guide" />
                </Item>

                <Item
                    component="a"
                    selected={activeTab === "roadmap"}
                    href="https://trello.com/b/cltji3sP/datagrab-roadmap"
                >
                    <Icon>
                        <MapIcon />
                    </Icon>
                    <Text
                        primary="Roadmap"
                    />
                </Item>

                <Item
                    component={Link}
                    selected={activeTab === "settings"}
                    to="/settings"
                >
                    <Icon>
                        <SettingsIcon />
                    </Icon>
                    <Text
                        primary="Settings"
                    />
                </Item>
            </List>
        </div>
    )
}
