import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {UserPage} from "../site/UserPage";
import {LinkTabs} from "../common/LinkTabs";


const useStyles = makeStyles( (theme) => ({
    tabs: {
        marginBottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6),
        }
    },
    wrapper: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(8)
        }
    }
}));


/**
 * Page skeleton for the settings pages (plan, account and billing).
 */
export const BaseSettingsPage = ({activeTab = 'account', children}) => {
    const classes = useStyles();

    return <UserPage activeTab="settings" breadcrumbs={[{label: "Settings", link: '/settings'}]}>
        <LinkTabs tabs={[
            {label: 'Account', link: '/settings/account'},
            {label: 'Billing', link: '/settings/billing'}
        ]} activeTab={activeTab} className={classes.tabs} />

        <div className={classes.wrapper}>{children}</div>
    </UserPage>
};