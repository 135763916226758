import React from 'react'
import { useMediaQuery, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {Link as RouterLink} from "react-router-dom"
import {PlanStatus} from '@datagrab/datagrab-common/constants'
import {ScrapersTable} from "./ScrapersTable"
import {ScraperCardList} from "./ScraperCardList"
import {RunRestrictionAlert} from "./RunRestrictionAlert"
import {EmptyScrapersList} from "./EmptyScrapersList"
import {ExtensionMissingAlert} from "../common/ExtensionMissingAlert"
import {Button, Box} from "@mui/material"


const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary[500],
        textDecoration: 'none',
        '&:hover, &:focus, &:active': {
            textDecoration: 'underline'
        }
    },
    wrapper: {
        paddingBottom: theme.spacing(9),
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(8),
        }
    },
    alert: {
        marginBottom: theme.spacing(4)
    },
    muted: {
        color: theme.palette.grey[600],
        fontSize: theme.fontSize[1]
    }
}))


/**
 * Displays and manages scrapers.
 *
 * @author Robert Balazsi
 */
export const ScrapersListPage = ({extensionMissing, scrapers, maxParallel, parallelLimitReached, subscription, bulkCredits, onStart, onStop,
                                     onClone, onDelete}) => {
    const classes = useStyles()
    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.down('sm'))

    const noScrapers = !scrapers || scrapers.length === 0
    const suspendedSubscription = subscription && subscription.status === PlanStatus.SUSPENDED
    const noCredits = (!subscription || subscription.currentUsage >= subscription.monthlyCredits) && !bulkCredits
    const startDisabled = noCredits || suspendedSubscription || parallelLimitReached

    // On resolutions bigger than mobile, we display a table. On mobile, we display a scrollable list of cards
    return noScrapers
        ? <EmptyScrapersList />
        : <div className={classes.wrapper}>
            <RunRestrictionAlert suspendedSubscription={suspendedSubscription}
                                 noCredits={noCredits}
                                 maxParallel={maxParallel}
                                 parallelLimitReached={parallelLimitReached} style={{marginBottom: 32}}/>

            {extensionMissing && <ExtensionMissingAlert style={{marginBottom: 32}} />}

            <Box mb={6}>
                <Button type="button"
                        variant="contained"
                        component={RouterLink}
                        to="/scrapers/import">Import</Button>
            </Box>

            {!isXs
                ? <ScrapersTable scrapers={scrapers}
                                 startDisabled={startDisabled}
                                 onStart={onStart}
                                 onStop={onStop}
                                 onClone={onClone}
                                 onDelete={onDelete}/>
                : <ScraperCardList scrapers={scrapers}
                                   startDisabled={startDisabled}
                                   onStart={onStart}
                                   onStop={onStop}
                                   onClone={onClone}
                                   onDelete={onDelete}/>}
        </div>
}