import {requestAction} from "../saga-helpers";

export const SEND_FEEDBACK = 'feedback/send';
export const SEND_FEEDBACK_REQUEST = requestAction(SEND_FEEDBACK);


export const sendFeedback = (values) => ({
    type: SEND_FEEDBACK_REQUEST,
    data: {
        type: !values.type ? null : values.type,
        rating: values.rating,
        comment: values.comment
    }
});