import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Container from "@mui/material/Container"
import { ToastNotifications } from "../notify/ToastNotifications"
import { Box, Card, Link, Typography } from "@mui/material"
import Logo from "../resources/icons/logo_color.svg"

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexFlow: "column",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    brand: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
    logo: {
        marginRight: theme.spacing(2),
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    brandName: {
        fontFamily: '"Share Tech", Arial, sans-serif',
        fontSize: theme.fontSize[5],
        color: theme.palette.grey[900],
    },
    card: {
        borderRadius: theme.borderRadius[3],
        backgroundColor: "white",
        padding: theme.spacing(5),
        marginBottom: theme.spacing(7),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4),
            marginBottom: 0,
            borderRadius: 0,
        },
    }
}))

/**
 * Defines common layout for authentication-related pages like sign-in, sign-up, confirm account, forgot password, etc.
 *
 * @author Robert Balazsi
 */
export const BaseAccountPage = ({
    title,
    subTitle,
    maxWidth,
    children,
    className,
    ...others
}) => {
    const classes = useStyles()

    return (
        <Container
            className={`${classes.container} ${className}`}
            component="main"
            maxWidth={maxWidth}
            {...others}
        >
            <ToastNotifications />

            <Link sx={{textDecoration: "none"}} href="https://datagrab.io">
                <div className={classes.brand}>
                    <img src={Logo} className={classes.logo} alt="logo" />
                    <span className={classes.brandName}>DataGrab</span>
                </div>
            </Link>

            <Card className={classes.card}>
                <Box mb={7}>
                    <Typography textAlign="center" variant="h4" component="h2">
                        {title}</Typography>
                    {subTitle && (
                        <Typography
                            mt={2}
                            textAlign="center"
                            variant="body2"
                        >
                            {subTitle}
                        </Typography>
                    )}
                </Box>

                {children}
            </Card>
        </Container>
    )
}
