import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {FETCH_ALL_LOGS, FETCH_ALL_USERS_DATA, fetchAllLogs, fetchAllUsersData} from "./actions"
import {apiRequestStateSelector} from "../api/selectors"
import {AdminPage} from "./AdminPage"
import {LoadingContainer} from "../common/LoadingContainer"
import {DataTable} from "../common/DataTable"


const columns = [
    {
        id: 'created',
        header: 'Timestamp',
        width: 200,
        cell: ({row}) => new Date(row.created * 1000).toLocaleString(),
        sortable: true
    },
    {
        id: 'user',
        header: 'User',
        width: 250,
        cell: ({row}) => row.user,
        sortable: true
    },
    {
        id: 'msg',
        header: 'Message',
        cell: ({row}) => row.msg
    }
];

const mapUsersByIDs = (list) => {
    const map = {};
    list.forEach(user => {
        map[user.id] = user.email;
    });
    return map;
};

const associateLogsToUsers = (logs, usersByIDs) => logs.map(log => ({
    user: usersByIDs[log.userId],
    ts: log.ts,
    msg: log.msg
}));

const reqSelector = apiRequestStateSelector([FETCH_ALL_USERS_DATA, FETCH_ALL_LOGS]);

/**
 * Container component for displaying error logs reported via 'loglevel-plugin-remote'.
 */
export const LogsPageContainer = () => {
    const usersByIDs = useSelector(state => mapUsersByIDs(state.admin.users.all.list));
    const logs = useSelector(state => state.admin.logs);
    const [loading, ] = useSelector(state => reqSelector(state));

    const dispatch = useDispatch();

    React.useEffect( () => {
        dispatch( fetchAllUsersData() );
        dispatch( fetchAllLogs() );
    }, [dispatch]);

    return <AdminPage activeTab="logs" breadcrumbs={[{label: 'Logs', link: '/admin/logs'}]}>
        <LoadingContainer loading={loading}>
            <DataTable columns={columns}
                       data={associateLogsToUsers(logs, usersByIDs)}
                       wrap
                       emptyMessage="No error logs in the system. Phew :)" />
        </LoadingContainer>
    </AdminPage>
};