import { sanitizeStringForFilename } from '@datagrab/datagrab-common/utils'
import {fork} from 'redux-saga/effects';
import {createApiRequestWatcher} from "../saga-helpers";
import {push} from "connected-react-router"
import {
    DELETE_SCRAPER,
    FETCH_ALL_SCRAPERS,
    FETCH_SCRAPER,
    START_SCRAPER,
    STOP_SCRAPER,
    UPDATE_SCRAPER_DETAILS,
    EXPORT_RUN_DATA, CREATE_SCRAPER
} from "./actions"
import {API_URL} from "../constants";
import {withAuthToken} from "../utils"
import {
    CREATE_SCRAPER_FAILURE_MSG,
    CREATE_SCRAPER_SUCCESS_MSG,
    START_SCRAPER_FAILURE_MSG,
    STOP_SCRAPER_FAILURE_MSG,
    UPDATE_SCRAPER_FAILURE_MSG,
    UPDATE_SCRAPER_SUCCESS_MSG
} from "../notify/constants"



const fetchAllScrapersFlow = createApiRequestWatcher({
    actionPrefix: FETCH_ALL_SCRAPERS,

    fetcher: () => withAuthToken().then(token => fetch(`${API_URL}/scrapers?checkData=true`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', token}
    }).then( resp => resp.json() ))
});

const fetchScraperFlow = createApiRequestWatcher({
    actionPrefix: FETCH_SCRAPER,

    fetcher: ({scraperID}) => withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraperID}?checkData=true`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', token}
    }).then( resp => resp.json() ))
});

const createScraperFlow = createApiRequestWatcher({
    actionPrefix: CREATE_SCRAPER,

    fetcher: ({scraper, start}) => withAuthToken().then(token => fetch(`${API_URL}/scrapers?start=${start || 'false'}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify(scraper)
    }).then( resp => resp.json() )),

    onSuccess: () => push('/scrapers'),

    notify: {
        success: CREATE_SCRAPER_SUCCESS_MSG,
        failure: CREATE_SCRAPER_FAILURE_MSG
    }
});

const startScraperFlow = createApiRequestWatcher({
    actionPrefix: START_SCRAPER,

    fetcher: ({scraperID}) => withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraperID}/start`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', token}
    }).then( resp => resp.json() )),

    notify: {
        failure: (res) => `${START_SCRAPER_FAILURE_MSG} ${res.data.error}`
    },

    meta: ({requestData}) => requestData
});

const exportRunDataFlow = createApiRequestWatcher({
    actionPrefix: EXPORT_RUN_DATA,

    fetcher: ({scraperID, scraperName, runID, format}) => withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraperID}/runs/${runID}/export?format=${format}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', token}
    }).then(res => {
        return res.blob();
    }).then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const sanitizedName = sanitizeStringForFilename(scraperName)
        a.download = `${sanitizedName}.${format}`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again

        return {
            code: 200
        }
    }).catch(err => console.error(err)))
});

const stopScraperFlow = createApiRequestWatcher({
    actionPrefix: STOP_SCRAPER,

    fetcher: ({scraperID}) => withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraperID}/stop`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', token}
    }).then( resp => resp.json() )),

    notify: {
        failure: STOP_SCRAPER_FAILURE_MSG
    },

    meta: ({requestData}) => requestData
});

export const deleteScrapersFlow = createApiRequestWatcher({
    actionPrefix: DELETE_SCRAPER,

    fetcher: ({id}) => withAuthToken().then(token => fetch(`${API_URL}/scrapers/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', token}
    }).then( resp => resp.json() )),

    meta: ({requestData}) => ({ids: requestData.id})
});

const updateScraperDetailsFlow = createApiRequestWatcher({
    actionPrefix: UPDATE_SCRAPER_DETAILS,

    fetcher: ({scraperID, details}) => {
        const body = JSON.stringify(details)

        return withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraperID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', token},
            body
        }).then( resp => resp.json() ))
    },

    meta: ({requestData}) => requestData,

    notify: {
        success: UPDATE_SCRAPER_SUCCESS_MSG,
        failure: UPDATE_SCRAPER_FAILURE_MSG
    }
});


export function* scraperFlows() {
    yield fork(createScraperFlow);
    yield fork(updateScraperDetailsFlow);
    yield fork(fetchAllScrapersFlow);
    yield fork(fetchScraperFlow);
    yield fork(exportRunDataFlow);
    yield fork(startScraperFlow);
    yield fork(stopScraperFlow);
    yield fork(deleteScrapersFlow);
}