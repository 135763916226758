export const TRIGGER_NOTIFY = 'notify/TRIGGER';
export const DISMISS_TOAST_NOTIFICATION = 'notify/toast/dismiss';
export const DISMISS_BANNER_NOTIFICATION = 'notify/banner/dismiss';


export const triggerNotification = ({status, message}) => ({
    type: TRIGGER_NOTIFY,
    status,
    message
});

export const dismissToastNotification = () => ({
    type: DISMISS_TOAST_NOTIFICATION
});

export const dismissBannerNotification = ({message}) => ({
    type: DISMISS_BANNER_NOTIFICATION,
    message
});