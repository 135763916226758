import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import React from "react"
import { ModalDialog } from "../../common/ModalDialog"
import { isJsRenderingMandatory } from "../utils"
import { RenderingOptions } from "../constants"
import { Formik } from "formik"
import { PaginationMethod, Rendering } from "@datagrab/datagrab-common/constants"

export const ChangeScraperSettingsDialog = ({
    scraper,
    maxConcurrency,
    onClose = (f) => f,
    onSubmit = (f) => f,
    submitting,
}) => {
    const handleValidation = React.useCallback(
        (values) => {
            const errors = {}

            if (!values.name) {
                errors.name = "Name is required."
            }

            if (!values.requestInterval && values.requestInterval !== 0) {
                errors.requestInterval = "Must be at least 0."
            }

            if (
                values.limitRequests &&
                (!values.maxRequests || values.maxRequests < 1)
            ) {
                errors.maxRequests = "Must be at least 1."
            }

            if (!values.concurrency || values.concurrency < 1 || values.concurrency > maxConcurrency) {
                errors.concurrency = `Must be between 1 and ${maxConcurrency}.`
            }

            return errors
        },
        [maxConcurrency]
    )

    const handleSubmit = React.useCallback(
        (details) => {
            onSubmit(details)
            onClose()
        },
        [onSubmit, onClose]
    )

    return (
        <Formik
            validate={handleValidation}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
                name: scraper.name,
                requestInterval: scraper.requestInterval,
                limitRequests: scraper.maxRequests > 0,
                maxRequests: scraper.maxRequests,
                concurrency: scraper.concurrency,
                rendering: scraper.rendering,
                template: scraper.template,
            }}
            onSubmit={handleSubmit}
        >
            {({ values, errors, setFieldValue }) => (
                <ModalDialog
                    form
                    title="Change Scraper Settings"
                    fullWidth
                    maxWidth="xs"
                    closeButton
                    onClose={onClose}
                    buttons={[
                        <LoadingButton
                            key="submit_button"
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={submitting}
                        >
                            Save
                        </LoadingButton>,
                    ]}
                >
                    <Stack spacing={5}>
                        <TextField
                            autoFocus
                            fullWidth
                            name="name"
                            label="Name"
                            tabIndex={1}
                            error={!!errors.name}
                            helperText={errors.name}
                            value={values.name}
                            onChange={(e) =>
                                setFieldValue("name", e.target.value)
                            }
                        />

                        <TextField
                            name="requestInterval"
                            type="number"
                            InputProps={{inputProps: {min: 0}}}
                            label="Request interval (millis)"
                            value={values.requestInterval}
                            error={!!errors.requestInterval}
                            helperText={errors.requestInterval}
                            onChange={(e) =>
                                setFieldValue("requestInterval", e.target.value)
                            }
                        />

                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                sx={{whiteSpace: 'nowrap'}}
                                control={
                                    <Checkbox
                                        checked={values.limitRequests}
                                        onChange={(e) =>
                                            setFieldValue(
                                                "limitRequests",
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="Limit requests to"
                            />

                            <TextField
                                type="number"
                                InputProps={{inputProps: {min: 1}}}
                                name="maxRequests"
                                value={values.maxRequests}
                                error={!!errors.maxRequests}
                                helperText={errors.maxRequests}
                                disabled={!values.limitRequests}
                                onChange={(e) =>
                                    setFieldValue("maxRequests", e.target.value)
                                }
                            />
                        </Box>

                        {maxConcurrency > 1 && (
                            <div>
                                <TextField
                                    type="number"
                                    InputProps={{inputProps: {min: 1, max: maxConcurrency}}}
                                    label="Concurrent requests"
                                    value={values.concurrency}
                                    error={!!errors.concurrency}
                                    helperText={errors.concurrency}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "concurrency",
                                            e.target.value
                                        )
                                    }
                                />

                                {!isNaN(values.concurrency) &&
                                    parseInt(values.concurrency) > 1 && (
                                        <Box mt={3}>
                                            <Alert
                                                variant="outlined"
                                                severity="warning"
                                            >
                                                Concurrent requests may not
                                                guarantee the order of data.
                                            </Alert>
                                        </Box>
                                    )}
                            </div>
                        )}

                        <div>
                            <FormControl fullWidth>
                                <InputLabel sx={{ marginRight: 3 }}>
                                    Rendering
                                </InputLabel>
                                <Select
                                    sx={{ minWidth: "100px", marginTop: 0 }}
                                    id="rendering"
                                    value={
                                        isJsRenderingMandatory(values.template)
                                            ? Rendering.DYNAMIC
                                            : values.rendering
                                    }
                                    disabled={isJsRenderingMandatory(
                                        values.template
                                    )}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "rendering",
                                            e.target.value
                                        )
                                    }
                                >
                                    {RenderingOptions.map((opt) => (
                                        <MenuItem
                                            key={opt.value}
                                            value={opt.value}
                                        >
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {isJsRenderingMandatory(scraper.template) && (
                                <div style={{ marginTop: 12 }}>
                                    <Alert variant="outlined" severity="info">
                                        The scraper uses{" "}
                                        {scraper.template.pagination ===
                                        PaginationMethod.SCROLL
                                            ? "infinite scrolling"
                                            : '"Load more" button pagination'}
                                        , so dynamic rendering is mandatory.
                                    </Alert>
                                </div>
                            )}
                        </div>
                    </Stack>
                </ModalDialog>
            )}
        </Formik>
    )
}
