import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {BaseAccountPage} from "./BaseAccountPage"
import {Form, Formik} from "formik"
import makeStyles from '@mui/styles/makeStyles';
import {Link as RouterLink} from "react-router-dom";
import {TextField, Stack, Link, Typography} from "@mui/material"
import * as Yup from 'yup'
import {EMAIL_SIGNUP, emailSignup} from "./actions"
import LoadingButton from "@mui/lab/LoadingButton"
import {apiRequestStateSelector} from "../api/selectors"
import {ToastNotifications} from "../notify/ToastNotifications"
import {GoogleSocialButton} from "../common/GoogleSocialButton"
import {OptionsSeparator} from "./OptionsSeparator"
import {SOCIAL_SIGNIN, socialSignIn} from "../auth/actions"


const signupSchema = Yup.object({
    fullName: Yup.string().required('Full name is required'),
    email: Yup.string().required('Please give a valid email address').email('Please give a valid email address'),
    password: Yup.string().required('Password is required').min(6, "Password must be at least 6 characters"),
})


const useStyles = makeStyles( (theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    link: {
        color: theme.palette.primary[500],
        textDecoration: 'none',
        '&:hover, &:focus, &:active': {
            textDecoration: 'underline'
        }
    },
}))

const emailSignupStateSelector = apiRequestStateSelector([EMAIL_SIGNUP])
const socialLoginStateSelector = apiRequestStateSelector([SOCIAL_SIGNIN])

/**
 * Manages sign-ups to the system.
 *
 * @author Robert Balazsi
 */
export const SignupPage = () => {
    const classes = useStyles()
    const [emailSignupSubmitting, ] = useSelector(state => emailSignupStateSelector(state))
    const [socialSignupSubmitting,] = useSelector(state => socialLoginStateSelector(state))
    const dispatch = useDispatch()

    const handleSocialSignup = React.useCallback(() => {
        dispatch( socialSignIn('google') )
    }, [dispatch])

    const handleEmailSignup = React.useCallback( (values) => {
        dispatch( emailSignup({
            fullName: values.fullName,
            email: values.email,
            password: values.password,
            optedInForMarketing: values.optedInForMarketing
        }));
    }, [dispatch])

    return <BaseAccountPage title='Create account'
                            subTitle={<React.Fragment>Already have an account? <Link component={RouterLink} to="/signin" variant="body2">Sign in</Link></React.Fragment>}
                            maxWidth="xs">
        <ToastNotifications />

        <Formik
            validationSchema={signupSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleEmailSignup}
            initialValues={{
                fullName: '',
                email: '',
                password: '',
                optedInForMarketing: true,
                termsAccepted: true
            }}
        >
            {({
                  values,
                  setFieldValue,
                  errors
              }) => <Form noValidate className={classes.form}>
                <Stack spacing={5}>
                    <GoogleSocialButton fullWidth
                                        size="large"
                                        loading={socialSignupSubmitting}
                                        onClick={handleSocialSignup}>Sign up with Google</GoogleSocialButton>

                    <OptionsSeparator/>

                    <TextField
                        name="fullName"
                        id="fullName"
                        label="Name"
                        fullWidth
                        required
                        error={errors.fullName}
                        helperText={errors.fullName}
                        autoComplete="name"
                        value={values.fullName}
                        onChange={(e) => setFieldValue('fullName', e.target.value)}
                    />

                    <TextField id="email"
                               name="email"
                               label="Email"
                               fullWidth
                               required
                               error={!!errors.email}
                               helperText={errors.email}
                               autoComplete="email"
                               value={values.email}
                               onChange={(e) => setFieldValue('email', e.target.value)} />

                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        required
                        helperText="At least 6 characters"
                        error={errors.password}
                        value={values.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                    />

                    <div>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={emailSignupSubmitting}>Sign up</LoadingButton>

                        <Typography variant="body2" mt={3}>By signing up, you agree to our <Link href="https://datagrab.io/terms">Terms of Service</Link> and acknowledge our <Link href="https://datagrab.io/privacy">Privacy Policy</Link>.</Typography>
                    </div>
                </Stack>
            </Form>
            }
        </Formik>
    </BaseAccountPage>
}