import React from 'react'
import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material"
import * as Yup from 'yup'
import { updateUserProfile } from "./actions"
import { Button } from "@mui/material"


const profileSchema = Yup.object({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().required('Email is required.').email('Email is invalid.')
})


/**
 * Form for updating the user's profile info, i.e. name and email address.
 */
export const UserProfileForm = () => {
    const { email, displayName } = useSelector(state => state.firebase.auth)
    const optedInForMarketing = useSelector(state => state.profile.optedInForMarketing)
    const dispatch = useDispatch()

    const handleSaveProfile = React.useCallback((details) => {
        const newEmail = details.email !== email ? details.email : null
        dispatch(updateUserProfile({
            fullName: details.name !== displayName ? details.name : null,
            email: newEmail,
            needsLogout: !!newEmail,
            optedInForMarketing: details.optedInForMarketing
        }))
    }, [dispatch, email, displayName])

    const handleKeyDown = React.useCallback((e) => {
        if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault()
        }
    }, [])

    return <Formik enableReinitialize
        validationSchema={profileSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
            name: displayName,
            email,
            optedInForMarketing: optedInForMarketing || false
        }}
        onSubmit={handleSaveProfile}>
        {
            ({ values, errors, setFieldValue }) =>
                <Form onKeyDown={handleKeyDown}>
                    <Stack spacing={6}>
                        <TextField
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            error={!!errors.name}
                            helperText={errors.name}
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                        />

                        <TextField
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            error={!!errors.email}
                            value={values.email}
                            helperText="Updating your email will log you out automatically."
                            onChange={(e) => setFieldValue('email', e.target.value)}
                        />

                        <FormControlLabel control={<Checkbox checked={values.optedInForMarketing} onChange={(e) => setFieldValue('optedInForMarketing', e.target.checked)} />} label="I'd like to receive news, product updates, and offers from DataGrab." />

                        <div>
                            <Button variant="outlined" type="submit">Update Profile</Button>
                        </div>
                    </Stack>
                </Form>
        }
    </Formik>
}