import React from 'react'
import { BaseSettingsPage } from "../BaseSettingsPage"
import { useCurrentProfile } from "../../profile/hooks"
import { useDispatch, useSelector } from "react-redux"
import { FETCH_USER_PROFILE, fetchUserProfile } from "../actions"
import { apiRequestStateSelector } from "../../api/selectors"
import { LoadingContainer } from "../../common/LoadingContainer"
import ErrorBoundary from "../../common/ErrorBoundary"
import BulkCreditsPanel from "./BulkCreditsPanel"
import { createSubscription, manageSubscription, buyBulkCredits, createCustomPlan } from "./stripe-checkout"
import { PlanSelectionDialog } from "./PlanSelectionDialog"
import { SubscriptionPanel } from "./SubscriptionPanel"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import { BulkPackageSelectionDialog } from "./BulkPackageSelectionDialog"
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { CustomPlansPanel } from './CustomPlansPanel'


const reqSelector = apiRequestStateSelector([FETCH_USER_PROFILE])

/**
 * Manages the user's billing information (payment method and billing address).
 */
export const BillingPage = () => {
    const { bulkCredits, subscriptions } = useCurrentProfile()

    const subscription = !subscriptions ? undefined : subscriptions.find(s => s.category === 'platform')
    const customPlans = !subscriptions ? undefined : subscriptions.filter(s => s.category === 'custom')

    const [loading,] = useSelector(state => reqSelector(state))
    const [addSubscriptionToggled, setAddSubscriptionToggled] = React.useState(false)
    const [addSubscriptionSubmitting, setAddSubscriptionSubmitting] = React.useState(false)
    const [buyCreditsToggled, setBuyCreditsToggled] = React.useState(false)
    const [buyCreditsSubmitting, setBuyCreditsSubmitting] = React.useState(false)
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchUserProfile())
    }, [dispatch])

    const handleAddSubscription = React.useCallback(({ plan, billingInterval }) => {
        setAddSubscriptionSubmitting(true)
        createSubscription({ plan, billingInterval })
    }, [])

    const handleAcceptOffer = React.useCallback(( stripePriceId) => {
        createCustomPlan({ stripePriceId })
    }, [])

    const handleBuyCredits = React.useCallback(({ bulk, quantity }) => {
        setBuyCreditsSubmitting(true)
        buyBulkCredits({ bulk, quantity })
    }, [])

    const handleManageSubscription = React.useCallback(() => {
        manageSubscription()
    }, [])

    return <BaseSettingsPage activeTab="/settings/billing">
        <ErrorBoundary>
            <LoadingContainer loading={loading}>
                <Container maxWidth="sm">
                    <Stack container spacing={7}>
                        {customPlans && customPlans.length > 0 &&<Grid item xs="auto">
                            <Card>
                                <CardHeader title="Custom Plans" />
                                <CardContent>
                                    <CustomPlansPanel subscriptions={customPlans}
                                        onAcceptOffer={handleAcceptOffer}
                                        onManageSubscription={handleManageSubscription} />
                                </CardContent>
                            </Card>
                        </Grid>}

                        <Grid item xs="auto">
                            <Card>
                                <CardHeader title="Subscription" />
                                <CardContent>
                                    <SubscriptionPanel subscription={subscription}
                                        onAddSubscription={() => setAddSubscriptionToggled(true)}
                                        onManageSubscription={handleManageSubscription} />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs="auto">
                            <Card title="Bulk Credits">
                                <CardHeader title="Bulk Credits" />
                                <CardContent>
                                    <BulkCreditsPanel bulkCredits={bulkCredits} onBuyCredits={() => setBuyCreditsToggled(true)} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Stack>
                </Container>
            </LoadingContainer>

            {addSubscriptionToggled && <PlanSelectionDialog onClose={() => setAddSubscriptionToggled(false)}
                onSubmit={handleAddSubscription}
                submitting={addSubscriptionSubmitting} />}

            {buyCreditsToggled && <BulkPackageSelectionDialog onClose={() => setBuyCreditsToggled(false)}
                onSubmit={handleBuyCredits}
                submitting={buyCreditsSubmitting} />}
        </ErrorBoundary>
    </BaseSettingsPage>
}