import { IconButton, Stack } from '@mui/material'
import React from 'react'
import { styled } from "@mui/material/styles"
import DeleteIcon from '@mui/icons-material/Delete'
import { GoogleSocialButton } from '../common/GoogleSocialButton'


const AccountEmail = styled("p")(({ theme }) => ({
    margin: 0,
    fontSize: theme.fontSize[3],
    fontWeight: "bold",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}))

export const ConnectedAccountsPanel = ({ accounts, onConnect = f=>f, onRemove = f => f }) => <div>
    {accounts && accounts.length > 0 ? (
        <Stack spacing={4} mb={6}>
            {accounts
                .filter((ca) => ca.provider === "google")
                .map((ca, i) => (
                    <AccountEmail key={i}>
                        {ca.email}
                        <IconButton
                            onClick={() =>
                                onRemove({
                                    email: ca.email,
                                    provider: ca.provider,
                                })
                            }>
                            <DeleteIcon />
                        </IconButton>
                    </AccountEmail>
                ))}
        </Stack>
    ) : <p style={{ marginTop: 0, marginBottom: 24 }}>No accounts connected.</p>}
    <GoogleSocialButton
        type="button"
        onClick={onConnect}
    >
        Connect Google Account
    </GoogleSocialButton>
</div>