import React from 'react'
import ErrorBoundary from "../../common/ErrorBoundary"
import {UserPage} from "../../site/UserPage"
import Container from "@mui/material/Container"
import {ImportScraperForm} from "./ImportScraperForm"
import {CREATE_SCRAPER, createScraper} from "../actions"
import {FETCH_USER_PROFILE, fetchUserProfile} from "../../settings/actions"
import {apiRequestStateSelector} from "../../api/selectors"
import {useDispatch, useSelector} from "react-redux"
import {useCurrentProfile} from "../../profile/hooks"
import {PlanStatus, TemplateType} from "@datagrab/datagrab-common/constants"
import {calculateMaxConcurrencyForPlan} from "../../profile/utils"
import {LoadingContainer} from "../../common/LoadingContainer"
import {generateScraperConfig} from "../utils"


const reqSelector = apiRequestStateSelector([FETCH_USER_PROFILE])
const createScraperReqSelector = apiRequestStateSelector([CREATE_SCRAPER])

/**
 * Container component for importing scrapers.
 */
export const ImportScraperPageContainer = () => {
    const [loading,] = useSelector(state => reqSelector(state))
    const [submitting,] = useSelector(state => createScraperReqSelector(state))
    const {subscriptions, bulkCredits, maxParallelScrapers, runningScrapers} = useCurrentProfile()

    // NOTE: We only consider the platform subscription (at most one at the moment)
    const subscription = !subscriptions ? undefined : subscriptions.find(s => s.category === 'platform')

    const suspendedSubscription = subscription && subscription.status === PlanStatus.SUSPENDED
    const noCredits = (!subscription || subscription.currentUsage >= subscription.monthlyCredits) && !bulkCredits
    const maxConcurrency = calculateMaxConcurrencyForPlan({subscription, bulkCredits})
    const parallelLimitReached = runningScrapers >= maxParallelScrapers
    const dispatch = useDispatch()

    // Refresh the user's plan
    React.useEffect(() => {
        dispatch(fetchUserProfile())
    }, [dispatch])

    const handleCreateScraper = React.useCallback((details) => {
        console.log(details)
        
        dispatch(createScraper({
            scraper: generateScraperConfig({
                scraper: {
                    ...details,
                    maxRequests: details.limitRequests ? details.maxRequests : 0,
                },
                template: {
                    ...details.template,
                    type: TemplateType.TABLE
                }
            }),
            start: details.runNow
        }))
    }, [dispatch])

    return <UserPage activeTab="scrapers">
        <ErrorBoundary>
            <LoadingContainer loading={loading}>
                <Container maxWidth="sm">
                    <ImportScraperForm plan={{suspendedSubscription, noCredits}}
                                       parallelLimitReached={parallelLimitReached}
                                       maxConcurrency={maxConcurrency}
                                       submitting={submitting}
                                       onSubmit={handleCreateScraper}/>
                </Container>
            </LoadingContainer>
        </ErrorBoundary>
    </UserPage>
}