import React from 'react'
import { PaginationMethod, Rendering, InputUrlsSource } from '@datagrab/datagrab-common/constants'
import { Form, Formik } from "formik"
import { Alert, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Stack, TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material"
import { RunRestrictionAlert } from "../RunRestrictionAlert"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import { DEFAULT_REQUEST_INTERVAL, RenderingOptions } from "../constants"
import { ScraperImporter } from "./ScraperImporter"
import { getURLsFromString, isJsRenderingMandatory } from "../utils"


/**
 * Form for importing scrapers.
 */
export const ImportScraperForm = ({ plan, parallelLimitReached, maxConcurrency, submitting, onSubmit = f => f }) => {

    const runDisabled = plan.noCredits || plan.suspendedSubscription || parallelLimitReached
    const [importedConfig, setImportedConfig] = React.useState(null)

    const handleValidation = React.useCallback((values) => {
        const errors = {}

        if (!importedConfig) {
            errors.config = 'Configuration `file` not imported.'
        }

        if (!values.name) {
            errors.name = 'Name is required.'
        }

        if (getURLsFromString(values.urls).length === 0) {
            errors.urls = 'At least one URL is required.'
        }

        if (!values.requestInterval && values.requestInterval !== 0) {
            errors.requestInterval = 'Must be at least 0.'
        }

        if (values.limitRequests && (!values.maxRequests || values.maxRequests < 1)) {
            errors.maxRequests = 'Must be at least 1.'
        }

        if (!values.concurrency || values.concurrency < 1 || values.concurrency > maxConcurrency) {
            errors.concurrency = `Must be between 1 and ${maxConcurrency}.`
        }

        return errors
    }, [maxConcurrency, importedConfig])

    const handleSubmit = React.useCallback((details) => {
        onSubmit({
            ...importedConfig,
            ...details,

            // Convert the URL string to a list of URLs
            inputUrls: {
                source: InputUrlsSource.LIST,
                list: getURLsFromString(details.urls)
            }
        })
    }, [onSubmit, importedConfig])

    return <Formik enableReinitialize
        validate={handleValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        initialValues={{
            name: importedConfig ? importedConfig.name : '',
            urls: importedConfig ? importedConfig.template.setupUrl : '',
            requestInterval: importedConfig && importedConfig.requestInterval ? importedConfig.requestInterval : DEFAULT_REQUEST_INTERVAL,
            limitRequests: importedConfig ? importedConfig.maxRequests > 0 : false,
            maxRequests: importedConfig && importedConfig.maxRequests ? importedConfig.maxRequests : 0,
            concurrency: importedConfig && importedConfig.concurrency ? importedConfig.concurrency : 1,
            rendering: importedConfig && importedConfig.rendering ? importedConfig.rendering : Rendering.AUTO,
            runNow: !runDisabled
        }}>
        {({
            values,
            errors,
            setFieldValue,
        }) => <Form noValidate>
                <Card>
                    <CardHeader title="Import Scraper" />
                    <CardContent>
                        <Stack spacing={5}>
                            <div>
                                <ScraperImporter onImport={setImportedConfig} />
                            </div>

                            <TextField autoFocus
                                fullWidth
                                name="name"
                                label="Name"
                                tabIndex={1}
                                error={!!errors.name}
                                helperText={errors.name}
                                value={values.name}
                                onChange={(e) => setFieldValue('name', e.target.value)} />

                            <TextField autoFocus
                                fullWidth
                                multiline
                                rows={5}
                                name="urls"
                                label="URLs"
                                tabIndex={3}
                                helperText="Separate them with whitespace."
                                error={!!errors.urls}
                                value={values.urls}
                                onChange={(e) => setFieldValue('urls', e.target.value)} />

                            <TextField name="requestInterval"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Request interval (millis)"
                                value={values.requestInterval}
                                error={!!errors.requestInterval}
                                helperText={errors.requestInterval}
                                onChange={(e) => setFieldValue('requestInterval', e.target.value)} />

                            <Box display="flex" alignItems="center">
                                <FormControlLabel control={<Checkbox checked={values.limitRequests} onChange={(e) => setFieldValue('limitRequests', e.target.checked)} />}
                                    label="Limit requests to" />


                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    name="maxRequests"
                                    value={values.maxRequests}
                                    error={!!errors.maxRequests}
                                    helperText={errors.maxRequests}
                                    disabled={!values.limitRequests}
                                    onChange={(e) => setFieldValue('maxRequests', e.target.value)} />
                            </Box>

                            {maxConcurrency > 1 && <div>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 1, max: maxConcurrency } }}
                                    label="Concurrent requests"
                                    value={values.concurrency}
                                    error={!!errors.concurrency}
                                    helperText={errors.concurrency}
                                    onChange={(e) => setFieldValue('concurrency', e.target.value)} />

                                {!isNaN(values.concurrency) && parseInt(values.concurrency) > 1 && <Box mt={3}>
                                    <Alert variant="outlined" severity="warning">Concurrent requests may not guarantee the order of data.</Alert>
                                </Box>}
                            </div>}

                            <div>
                                <FormControl fullWidth>
                                    <InputLabel sx={{ marginRight: 3 }}>Rendering</InputLabel>
                                    <Select sx={{ minWidth: '100px', marginTop: 0 }}
                                        id="rendering"
                                        value={isJsRenderingMandatory(values.template) ? Rendering.DYNAMIC : values.rendering}
                                        disabled={isJsRenderingMandatory(values.template)}
                                        onChange={(e) => setFieldValue('rendering', e.target.value)}>
                                        {RenderingOptions.map(opt => <MenuItem key={opt.value}
                                            value={opt.value}>{opt.label}</MenuItem>)}
                                    </Select>
                                </FormControl>

                                {isJsRenderingMandatory(values.template) && <div style={{ marginTop: 12 }}>
                                    <Alert variant="outlined" severity="info">
                                        The scraper uses {values.template.pagination === PaginationMethod.SCROLL ? 'infinite scrolling' : '"Load more" button pagination'}, so dynamic rendering is mandatory.
                                    </Alert>
                                </div>}
                            </div>

                            <div>
                                <FormControlLabel control={<Checkbox checked={values.runNow} disabled={runDisabled} onChange={(e) => setFieldValue('runNow', e.target.checked)} />}
                                    label="Run now" />

                                <RunRestrictionAlert noCredits={plan.noCredits}
                                    suspendedSubscription={plan.suspendedSubscription}
                                    maxParallel={maxConcurrency}
                                    parallelLimitReached={parallelLimitReached}
                                    style={{ marginTop: 12 }} />
                            </div>

                            <Box mt={7}>
                                <LoadingButton key="submit_button"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    loading={submitting}
                                    style={{ width: 150 }}>Create{values.runNow ? ' & Run' : ''}</LoadingButton>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            </Form>
        }
    </Formik>
}
