import {useSelector} from "react-redux"


/**
 * Expands information about a user's profile.
 * @param profile the profile object
 */
const getProfileDetails = (profile) => {
    const userId = profile ? profile.userId : null
    const email = profile ? profile.email : null
    const fullName = profile ? profile.fullName : null
    const provider = profile ? profile.provider : null
    const bulkCredits = profile ? profile.bulkCredits : null
    const subscriptions = profile ? profile.subscriptions : null
    const maxParallelScrapers = profile ? profile.maxParallelScrapers : null
    const runningScrapers = profile ? profile.runningScrapers : null
    const connectedAccounts = profile ? profile.connectedAccounts : null

    return {userId, email, fullName, provider, bulkCredits, subscriptions, maxParallelScrapers, runningScrapers, connectedAccounts}
}

/**
 * Returns information about the user's current plan.
 */
export const useCurrentProfile = () => {
    const profile = useSelector(state => state.profile)
    return getProfileDetails(profile)
}