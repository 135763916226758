import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { LoadingContainer } from '../../common/LoadingContainer'
import { LogEntry } from './LogEntry'


export const LogsPanel = ({ logs, fetching, onDownload = f => f }) =>
    <LoadingContainer loading={fetching}>{!logs || logs.length === 0
        ? <Typography variant="body2">
            There are no logs.
        </Typography>
        : <div>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" onClick={onDownload}>Download Logs</Button>
            </Box>

            {logs.map((log, i) => <LogEntry key={i} log={log} />)}
        </div>}
    </LoadingContainer>