import React from 'react'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import makeStyles from '@mui/styles/makeStyles';
import {Link as RouterLink} from "react-router-dom"
import Link from "@mui/material/Link"
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined'
import {styled} from '@mui/material/styles'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 0,
        marginBottom: theme.spacing(7),
        fontWeight: 'bold',
        color: theme.palette.grey[700],
        fontSize: theme.fontSize[4],
    },
    muted: {
        margin: 0
    },
    active: {
        color: theme.palette.grey[900],
        margin: 0,
        fontWeight: 'bold',
    }
}))

const StyledBreadcrumbs = styled(MuiBreadcrumbs)( ({theme}) => ({
    margin: 0,
    fontWeight: 400,
    color: theme.palette.grey[500],
    fontSize: theme.fontSize[3],
}))


/**
 * A styled breadcrumbs component accepting an array of items (labels and links) and rendering them as links, except for
 * the last component that is to be rendered as static text.
 */
export const Breadcrumbs = ({items, ...others}) => {
    const classes = useStyles()

    if (!items || items.length === 0) {
        return null
    }

    const itemsArr = []
    for (let i = 0; i < items.length - 1; i++) {
        itemsArr.push(<Link color="inherit" className={classes.muted} key={'bc_' + i} component={RouterLink}
                            to={items[i].link}>{items[i].label}</Link>)
    }
    itemsArr.push(<p key={'bc_' + (items.length - 1)}
                     className={`${classes.active}`}>{items[items.length - 1].label}</p>)

    return <StyledBreadcrumbs separator={<NavigateNextOutlinedIcon fontSize="small"/>}
                           className={classes.root} aria-label="scrapers breadcrumbs" {...others}>
        {itemsArr}
    </StyledBreadcrumbs>
}