import React from 'react'
import {useDropzone} from "react-dropzone"
import {InputLabel, useTheme} from "@mui/material"
import {ConfigInfo} from "./ConfigInfo"
import { FieldType } from '@datagrab/datagrab-common/constants'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import {styled} from '@mui/material/styles'


const baseStyle = (theme) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    border: `1px dashed ${theme.palette.grey[200]}`,
    borderRadius: 4,
    backgroundColor: '#fafafa',
    color: theme.palette.grey[500],
    outline: 'none',
    transition: 'border .24s ease-in-out'
})

const successStyle = (theme) => ({
    color: theme.palette.success[700]
})

const failureStyle = (theme) => ({
    color: theme.palette.error.light
})

const focusedStyle = (theme) => ({
    borderColor: theme.palette.primary[300]
})

const acceptStyle = (theme) => ({
    backgroundColor: theme.palette.success[100]
})

const rejectStyle = (theme) => ({
    backgroundColor: theme.palette.light
})

const validateConfig = (conf) => {
    let errors = []

    if (!conf) {
        errors.push('Configuration is missing.')
        return errors
    }

    if (!conf.name) {
        errors.push('Scraper name not set.')
    }

    if (!conf.template) {
        errors.push('Template is missing.')
    }

    if (!conf.template.setupUrl) {
        errors.push('Setup URL not set.')
    }

    if (!conf.template.table) {
        errors.push('Table definition is missing.')
        return errors
    }

    conf.template.table.columns.forEach(col => {
        if (!col.name) {
            errors.push('Unnamed field found.')
        }

        if (col.type !== FieldType.FIXED && !col.selector) {
            errors.push(`Field '${col.name}' has no selector.`)
        }
    })

    return errors
}

const Wrapper = styled('div')( () => ({
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    width: '100%', 
    minWidth: 0
}) )

const renderMessage = ({data, errors}) => {
    if (!data) {
        return <p>Drag and drop the JSON file here, or click to select it</p>
    }

    if (errors.length === 0) {
        return <Wrapper>
            <p style={{display: 'flex', alignItems: 'center'}}><CheckCircleIcon style={{marginRight: 8}} />Configuration was imported from "{data.file}".</p>

            <ConfigInfo template={data.config.template} style={{marginTop: 12}} />
        </Wrapper>
    }

    if (errors.length === 1) {
        return <p style={{display: 'flex', alignItems: 'center'}}><ErrorIcon style={{marginRight: 8}} />{errors[0]}</p>
    }

    return <Wrapper>
        <p style={{display: 'flex', alignItems: 'center'}}><ErrorIcon style={{marginRight: 8}} />The imported file has the following errors:</p>

        <ul>
            {errors.map((error, i) => <li key={i}>{error}</li>)}
        </ul>
    </Wrapper>
}


/**
 * A file upload control for importing a scraping configuration. It uses React-Dropzone and allows either drag-and-dropping
 * or selecting the file to upload. It validates the imported configuration and reports errors if necessary.
 *
 * @param onImport called when the file is imported
 */
export const ScraperImporter = ({onImport = f=>f}) => {
    const [data, setData] = React.useState(null)
    const [errors, setErrors] = React.useState([])
    const isSuccess = data && errors.length === 0
    const isFailure = data && errors.length > 0

    const onDrop = React.useCallback( (files) => {
        if (files.length > 0) {
            const uploadedFile = files[0]

            const reader = new FileReader()
            reader.onload = function() {
                try {
                    const config = JSON.parse(reader.result)

                    let errors = validateConfig(config)

                    if (errors && errors.length > 0) {
                        setErrors(errors)
                        setData({file: uploadedFile.name, config: null})
                    } else {
                        setErrors([])
                        setData({file: uploadedFile.name, config})
                        onImport(config)
                    }
                } catch (e) {
                    setErrors(['Invalid or empty config file.'])
                    setData({file: uploadedFile.name, config: null})
                }
            }

            reader.readAsText(uploadedFile)
        }

    }, [onImport])

    const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({
        accept: 'application/json',
        multiple: false,
        onDrop
    })
    const theme = useTheme()

    const style = React.useMemo(() => ({
        ...baseStyle(theme),
        ...(isSuccess ? successStyle(theme) : {}),
        ...(isFailure ? failureStyle(theme) : {}),
        ...(isFocused ? focusedStyle(theme) : {}),
        ...(isDragAccept ? acceptStyle(theme) : {}),
        ...(isDragReject ? rejectStyle(theme) : {})
    }), [
        theme,
        isSuccess,
        isFailure,
        isFocused,
        isDragAccept,
        isDragReject
    ])

    return <div>
        <InputLabel style={{marginBottom: 8}}>Configuration file</InputLabel>

        <section className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />

                {renderMessage({data, errors})}
            </div>
        </section>
    </div>
}