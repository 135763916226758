import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import React from 'react'
import { styled } from "@mui/material/styles"
import GoogleSheetsIcon from "../../resources/icons/google_sheets.svg"
import { InputUrlsSource as SourceType } from '@datagrab/datagrab-common/constants'
import { LongLink } from '../../common/LongLink'
import { getSpreadsheetUrl } from '@datagrab/datagrab-common/utils'
import { LongText } from '../../common/LongText'

const MAX_URLS_TO_DISPLAY = 3


const renderStaticList = (urls) => <Stack spacing={2} sx={{ width: '100%' }}>
    {urls.slice(0, MAX_URLS_TO_DISPLAY).map((url, i) => <LongLink key={i} href={url}>{url}</LongLink>)}
    {urls.length - MAX_URLS_TO_DISPLAY > 0 && <Typography variant="body2">... and {urls.length - MAX_URLS_TO_DISPLAY} more</Typography>}
</Stack>


const Icon = styled("img")(() => ({
    height: 20,
    marginRight: 8,
}))

export const InputUrlsSource = ({ source, onUpdate = f => f }) =>
    <Card sx={{ maxWidth: '100%' }}>
        <CardHeader title="Input URLs" />
        <CardContent>
            {source.source === SourceType.LIST && renderStaticList(source.list)}

            {source.source === SourceType.GOOGLE_SHEET && <Box display="flex" alignItems="center" mb={5}>
                <Icon src={GoogleSheetsIcon} alt="google sheets" />
                <span
                    style={{
                        marginRight: 24,
                        minWidth: 0,
                        maxWidth: "100%",
                    }}>
                    <LongText>
                        Sourced from
                        <Box
                            mx={2}
                            minWidth={0}
                            width="100%"
                            display="inline-flex"
                            style={{ whiteSpace: "nowrap" }}
                        >
                            <LongLink
                                href={getSpreadsheetUrl(
                                    source.spreadsheetId
                                )}
                            >
                                {source.spreadsheetTitle}
                            </LongLink>
                        </Box>
                    </LongText>
                </span>
            </Box>}

            <Box mt={5}>
                <Button onClick={onUpdate} variant="outlined">Update</Button>
            </Box>
        </CardContent>

    </Card>