import {API_URL, PORTAL_URL} from "../../constants"
import {withAuthToken} from "../../utils"


/**
 * Redirects to Stripe Checkout to create a subscription with the given plan and billing interval.
 * It redirects afterward to the thank you page on success, or to the billing page on failure.
 *
 * @param plan the plan
 * @param billingInterval the billing interval
 */
export const createSubscription = ({plan, billingInterval}) => {
    return withAuthToken().then(token => fetch(`${API_URL}/billing/checkout`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify({
            type: 'subscription',
            plan,
            billingInterval,
            successUrl: `${PORTAL_URL}/thank-you`,
            cancelUrl: `${PORTAL_URL}/settings/billing`,
        })
    })
        .then(resp => resp.json())
        .then((result) => {
            window.location.href = result.result
        }))
}

/**
 * Redirects to Stripe Checkout to create a custom plan with the given Stripe price ID.
 * It redirects afterward to the thank you page on success, or to the billing page on failure.
 *
 * @param stripePriceId the price ID on Stripe's end
 */
export const createCustomPlan = ({stripePriceId}) => {
    return withAuthToken().then(token => fetch(`${API_URL}/billing/checkout`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify({
            type: 'custom',
            stripePriceId,
            successUrl: `${PORTAL_URL}/thank-you`,
            cancelUrl: `${PORTAL_URL}/settings/billing`,
        })
    })
        .then(resp => resp.json())
        .then((result) => {
            window.location.href = result.result
        }))
}

/**
 * Redirects the customer to Stripe's Customer Portal that supports:
 *  - switching to another plan
 *  - updating the payment method
 *  - cancelling the subscription
 */
export const manageSubscription = () => {
    return withAuthToken().then(token => fetch(`${API_URL}/billing/customerPortal`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify({
            returnUrl: `${PORTAL_URL}/settings/billing`,
        })
    })
        .then(resp => resp.json())
        .then((result) => {
            window.location.href = result.result
        }))
}

/**
 * Redirects to Stripe Checkout to buy bulk credits of the given package and in the given quantity.
 * It redirects afterward to the billing page.
 *
 * @param bulk the code of the bulk package
 * @param quantity the quantity
 */
export const buyBulkCredits = ({bulk, quantity}) => {
    return withAuthToken().then(token => fetch(`${API_URL}/billing/checkout`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify({
            type: 'bulk',
            bulk,
            quantity,
            successUrl: `${PORTAL_URL}/settings/billing`,
            cancelUrl: `${PORTAL_URL}/settings/billing`,
        })
    })
        .then(resp => resp.json())
        .then((result) => {
            window.location.href = result.result
        }))
}