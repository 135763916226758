import React from 'react'
import Box from "@mui/material/Box/Box"
import {Check} from "@mui/icons-material"
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles( (theme) => ({
    planButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minWidth: 350,
        padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.borderRadius[3],
        background: 'white',
        cursor: 'pointer',

        [theme.breakpoints.down('xl')]: {
            minWidth: '100%',
        },

        '& .muted': {
            fontSize: theme.fontSize[2],
            color: theme.palette.grey[600],
        },

        '&.active': {
            color: 'white',
            background: theme.palette.primary[600],

            '& .muted': {
                fontSize: theme.fontSize[2],
                color: theme.palette.primary[200],
            },
        }
    },
    checkbox: {
        width: 30,
        height: 30,
        marginRight: theme.spacing(5),
        display: 'inline-flex',
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        border: `1px solid ${theme.palette.grey[100]}`,
        background: 'white',
        color: theme.palette.primary[600]
    },
    planName: {
        fontSize: theme.fontSize[4],
        margin: 0,
    },
}))

/**
 * Displays a selectable plan or package. Used for picking the subscription or bulk credit package.
 */
export const PlanButton = ({name, active, onClick, price, className = '', ...others}) => {
    const classes = useStyles()

    return <button className={`${classes.planButton} ${active ? 'active' : ''} ${className}`} onClick={onClick} {...others}>
        <Box display="flex" alignItems="center">
            <span className={classes.checkbox}>{active && <Check />}</span>
            <p className={classes.planName}>{name}</p>
        </Box>
        <Box ml={4}>{price}</Box>
    </button>
}