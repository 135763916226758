import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { dismissToastNotification } from "./actions"
import Snackbar from "@mui/material/Snackbar"
import {
    NOTIFY_AUTO_HIDE_DURATION,
    NOTIFY_DISMISS_ON_CLICK_AWAY,
} from "./constants"
import { Alert } from "@mui/material"

/**
 * Displays notification messages in Snackbar components that auto-hides after a configured duration but only on success.
 * Auto-hide is disabled for failure notifications.
 *
 * @author Robert Balazsi
 */
export const ToastNotifications = () => {
    const status = useSelector((state) => state.notify.toast.status)
    const message = useSelector((state) => state.notify.toast.message)
    const hasStatus = !!status && !!message
    const dispatch = useDispatch()

    const handleDismissNotification = React.useCallback(
        (event, reason) => {
            if (NOTIFY_DISMISS_ON_CLICK_AWAY && reason === "clickaway") {
                return
            }

            dispatch(dismissToastNotification())
        },
        [dispatch]
    )

    if (!hasStatus) {
        return null
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open
            onClose={handleDismissNotification}
            autoHideDuration={
                status === "success" ? NOTIFY_AUTO_HIDE_DURATION : null
            }
        >
            <Alert
                variant="filled"
                severity={status === "success" ? "success" : "error"}
                onClose={handleDismissNotification}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}
