import {all, fork} from 'redux-saga/effects'
import {accountFlows} from "./account/sagas"
import {authFlows} from "./auth/sagas"
import {scraperFlows} from "./scrapers/sagas"
import {feedbackFlows} from "./feedback/sagas"
import {adminFlows} from "./admin/sagas"
import {systemPrefsFlows} from "./systemPrefs/sagas"
import {userProfileFlows} from "./settings/sagas"


export default function* rootSaga() {
    yield all([
        fork(accountFlows),
        fork(authFlows),
        fork(adminFlows),
        fork(scraperFlows),
        fork(userProfileFlows),
        fork(feedbackFlows),
        fork(systemPrefsFlows)
    ])
}