import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Box, Card, TextField, Stack } from "@mui/material"
import { AdminPage } from "./AdminPage"
import { useDispatch, useSelector } from "react-redux"
import {
    FETCH_QUOTAS,
    fetchQuotas,
    UPDATE_QUOTAS,
    updateQuotas,
} from "./actions"
import { apiRequestStateSelector } from "../api/selectors"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import LoadingButton from "@mui/lab/LoadingButton"
import { ToastNotifications } from "../notify/ToastNotifications"
import { LoadingContainer } from "../common/LoadingContainer"

const quotasSchema = Yup.object({
    maxRequests: Yup.number()
        .required("Value is required.")
        .positive("Value must be strictly positive"),
})

const useStyles = makeStyles((theme) => ({
    quotasContainer: {
        minWidth: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}))

const fetchReqSelector = apiRequestStateSelector([FETCH_QUOTAS])
const updateReqSelector = apiRequestStateSelector([UPDATE_QUOTAS])

/**
 * Container component for managing global quotas, e.g. max pages to be scraped by users.
 *
 * @author Robert Balazsi
 */
export const QuotasPageContainer = () => {
    const classes = useStyles()

    const [loading] = useSelector((state) => fetchReqSelector(state))
    const [saving] = useSelector((state) => updateReqSelector(state))
    const maxRequests = useSelector((state) => state.admin.quotas.maxRequests)
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchQuotas())
    }, [dispatch])

    const handleSave = React.useCallback(
        (values) => {
            dispatch(updateQuotas(values))
        },
        [dispatch]
    )

    return (
        <AdminPage
            activeTab="quotas"
            breadcrumbs={[{ label: "Quotas", link: "/admin/quotas" }]}
        >
            <ToastNotifications />

            <Box display="flex">
                        <Card className={classes.quotasContainer}>
                            <LoadingContainer loading={loading}>
                                <Formik
                                    enabledReinitialze
                                    initialValues={{
                                        maxRequests,
                                    }}
                                    validationSchema={quotasSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={handleSave}
                                >
                                    {({ values, errors, setFieldValue }) => (
                                        <Form>
                                            <Stack spacing={5}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    name="maxRequests"
                                                    error={!!errors.maxRequests}
                                                    helperText={errors.maxRequests}
                                                    value={values.maxRequests}
                                                    label="Max Requests per User"
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "maxRequests",
                                                            e.target.value
                                                        )
                                                    }
                                                />

                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                >
                                                    <LoadingButton
                                                        type="submit"
                                                        loading={saving}
                                                        color="primary"
                                                        variant="contained"
                                                    >
                                                        Save
                                                    </LoadingButton>
                                                </Box>
                                            </Stack>
                                        </Form>
                                    )}
                                </Formik>
                            </LoadingContainer>
                        </Card>
                    </Box>
        </AdminPage>
    )
}
