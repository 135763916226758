import {take, put, call} from 'redux-saga/effects'

/**
 * Returns the request Redux action for a given action prefix.
 */
export const requestAction = (prefix) => `${prefix}/REQUEST`

/**
 * Returns the success Redux action for a given action prefix.
 */
export const successAction = (prefix) => `${prefix}/SUCCESS`

/**
 * Returns the failure Redux action for a given action prefix.
 */
export const failureAction = (prefix) => `${prefix}/FAILURE`

/**
 * Returns the notification Redux action for a given action prefix.
 */
export const notifyAction = (prefix) => `${prefix}/NOTIFY`

/**
 * Returns whether a given action is a notification action.
 * @param action the action
 * @return {boolean} true if yes, false otherwise
 */
export const isNotifyAction = (action) => !action ? false : action.endsWith('/NOTIFY')

/**
 * Returns whether a given action is a success action.
 * @param action the action
 * @return {boolean} true if yes, false otherwise
 */
export const isSuccessAction = (action) => !action ? false : action.endsWith('/SUCCESS')

const getMetaValue = (meta, requestData, response, stage) => {
    if (!meta) {
        return null
    }

    if (typeof meta !== 'function') {
        return meta
    }

    return meta({requestData, response, stage})
}

const getNotifyObject = (notify, requestData, status, response) => {
    if (!notify) return null
    if (status === 'success' || (notify.failureAsSuccess && notify.failureAsSuccess(response, requestData))) {
        return notify.success
            ? {
                status: 'success',
                message: (typeof notify.success === 'string' ? notify.success : notify.success(response, requestData))
            }
            : null
    }

    return notify.failure
        ? {
            status: 'failure',
            message: (typeof notify.failure === 'string' ? notify.failure : notify.failure(response, requestData))
        }
        : null
}

/**
 * Sets up a Redux Saga with a full flow of watching an API action request, calling the fetcher and dispatching appropriate
 * actions for the different states of the request. It takes the following assumptions:
 * 1. The request action should have exactly one parameter named 'data', that is passed to the fetcher as the only argument
 * 2. The response of the fetch call has the following fields:
 *   - status: a brief status message
 *   - code: the status code
 *   - error: the error message (in case of failures)
 *   - result: the response payload
 *
 * @param actionPrefix the action's prefix, from which all the actions will be inferred
 * @param fetcher a function that is called right after the 'sending' action was dispatched
 * @param onSuccess an action creator that is to be dispatched on success
 * @param onFailure an action creator that is to be dispatched on failure
 * @param meta either an object or a function to return metadata in case of success/failure; if it's a function, it gets the
 *        bag of parameters: requestData, response, stage
 * @param notify an object of the form ({success: '[success_msg]', failure: '[failure_msg]'}) defining notification messages in the different states
 *        If set, the watcher will provide an object {status: 'success|failure', message: '[message]'} in the SUCCESS and FAILURE actions
 * @returns {Function} the resulting saga (technically a generator function)
 */
export const createApiRequestWatcher = ({actionPrefix, fetcher, onSuccess, onFailure, meta, notify}) => (function* () {
    while (true) {

        // Watch for the request
        const request = yield take(requestAction(actionPrefix))

        try {
            // Call the fetcher with the request's details
            const response = yield call(fetcher, request.data)

            // The fetch request is considered successful on any 2xx status code
            if (response.code >= 200 && response.code < 300) {
                yield put({
                    type: successAction(actionPrefix),
                    result: response.result,
                    meta: getMetaValue(meta, request.data, response, 'success')
                })

                // If the 'onSuccess' is set, dispatch it
                if (onSuccess) {
                    const postSuccessAction = onSuccess({requestData: request.data, response})
                    if (postSuccessAction) {
                        yield put(postSuccessAction)
                    }
                }

                // Notify on success, if enabled
                const notifyObj = getNotifyObject(notify, request.data, 'success', {code: response.code, error: null, result: response.result})
                if (notifyObj) {
                    yield put({
                        type: notifyAction(actionPrefix),
                        notify: notifyObj
                    })
                }
            }
            // Otherwise the fetch failed; dispatch the failure action with the appropriate status code and message
            else {
                yield put({
                    type: failureAction(actionPrefix),
                    error: {code: response.code, message: response.error},
                    meta: getMetaValue(meta, request.data, response, 'failure')
                })

                // If the 'onFailure' is set, dispatch it
                if (onFailure) {
                    const postFailureAction = onFailure({requestData: request.data, response})
                    if (postFailureAction) {
                        yield put(postFailureAction)
                    }
                }

                // Notify on failure, if enabled
                const notifyObj = getNotifyObject(notify, request.data, 'failure', {code: response.code, error: response.error, result: null})
                if (notifyObj) {
                    yield put({
                        type: notifyAction(actionPrefix),
                        notify: notifyObj
                    })
                }
            }
        }
            // There was been a network failure, i.e. the Server may be down
        catch (e) {
            console.error("[apiRequestWatcher] error: ", e)
            yield put({
                type: failureAction(actionPrefix),
                error: {status: 0, message: 'Network failure'},
                meta: getMetaValue(meta, request.data, null, 'failure'),
            })

            // Notify on network failure
            yield put({
                type: notifyAction(actionPrefix),
                notify: {
                    status: 'failure',
                    code: 0,
                    message: 'Network failure'
                }
            })
        }
    }
})
