import {requestAction, successAction} from "../saga-helpers"


export const EMAIL_SIGNUP = 'account/signup/email'
export const EMAIL_SIGNUP_REQUEST = requestAction(EMAIL_SIGNUP)
export const EMAIL_SIGNUP_SUCCESS = successAction(EMAIL_SIGNUP)

export const REQUEST_PASSWORD_RESET = 'account/requestPasswordReset'
export const REQUEST_PASSWORD_RESET_REQUEST = requestAction(REQUEST_PASSWORD_RESET)


export const emailSignup = (details) => ({
    type: EMAIL_SIGNUP_REQUEST,
    data: details
})

export const requestPasswordReset = (email) => ({
    type: REQUEST_PASSWORD_RESET_REQUEST,
    data: email
})
