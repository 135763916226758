import React from "react"
import { ConfirmationDialog } from "../common/ConfirmationDialog"
import { ScraperStatus } from "@datagrab/datagrab-common/constants"
import { CloneScraperDialog } from "./CloneScraperDialog"
import { Box, IconButton, Stack } from "@mui/material"
import FileCopyIcon from '@mui/icons-material/FileCopy'
import PlayIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import DeleteIcon from '@mui/icons-material/Delete'


/**
 * A toolbar of scraper actions displayed on mobile resolutions.
 */
export const ActionButtonsToolbar = ({
    scraper,
    startDisabled,
    onStart,
    onStop,
    onClone,
    onDelete,
}) => {
    const [stopRequested, setStopRequested] = React.useState(false)
    const [cloneRequested, setCloneRequested] = React.useState(false)
    const [deleteRequested, setDeleteRequested] = React.useState(false)

    const handleStart = React.useCallback(() => {
        onStart(scraper.id)
    }, [onStart, scraper.id])

    const handleStop = React.useCallback(() => {
        setStopRequested(false)
        onStop(scraper.id)
    }, [onStop, scraper.id])

    const handleClone = React.useCallback(
        (details) => {
            setCloneRequested(false)
            onClone({ ...scraper, ...details })
        },
        [onClone, scraper]
    )

    const handleDelete = React.useCallback(() => {
        setDeleteRequested(false)
        onDelete(scraper.id)
    }, [onDelete, scraper.id])

    return (
        <Box display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={6}>
                {scraper.status === ScraperStatus.STARTING ||
                scraper.status === ScraperStatus.RUNNING ? (
                    <IconButton onClick={() => setStopRequested(true)} size="large">
                        <StopIcon />
                    </IconButton>
                ) : (
                    <IconButton disabled={startDisabled} onClick={handleStart} size="large">
                        <PlayIcon />
                    </IconButton>
                )}

                <IconButton onClick={() => setCloneRequested(true)} size="large">
                    <FileCopyIcon />
                </IconButton>

                <IconButton
                    disabled={
                        scraper.status === ScraperStatus.STARTING ||
                        scraper.status === ScraperStatus.RUNNING ||
                        scraper.status === ScraperStatus.STOPPING
                    }
                    onClick={() => setDeleteRequested(true)}
                    size="large">
                    <DeleteIcon />
                </IconButton>

                {stopRequested && (
                    <ConfirmationDialog
                        title="Confirm Stop"
                        cancelButton={{ label: "No" }}
                        submitButton={{ label: "Yes, stop", danger: true }}
                        onCancel={() => setStopRequested(false)}
                        onSubmit={handleStop}
                    >
                        <p>
                            Are you sure you want to stop{" "}
                            <strong>{scraper.name}</strong>?
                        </p>
                        <p>
                            Note that data scraped up to this point is retained
                            so you can still download it.
                        </p>
                    </ConfirmationDialog>
                )}

                {cloneRequested && (
                    <CloneScraperDialog
                        scraper={scraper}
                        onClose={() => setCloneRequested(false)}
                        onSubmit={handleClone}
                    />
                )}

                {deleteRequested && (
                    <ConfirmationDialog
                        title="Confirm Deletion"
                        cancelButton={{ label: "No" }}
                        submitButton={{ label: "Yes, delete it", danger: true }}
                        onCancel={() => setDeleteRequested(false)}
                        onSubmit={handleDelete}
                    >
                        Deleting a scraper will also purge all its data. Are you
                        sure you want to delete it?
                    </ConfirmationDialog>
                )}
            </Stack>
        </Box>
    );
}
