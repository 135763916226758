import { isNotifyAction, isSuccessAction } from "../saga-helpers"
import {
    DISMISS_TOAST_NOTIFICATION,
    TRIGGER_NOTIFY,
} from "./actions"
import { LOGIN_FAILURE, SOCIAL_SIGNIN_FAILURE } from "../auth/actions"
import { ERROR_USER_DISABLED } from "../constants"
import { combineReducers } from "redux"

const SERVER_DOWN_MSG = "Connection error occurred. The server may be down due to maintenance. Please try again later."


const toast = (
    state = {
        status: null,
        message: null,
    },
    action = null
) => {
    // Notifications that were manually dispatched
    if (action.type === TRIGGER_NOTIFY) {
        return {
            ...state,
            status: action.status,
            message: action.message,
        }
    }

    // Trigger notification
    if (isNotifyAction(action.type)) {
        return (action.notify.code !== 0)
            ? {
                ...state,
                status: action.notify.status,
                message: action.notify.message
            }
            // Server is down
            : {
                ...state,
                status: "error",
                message:
                    action.notify.code === 0
                        ? SERVER_DOWN_MSG
                        : action.notify.message,
            }
    }

    // Clear previous failure if current action is success
    if (isSuccessAction(action.type) && state.status === "error") {
        return {
            ...state,
            status: null,
            message: null,
        }
    }

    // We make an exception for login failure notification as it is not handled by an API request watcher
    if (action.type === LOGIN_FAILURE) {
        if (action.error.status !== 0) {
            /* NOTE: We ignore the 400 status when the user is disabled as that will be displayed in a separate page along with
           an option wo resend the confirmation mail. */
            if (
                action.error.status === 400 &&
                action.error.message === ERROR_USER_DISABLED
            ) {
                return state
            }

            return {
                ...state,
                status: "error",
                message: "Invalid credentials",
            }
        } else {
            return {
                ...state,
                status: "error",
                message: SERVER_DOWN_MSG
            }
        }
    }

    // For social sign-in failures, we display the message unless there was a network error
    if (action.type === SOCIAL_SIGNIN_FAILURE) {
        return {
            ...state,
            status: "error",
            message: action.error.status === 0
                ? SERVER_DOWN_MSG
                : action.error.message,
        }
    }

    if (action.type === DISMISS_TOAST_NOTIFICATION) {
        return {
            ...state,
            status: null,
            message: null,
        }
    }

    return state
}


const notify = combineReducers({
    toast,
})

export default notify
