import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import makeStyles from '@mui/styles/makeStyles';
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";


const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: theme.borderRadius[2],
        [theme.breakpoints.down('sm')]: {
            border: 0,
            borderRadius: 0,
            boxShadow: 'none'
        }
    },
    table: {
        tableLayout: 'fixed',
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
        '&.wrap': {
            whiteSpace: 'normal'
        }
    },
    tr: {
        height: theme.spacing(7),
        borderBottom: `1px solid ${theme.palette.grey[200]}`,

        'tbody > &:last-child': {
            border: 0
        }
    },
    th: {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        backgroundColor: 'inherit',
        border: 0,
        fontWeight: 'bold'
    },
    td: {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        border: 0,
        '&.truncate': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

function desc(row1, row2, columns, orderBy) {
    const orderByCol = columns.find(col => col.id === orderBy);

    // Column has a custom sort function
    if (orderByCol && orderByCol.sortFunc) {
        return orderByCol.sortFunc(orderByCol.cell({row: row1}), orderByCol.cell({row: row2}));
    }

    // Sort by column IDs
    if (row2[orderBy] < row1[orderBy]) {
        return -1;
    }
    if (row2[orderBy] > row1[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(columns, order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, columns, orderBy) : (a, b) => -desc(a, b, columns, orderBy);
}


const EnhancedTableHead = ({columns, classes, selectionEnabled, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort}) => {
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className={classes.tr}>
                {selectionEnabled && <TableCell className={classes.th} padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>}
                {columns.map(col => (
                    <TableCell
                        className={classes.th}
                        style={{width: col.width ? col.width : 'auto'}}
                        key={col.id}
                        align={col.align ? col.align : 'left'}
                        padding={col.padding ? col.padding : 'normal'}
                        sortDirection={(col.sortable && orderBy === col.id) ? order : false}
                    >
                        {col.sortable ? <TableSortLabel
                            active={orderBy === col.id}
                            direction={orderBy === col.id ? order : 'asc'}
                            onClick={createSortHandler(col.id)}
                        >
                            {col.header}
                            {orderBy === col.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel> : col.header}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

/**
 * A reusable, responsive table component. Supports element selection and sorting.
 *
 * @author Robert Balazsi
 */
export const DataTable = ({columns, data, wrap, emptyMessage, selectionEnabled, selected, defaultSort, onSelect, ...others}) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState(defaultSort ? defaultSort.order : 'asc');
    const [orderBy, setOrderBy] = React.useState(defaultSort ? defaultSort.by : null);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = React.useCallback( event => {
        if (event.target.checked) {
            const allIDs = data.map(n => n.id);
            onSelect(allIDs);
            return;
        } else {

        }
        onSelect([]);
    }, [onSelect, data]);

    const handleSelect = React.useCallback( (event, id) => {
        const selectedIDs = !selected ? [] : selected;
        const selectedIndex = selectedIDs.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedIDs, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedIDs.slice(1));
        } else if (selectedIndex === selectedIDs.length - 1) {
            newSelected = newSelected.concat(selectedIDs.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedIDs.slice(0, selectedIndex),
                selectedIDs.slice(selectedIndex + 1),
            );
        }

        onSelect(newSelected);
    }, [selected, onSelect]);

    const isSelected = id => selected.indexOf(id) !== -1;

    return <TableContainer className={classes.container}>
        <Table {...others} className={`${classes.table} ${wrap ? 'wrap' : ''}`}>
            <EnhancedTableHead
                columns={columns}
                classes={classes}
                numSelected={!selected ? 0 : selected.length}
                order={order}
                orderBy={orderBy}
                selectionEnabled={selectionEnabled}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
            />

            <TableBody>
                {data.length > 0 ? stableSort(data, getSorting(columns, order, orderBy))
                    .map((row, index) => {
                        const isItemSelected = !selected ? false : isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return <TableRow hover={selectionEnabled}
                                         aria-checked={isItemSelected}
                                         selected={isItemSelected}
                                         className={classes.tr} key={row.id}>
                            {selectionEnabled && <TableCell className={classes.td} padding="checkbox">
                                <Checkbox
                                    checked={isItemSelected}
                                    onChange={event => handleSelect(event, row.id)}
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </TableCell>}

                            {columns.map(col => <TableCell align={col.align ? col.align : 'left'}
                                                           style={{width: col.width ? col.width : 'auto'}}
                                                           padding={col.padding ? col.padding : 'normal'}
                                                           key={row.id + "_" + col.id}
                                                           className={`${classes.td} ${col.truncate ? 'truncate' : ''}`}>{!col.render ? col.cell({row}) : col.render(col.cell({row}))}</TableCell>)}

                        </TableRow>}) : <TableRow className={classes.tr}>
                    <TableCell className={classes.td} colSpan={!selectionEnabled ? columns.length : columns.length+1}>{emptyMessage}</TableCell>
                </TableRow>
                }
            </TableBody>
        </Table>
    </TableContainer>
};