/**
 * Defines utility functions.
 */

import jwtDecode from "jwt-decode"
import {EXTENSION_NOT_INSTALLED, VERSIONED_JSON_MIME_TYPE} from "./constants"
import firebase from './auth/firebase'


export const getAccessToken = () => {
    return localStorage.getItem("access_token")
}

export const withAuth = (headers = {}) => ({
    ...headers,
    'Authorization': `Bearer ${getAccessToken()}`
})

export const jsonWithAuth = () => withAuth({
    'Accept': VERSIONED_JSON_MIME_TYPE,
    'Content-Type': VERSIONED_JSON_MIME_TYPE
})

export const hasAdminRole = () => {
    const token = getAccessToken()
    if (!token) {
        return false
    }
    const user = jwtDecode(token)
    return user.role === 'ADMIN'
}

export const upsertInArray = (arr, obj) => {
    const resultArr = [...arr]
    const index = resultArr.findIndex((e) => e.id === obj.id)

    if (index === -1) {
        resultArr.push(obj)
    } else {
        resultArr[index] = obj
    }

    return resultArr
}

export const replaceElement = (arr, index, obj) => !obj ? [...arr] : [
    ...arr.slice(0, index),
    obj,
    ...arr.slice(index+1, arr.length)
]

/**
 * Exports textual data to a given file, inserts a downloadable link in the document and clicks it for automatic download.
 * @param mimeType the MIME type of the data (e.g. 'text/csv' or 'application/json')
 * @param text the textual data
 * @param fileName the output file's name
 */
export const exportTextToFile = ({mimeType, text, fileName}) => {
    const dataUri = `data:${mimeType};charset=utf-8,${encodeURIComponent(text)}`

    let linkElement = document.createElement('a')
    linkElement.setAttribute('href', dataUri)
    linkElement.setAttribute('download', fileName)
    linkElement.click()
}

/**
 * Checks if the current browser is Chrome.
 */
export const isChrome = () => !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

/**
 * Gets the access token of the user via Firebase. Used for invoking authenticated endpoints.
 * @type {function(): Promise<any>} a Promise resolving with the token, or rejecting if the user is not authenticated
 */
export const withAuthToken = () => new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser
    if (!user) {
        reject('User is not authenticated.')
    } else {
        user.getIdToken(true).then(token => resolve(token))
            .catch(e => reject(e))
    }
})

/**
 * Check whether a given value is a string.
 * @param val the value
 */
export const isString = (val) => typeof val === 'string' || val instanceof String

/**
 * Sends a message to the Chrome extension, if available.
 *
 * @param message the payload of the message
 * @param callback called with the response from the extension (if any)
 */
export const sendMessageToExtension = (message, callback = f=>f) => {
    if (window.chrome && window.chrome.runtime) {
        window.chrome.runtime.sendMessage(process.env.REACT_APP_CHROME_EXTENSION_ID, message, (response) => {
            if (callback) {
                if (window.chrome.runtime.lastError || !response) {
                    callback({version: EXTENSION_NOT_INSTALLED})
                } else {
                    callback(response)
                }
            }
        })
    }
    /* NOTE: For window.chrome to be set on the page, at least one extension needs to have the web app's URL
       in its externally_connectable settings. So we account for that too. */
    else if (callback) {
        callback({version: EXTENSION_NOT_INSTALLED})
    }
}

/**
 * Returns the favicon URL (https://<origin>/favicon.icon) for a given URL.
 *
 * @param url the URL
 */
export const getFaviconUrl = (url) => {
    try {
        const origin = new URL(url).origin

        return `${origin}/favicon.ico`
    } catch (e) {
        return null
    }
}