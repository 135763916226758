import React from 'react'
import {bulkCredits} from '@datagrab/datagrab-common/products'
import LoadingButton from "@mui/lab/LoadingButton"
import {ModalDialog} from "../../common/ModalDialog"
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import {TextField} from "@mui/material"
import {PlanButton} from "./PlanButton"
import Typography from "@mui/material/Typography"

const products = bulkCredits(process.env.NODE_END === 'development')


const useStyles = makeStyles( theme => ({
    packageButton: {
        marginTop: theme.spacing(5),

        '&:first-child': {
            marginTop: 0
        }
    },
    fieldWrapper: {
        width: '100%',
        maxWidth: 250
    },
    priceSection: {

    },
    priceValue: {
        fontWeight: 'bold',
        fontSize: theme.fontSize[6],
    },
    totalsSection: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(3),
        borderRadius: theme.borderRadius[3]
    },
    divider: {
        border: 'none',
        height: 1,
        backgroundColor: theme.palette.grey[200],
        margin: `${theme.spacing(5)} 0`
    },
}))

const isValidQuantity = (quan) => !isNaN(quan) && quan > 0

/**
 * A modal dialog shown for the user to pick a bulk package, along with the quantity.
 */
export const BulkPackageSelectionDialog = ({onClose = f=>f, onSubmit = f=>f, submitting}) => {
    const classes = useStyles()
    const [bulk, setBulk] = React.useState('x10k')
    const [quantity, setQuantity] = React.useState(1)

    const selectedProduct = products.find(p => p.code === bulk)

    const handleSetQuantity = (e) => setQuantity(isNaN(e.target.value) ? e.target.value : parseInt(e.target.value))

    return (
        <ModalDialog title="Choose Package"
                            closeButton
                            onClose={onClose}
                            maxWidth="md"
                            buttons={[
                                <LoadingButton key="submit_button"
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              onClick={() => onSubmit({bulk, quantity})}
                                              loading={submitting}
                                              disabled={!isValidQuantity(quantity)}>Go To Checkout</LoadingButton>
                            ]}>

            <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={7}>
                    {products.map( (product, i) => <PlanButton key={i}
                                                               className={classes.packageButton}
                                                               name={product.name}
                                                               active={bulk === product.code}
                                                               onClick={() => setBulk(product.code)}
                                                               price={<Box display="flex" flexDirection="column" alignItems="center">
                        <span className={classes.priceValue}>${product.price}</span>
                        <Typography noWrap><strong>{product.amount.toLocaleString()}</strong> credits</Typography>
                    </Box>}/>)}
                </Grid>

                <Grid item xs={12} md={5}>
                    <div className={classes.totalsSection}>
                        <TextField horizontal
                                   fullWidth
                                   type="number"
                                   InputProps={{inputProps: {min: 1, max: 100}}}
                                   label="Quantity"
                                   value={quantity}
                                   onChange={handleSetQuantity}/>

                        <hr className={classes.divider} />

                        <div>
                            <Box display="flex" justifyContent="space-between" mb={4}>
                                <span className={classes.label}>Total Credits</span>
                                <strong>{quantity * selectedProduct.amount}</strong>
                            </Box>

                            <Box display="flex" justifyContent="space-between">
                                <span className={classes.label}>Total Price</span>
                                <strong>${quantity * selectedProduct.price}</strong>
                            </Box>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </ModalDialog>
    );
}