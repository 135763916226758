import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { AdminPage } from "./AdminPage"
import { Box, Card, CardContent, Chip, Link, Rating, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { apiRequestStateSelector } from "../api/selectors"
import { FETCH_ALL_USERS_DATA, FETCH_USER_FEEDBACK, fetchAllUsersData, fetchUserFeedback } from "./actions"
import { Link as RouterLink } from "react-router-dom"
import { FEEDBACK_TYPES } from "../feedback/constants"
import { LoadingContainer } from "../common/LoadingContainer"


const useStyles = makeStyles((theme) => ({
    feedbackCard: {
        maxWidth: 600
    },
    small: {
        fontSize: theme.fontSize[1],
        color: theme.palette.grey[700]
    },
    secondarySection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    }
}))

const reqSelector = apiRequestStateSelector([FETCH_USER_FEEDBACK, FETCH_ALL_USERS_DATA])

const fillUsersMap = (users) => {
    const map = {}
    users.forEach(user => {
        map[user.id] = user.fullName ? user.fullName : user.firstName + ' ' + user.lastName
    })

    return map
}

const buildFeedbackTypesMap = () => {
    const map = {}
    FEEDBACK_TYPES.forEach(type => {
        map[type.value] = type
    })
    return map
}

const FEEDBACK_TYPES_MAP = buildFeedbackTypesMap()

const renderFeedbackType = (type) => {
    if (!type || !FEEDBACK_TYPES_MAP[type]) {
        return <Chip size="small" label="Uncategorized" />
    }

    return <Chip size="small" color={FEEDBACK_TYPES_MAP[type].color} label={FEEDBACK_TYPES_MAP[type].label} />
}

/**
 * Displays user feedback.
 */
export const UserFeedbackPageContainer = () => {
    const classes = useStyles()

    const [loading,] = useSelector(state => reqSelector(state))
    const feedback = useSelector(state => state.admin.feedback)

    const usersData = useSelector(state => state.admin.users.all.list)
    const usersMap = fillUsersMap(usersData)

    const dispatch = useDispatch()

    // Fetch the users' feedback on mount
    React.useEffect(() => {
        dispatch(fetchUserFeedback())
    }, [dispatch])

    // Fetch the users' data on mount
    React.useEffect(() => {
        dispatch(fetchAllUsersData())
    }, [dispatch])

    return <AdminPage activeTab="feedback" breadcrumbs={[{ label: 'Feedback', link: '/admin/feedback' }]}>
        <LoadingContainer loading={loading}>
            <Stack spacing={7}>
                {!feedback || feedback.length === 0
                    ? <Card className={classes.feedbackCard}>
                        <CardContent>No feedback received yet.</CardContent>
                    </Card>
                    : feedback.map(item =>
                        <Card key={item.id} className={classes.feedbackCard}>
                            <CardContent>
                                <Stack fullWidth column spacing={7}>
                                    <div>
                                        <Box display="flex" flexWrap="wrap" justifyContent="space-between"
                                            alignItems="center">
                                            <Typography variant="h6">
                                                <Link component={RouterLink}
                                                    to={"/admin/users/" + item.userId}>{usersMap[item.userId]}</Link>
                                            </Typography>
                                            <span
                                                className={classes.small}>{new Date(item.created * 1000).toLocaleString()}</span>
                                        </Box>

                                        <div className={classes.secondarySection}>
                                            <Rating value={item.rating} readOnly />

                                            {renderFeedbackType(item.type)}
                                        </div>
                                    </div>

                                    <Typography variant="body1">{item.comment}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>)}
            </Stack>
        </LoadingContainer>
    </AdminPage>
}