import {combineReducers} from "redux";
import {
    FETCH_ALL_LOGS_SUCCESS,
    FETCH_ALL_USERS_DATA_SUCCESS,
    FETCH_QUOTAS_SUCCESS,
    FETCH_SYSTEM_STATS_SUCCESS, FETCH_USER_ACTIVITY_SUCCESS, FETCH_USER_DATA_SUCCESS, FETCH_USER_FEEDBACK_SUCCESS,
    FETCH_USER_SCRAPERS_SUCCESS
} from "./actions";
import {upsertInArray} from "../utils";

const all = (state = {
    list: []
}, action = null) => {
    switch (action.type) {
        case FETCH_ALL_USERS_DATA_SUCCESS:
            return {
                ...state,
                list: action.result
            };

        case FETCH_USER_DATA_SUCCESS:
            return {
                ...state,
                list: upsertInArray(state.list, action.result)
            };

        default:
            return state;
    }
};

const activity = (state = [], action = null) => {
    if (action.type === FETCH_USER_ACTIVITY_SUCCESS) {
        return action.result;
    }

    return state;
};

const scrapers = (state = [], action = null) => {
    if (action.type === FETCH_USER_SCRAPERS_SUCCESS) {
        return action.result;
    }

    return state;
};

const current = combineReducers({
    activity,
    scrapers
});

const users = combineReducers({
    all,
    current
});

const stats = (state = {}, action = null) => {
    if (action.type === FETCH_SYSTEM_STATS_SUCCESS) {
        return action.result;
    } else {
        return state;
    }
};

const quotas = (state = {}, action = null) => {
    if (action.type === FETCH_QUOTAS_SUCCESS) {
        return action.result;
    } else {
        return state;
    }
};

const feedback = (state = [], action = null) => {
    if (action.type === FETCH_USER_FEEDBACK_SUCCESS) {
        return action.result;
    } else {
        return state;
    }
};

const logs = (state = [], action = null) => {
    if (action.type === FETCH_ALL_LOGS_SUCCESS) {
        return action.result;
    } else {
        return state;
    }
};


const admin = combineReducers({
    users,
    stats,
    quotas,
    feedback,
    logs
});

export default admin;