import React from 'react'
import LoadingButton from "@mui/lab/LoadingButton"
import {Formik, Form} from 'formik'
import {Link as RouterLink} from "react-router-dom"
import {TextField, Link, Stack} from "@mui/material"
import {BaseAccountPage} from "../account/BaseAccountPage"
import {useDispatch, useSelector} from "react-redux"
import {LOGIN, login, SOCIAL_SIGNIN, socialSignIn} from "./actions"
import {ToastNotifications} from "../notify/ToastNotifications"
import {apiRequestStateSelector} from "../api/selectors"
import {GoogleSocialButton} from "../common/GoogleSocialButton"
import {OptionsSeparator} from "../account/OptionsSeparator"


let yup = require('yup')

const loginSchema = yup.object({
    userName: yup.string().required("Cannot be empty"),
    password: yup.string().required("Cannot be empty")
})

const loginStateSelector = apiRequestStateSelector([LOGIN])
const socialLoginStateSelector = apiRequestStateSelector([SOCIAL_SIGNIN])

/**
 * Sign-in page.
 */
export const SigninPage = () => {
    const [loginSubmitting,] = useSelector(state => loginStateSelector(state))
    const [socialLoginSubmitting,] = useSelector(state => socialLoginStateSelector(state))
    const dispatch = useDispatch()

    const handleLogin = React.useCallback((details) => {
        dispatch(login(details.userName, details.password))
    }, [dispatch])

    const handleSocialSignin = React.useCallback(() => {
        dispatch( socialSignIn('google') )
    }, [dispatch])

    return <BaseAccountPage title="Welcome back"
                            subTitle={<React.Fragment>Don't have an account? <Link component={RouterLink} to="/signup"
                                                                                   variant="body2">Sign
                                up</Link></React.Fragment>}
                            maxWidth="xs">
        <ToastNotifications/>

        <Formik
            validationSchema={loginSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleLogin}
            initialValues={{
                userName: '',
                password: ''
            }}
        >
            {({
                  values,
                  setFieldValue,
                  errors
              }) => <Form style={{width: '100%'}} noValidate>
                <Stack spacing={5}>
                    <GoogleSocialButton fullWidth
                                        size="large"
                                        loading={socialLoginSubmitting}
                                        onClick={handleSocialSignin}>Sign in with Google</GoogleSocialButton>

                    <OptionsSeparator/>

                    <TextField
                        fullWidth
                        id="userName"
                        label="Email"
                        name="userName"
                        autoComplete="email"
                        autoFocus
                        error={!!errors.userName}
                        helperText={errors.userName}
                        value={values.userName}
                        onChange={(e) => setFieldValue('userName', e.target.value)}
                    />
                    <TextField
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={values.password}
                        error={!!errors.password}
                        helperText={errors.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                    />

                    <div>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            loading={loginSubmitting}>Sign in</LoadingButton>

                        <Link style={{display: 'inline-block', marginTop: 12}} component={RouterLink} to="/forgot" variant="body2">Forgot password?</Link>
                    </div>
                </Stack>
            </Form>
            }
        </Formik>
    </BaseAccountPage>
}
