import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {
    FETCH_SCRAPER,
    fetchScraper, startScraper, stopScraper, updateScraperGeneralDetails, UPDATE_SCRAPER_DETAILS, loadScraperForSetup, START_SCRAPER, STOP_SCRAPER, selectScraperRun,
} from "../actions"
import {UserPage} from "../../site/UserPage"
import ErrorBoundary from "../../common/ErrorBoundary"
import ScraperDetailsPage from "./ScraperDetailsPage"
import {apiRequestStateSelector} from "../../api/selectors"
import {LoadingContainer} from "../../common/LoadingContainer"
import {fetchUserProfile} from "../../settings/actions"
import {useCurrentProfile} from "../../profile/hooks"
import {useScraper} from "../hooks"
import {useExtensionVersion} from "../../hooks"
import {EXTENSION_NOT_INSTALLED} from "../../constants"
import { exportScraper } from '../exporter'
import { exportTextToFile } from '../../utils'
import { sanitizeStringForFilename } from '@datagrab/datagrab-common/utils'
import { useScraperLiveMonitor } from '../../live-events/hooks'
import { processLiveEvent } from '../../live-events/actions'


const reqSelector = apiRequestStateSelector([FETCH_SCRAPER])
const startScraperSelector = apiRequestStateSelector([START_SCRAPER])
const stopScraperSelector = apiRequestStateSelector([STOP_SCRAPER])
const patchScraperSelector = apiRequestStateSelector([UPDATE_SCRAPER_DETAILS])


/**
 * Container component for managing the settings of a scraper. It wraps the presentational component in an error boundary.
 */
const ScraperDetailsPageContainer = ({match}) => {
    const {params: {id}} = match

    const userId = useSelector(state => !state.profile ? undefined : state.profile.userId)
    const dispatch = useDispatch()
    const extVersion = useExtensionVersion()
    const [loading,] = useSelector(state => reqSelector(state))
    const [starting,] = useSelector(state => startScraperSelector(state))
    const [stopping,] = useSelector(state => stopScraperSelector(state))
    const [settingsSubmitting,] = useSelector(state => patchScraperSelector(state))
    const scraper = useScraper(id)
    const {subscriptions, bulkCredits, maxParallelScrapers, runningScrapers, connectedAccounts} = useCurrentProfile()
    const parallelLimitReached = runningScrapers >= maxParallelScrapers

    // NOTE: We only consider the platform subscription (at most one at the moment)
    const subscription = !subscriptions ? undefined : subscriptions.find(s => s.category === 'platform')

    // Refresh the user's profile
    React.useEffect(() => {
        dispatch(fetchUserProfile())
    }, [dispatch])

    // Refresh the scraper's data on mount
    React.useEffect(() => {
        if (id) {
            dispatch(fetchScraper(id))
        }
    }, [dispatch, id])

    // Monitor live events
    const handleLiveEvent = React.useCallback( (event) => {
        try {
            const eventData = JSON.parse(event)
            dispatch(processLiveEvent(eventData))
        } catch (e) {
            console.error('Error parsing live event. ', e)
        }
    },  [dispatch])
    useScraperLiveMonitor(userId, handleLiveEvent)

    const handleStart = React.useCallback(() => {
        dispatch(startScraper(scraper.id))
    }, [dispatch, scraper])

    const handleStop = React.useCallback(() => {
        dispatch(stopScraper(scraper.id))
    }, [dispatch, scraper])

    const handleOpenWithExtension = React.useCallback(() => {
        loadScraperForSetup(exportScraper(scraper))
    }, [scraper])

    const handleSaveSettings = React.useCallback( ({id, data}) => {
        dispatch(updateScraperGeneralDetails(id, data))
    }, [dispatch])

    const handleExportConfig = React.useCallback(() => {
        const exportedScraper = exportScraper(scraper)
        const sanitizedName = sanitizeStringForFilename(exportedScraper.name)

        exportTextToFile({ mimeType: 'application/json', text: JSON.stringify(exportedScraper), fileName: `${sanitizedName}_config.json` })
    }, [scraper])

    const handleSelectRun = React.useCallback( (runID) => {
        dispatch(selectScraperRun({scraperID: scraper.id, runID}))
    }, [dispatch, scraper])

    const breadcrumbs = [{label: 'Scrapers', link: '/scrapers'}, {label: scraper ? scraper.name : ''}]

    return <UserPage activeTab="scrapers" breadcrumbs={breadcrumbs}>
        <ErrorBoundary>
            <LoadingContainer loading={loading}>
                {!scraper
                    ? 'Scraper not found'
                    : <ScraperDetailsPage extensionMissing={extVersion === EXTENSION_NOT_INSTALLED}
                                          loading={loading}
                                          subscription={subscription}
                                          bulkCredits={bulkCredits}
                                          scraper={scraper}
                                          maxParallel={maxParallelScrapers}
                                          parallelLimitReached={parallelLimitReached}
                                          connectedAccounts={connectedAccounts}
                                          onStart={handleStart}
                                          starting={starting}
                                          onStop={handleStop}
                                          stopping={stopping}
                                          onOpenWithExtension={handleOpenWithExtension}
                                          onSaveSettings={handleSaveSettings}
                                          onExportConfig={handleExportConfig}
                                          settingsSubmitting={settingsSubmitting} 
                                          onSelectRun={handleSelectRun} />}
            </LoadingContainer>
        </ErrorBoundary>
    </UserPage>
}

ScraperDetailsPageContainer.displayName = 'ScraperDetailsPageContainer'

export default ScraperDetailsPageContainer