import {combineReducers} from "redux";
import {LOCATION_CHANGE} from 'connected-react-router'
import {RESET_API_ERROR_STATE} from "./actions";


const loading = (state = {}, action = null) => {
    const matches = /(.*)\/(REQUEST|SUCCESS|FAILURE)/.exec(action.type);

    // Reset loading states when user navigates away or they refresh the page to avoid stuck loading states
    if (action.type === LOCATION_CHANGE) {
        return {}
    }

    if (!matches)  return state;

    const [, actionPrefix, requestState] = matches;

    return {
        ...state,
        [actionPrefix]: (requestState === 'REQUEST')
    }
};

const error = (state = {}, action = null) => {

    // Reset the error
    if (action.type === RESET_API_ERROR_STATE) {
        let actionMap = {};
        action.actionPrefixes.forEach(prefix => actionMap[prefix] = null);
        return {
            ...state,
            ...actionMap
        }
    }

    // Request / Failure actions
    const matches = /(.*)\/(REQUEST|FAILURE)/.exec(action.type);

    if (!matches)  return state;

    const [, actionPrefix, requestState] = matches;

    return {
        ...state,
        [actionPrefix]: (requestState === 'FAILURE') ? {
            code: action.error.code,
            message: action.error.message
        } : null
    }
};

const api = combineReducers({
    loading,
    error
});

export default api;