import React from "react"
import {
    Box,
    Button,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { ScraperStatus } from "@datagrab/datagrab-common/constants"
import LoadingButton from "@mui/lab/LoadingButton"
import { displayScraperStatus } from "../utils"


const Title = styled("h2")(({ theme }) => ({
    margin: 0,
    color: theme.palette.grey[900],
    fontSize: theme.fontSize[7],
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 500
}))

const getLastRunTime = (scraper) => {
    if (scraper.status === ScraperStatus.RUNNING) {
        return (
            <Typography my={1} fontSize={12}>
                Started at{" "}
                <strong>
                    {new Date(scraper.lastStarted).toLocaleString()}
                </strong>
            </Typography>
        )
    }

    if (!scraper.lastFinished) {
        return (
            <Typography my={1} fontSize={12}>
                Was never run.
            </Typography>
        )
    }

    return (
        <Typography my={1} fontSize={12}>
            Last finished at{" "}
            <strong>{new Date(scraper.lastFinished).toLocaleString()}</strong>
        </Typography>
    )
}

export const ScraperManager = ({
    scraper,
    startDisabled,
    extensionMissing,
    onStart = (f) => f,
    starting,
    onStop = (f) => f,
    stopping,
    onOpenWithExtension = (f) => f,
    onChangeSettings = (f) => f,
    onExportConfig = (f) => f,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClose = React.useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleOpenWithExtension = React.useCallback(() => {
        onOpenWithExtension()
        handleClose()
    }, [onOpenWithExtension, handleClose])

    const handleChangeSettings = React.useCallback(() => {
        onChangeSettings()
        handleClose()
    }, [onChangeSettings, handleClose])

    const handleExportConfig = React.useCallback(() => {
        onExportConfig()
        handleClose()
    }, [onExportConfig, handleClose])

    const handleMenu = React.useCallback((e) => {
        setAnchorEl(e.currentTarget)
    }, [])

    return <div>
        <Title>{scraper.name}</Title>

        <Box mt={4}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{maxWidth: 400}}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={4}>
                <div>
                    <Typography my={1} fontSize={12}>
                        Created at{" "}
                        <strong>
                            {new Date(scraper.createdAt).toLocaleString()}
                        </strong>
                    </Typography>

                    {getLastRunTime(scraper)}
                </div>

                {displayScraperStatus(scraper)}
            </Stack>

            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                spacing={4}
                mt={5}
            >
                <Stack direction="row" spacing={4}>
                    {scraper.status !== ScraperStatus.STARTING &&
                        scraper.status !== ScraperStatus.RUNNING ? (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={startDisabled || starting}
                            loading={starting}
                            sx={{ width: 96 }}
                            onClick={onStart}
                        >
                            Start
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            variant="outlined"
                            color="error"
                            sx={{ width: 96 }}
                            onClick={onStop}
                            loading={stopping}
                        >
                            Stop
                        </LoadingButton>
                    )}

                    <div>
                        <Button
                            variant="outlined"
                            style={{ width: 96 }}
                            onClick={handleMenu}
                            aria-controls="actions-menu"
                            aria-haspopup="true"
                        >
                            More...
                        </Button>

                        <Menu
                            id="actions-menu"
                            anchorEl={anchorEl}
                            disableScrollLock
                            open={Boolean(anchorEl)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            onClose={handleClose}
                        >
                            <MenuItem
                                disabled={extensionMissing}
                                onClick={handleOpenWithExtension}
                            >
                                Open with Extension
                            </MenuItem>
                            <MenuItem onClick={handleChangeSettings}>
                                Change Settings
                            </MenuItem>
                            <MenuItem onClick={handleExportConfig}>
                                Export Configuration
                            </MenuItem>
                        </Menu>
                    </div>
                </Stack>
            </Stack>
        </Box>
    </div>
}
