import React from 'react'
import Link from "@mui/material/Link"
import {Link as RouterLink} from "react-router-dom"
import {displayLastRunDate, displayScraperStatus} from "./utils"
import {ActionButtonsToolbar} from "./ActionButtonsToolbar"
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.borderRadius[2],
        display: 'grid',
        gridTemplateRows: `repeat(4, ${theme.spacing(7)})`,
        marginBottom: theme.spacing(7),
        '&:last-child': {
            marginBottom: 0
        },
    },
    dataRow: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        '&:last-child': {
            borderBottom: 0
        },
        display: 'flex',
        alignItems: 'center',
        minWidth: 0
    },
    dataLabel: {
        color: theme.palette.grey[900],
        width: 150,
    },
    dataValue: {
        color: theme.palette.grey[600],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%'
    }
}))

/**
 * Displays the list of scrapers as scrollable cards, on mobile resolutions. It allows starting/stopping and deleting them.
 *
 * @param scrapers the list of scrapers
 * @param startDisabledReason if starting scrapers is disabled, this will hold the reason for it
 * @param onStart called when the user starts a scraper
 * @param onStop called when the user stops a scraper
 * @param onClone called when the user clones a scraper
 * @param onDelete called when the user deletes a scraper
 */
export const ScraperCardList = ({scrapers, startDisabled, onStart, onStop, onClone, onDelete}) => {
    const classes = useStyles()

    return <div>
        {scrapers.map(scraper => <div className={classes.card} key={scraper.id}>
            <div className={classes.dataRow}>
                <span className={classes.dataLabel}>Name</span>
                <div className={classes.dataValue}>
                    <Link component={RouterLink} to={`/scrapers/${scraper.id}`}>{scraper.name}</Link>
                </div>
            </div>
            <div className={classes.dataRow}>
                <span className={classes.dataLabel}>Status</span>
                <div className={classes.dataValue}>
                    {displayScraperStatus(scraper)}
                </div>
            </div>
            <div className={classes.dataRow}>
                <span className={classes.dataLabel}>Last Run</span>
                <div className={classes.dataValue}>
                    {displayLastRunDate(scraper)}
                </div>
            </div>
            <div className={classes.dataRow}>
                <ActionButtonsToolbar scraper={scraper}
                                      startDisabled={startDisabled}
                                      onStart={onStart}
                                      onStop={onStop}
                                      onClone={onClone}
                                      onDelete={onDelete}/>
            </div>
        </div>)}
    </div>
}