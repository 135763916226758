import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from "@mui/material"
import React from "react"
import { Tabs } from "../../common/Tabs"
import { DataPanelContainer } from "./DataPanelContainer"
import { LogsPanelContainer } from "./LogsPanelContainer"
import { RunSelector } from "./RunSelector"
import { Stats } from "./Stats"

const getRun = (scraper, runID) =>
    scraper.currentRun && scraper.currentRun.runId === runID
        ? scraper.currentRun
        : scraper.runHistory.find((r) => r.runId === runID)

export const RunsPanel = ({scraper, selectedRunID, onSelectRun = f=>f}) => {
    const selectedRun = getRun(scraper, selectedRunID)
    const [activeTab, setActiveTab] = React.useState("logs")

    return (
        <Card>
            <CardHeader title="Runs" />
            <CardContent>
                {!selectedRunID ? (
                    <Typography variant="body2">
                        Hasn't been run yet.
                    </Typography>
                ) : (
                    <Stack spacing={6}>
                        <Stack direction="row" spacing={5}>
                            <RunSelector
                                currentRun={scraper.currentRun}
                                runHistory={scraper.runHistory}
                                selected={selectedRunID}
                                onSelect={(e) =>onSelectRun(e.target.value)}
                            />
                            <Box display="flex" alignItems="center">
                                <span>Pages:</span>
                                <Stats
                                    pagesScraped={selectedRun.pagesScraped}
                                    pagesFailed={selectedRun.pagesFailed}
                                />
                            </Box>
                        </Stack>

                        <Tabs
                            tabs={[
                                { value: "logs", label: "Logs" },
                                { value: "data", label: "Data" },
                            ]}
                            activeTab={activeTab}
                            onChange={setActiveTab}
                        />

                        {activeTab === "logs" && (
                            <LogsPanelContainer scraper={scraper} runID={selectedRunID} />
                        )}
                        {activeTab === "data" && (
                            <DataPanelContainer scraper={scraper} runID={selectedRunID} />
                        )}
                    </Stack>
                )}
            </CardContent>
        </Card>
    )
}
