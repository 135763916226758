import React from 'react'
import { useDispatch } from "react-redux"
import { changePassword } from "./actions"
import { Form, Formik } from "formik"
import { Stack, TextField } from "@mui/material"
import * as Yup from "yup"
import { Button } from "@mui/material"


const changePasswordSchema = Yup.object({
    oldPassword: Yup.string().required('Old password is required.'),
    newPassword: Yup.string().required('New password is required.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Passwords must match.")
        .required("Confirm your new password.")
})


/**
 * Form for changing the user's password.
 */
export const ChangePasswordForm = () => {
    const dispatch = useDispatch()

    const handleChangePassword = React.useCallback((details, { resetForm }) => {
        dispatch(changePassword({
            oldPassword: details.oldPassword,
            newPassword: details.newPassword
        }))

        resetForm()
    }, [dispatch])

    const handleKeyDown = React.useCallback((e) => {
        if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault()
        }
    }, [])

    return <Formik validationSchema={changePasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }}
        onSubmit={handleChangePassword}>
        {
            ({ values, errors, setFieldValue }) =>
                <Form onKeyDown={handleKeyDown}>
                    <Stack spacing={6}>
                        <TextField
                            fullWidth
                            id="oldPassword"
                            label="Old Password"
                            name="oldPassword"
                            type="password"
                            error={!!errors.oldPassword}
                            helperText={errors.oldPassword}
                            value={values.oldPassword}
                            onChange={(e) => setFieldValue('oldPassword', e.target.value)}
                        />

                        <TextField
                            fullWidth
                            id="newPassword"
                            label="New Password"
                            name="newPassword"
                            type="password"
                            error={!!errors.newPassword}
                            helperText={errors.newPassword}
                            value={values.newPassword}
                            onChange={(e) => setFieldValue('newPassword', e.target.value)}
                        />

                        <TextField
                            fullWidth
                            id="confirmPassword"
                            label="Confirm New Password"
                            name="confirmPassword"
                            type="password"
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            value={values.confirmPassword}
                            onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                        />

                        <div>
                            <Button variant="outlined" color="primary" type="submit">Change Password</Button>
                        </div>
                    </Stack>
                </Form>
        }
    </Formik>
}