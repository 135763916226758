import React from 'react'


export const useScraperLiveMonitor = (userId, onEvent) => React.useEffect(() => {
    if (userId && onEvent) {
        const worker = !window.SharedWorker ? undefined : new SharedWorker(new URL(`${process.env.REACT_APP_URL}/shared-worker.js?wsUrl=${encodeURIComponent(process.env.REACT_APP_WS_URL)}&userId=${userId}`))

        if (worker) {
            worker.port.onmessage = event => {
                onEvent(event.data)
            }
        }

        return () => {
            if (worker) {
                worker.port.close()
            }
        }
    }
}, [userId, onEvent])
