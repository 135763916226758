import React from 'react'
import { PlanStatus } from '@datagrab/datagrab-common/constants'
import { maxConcurrencyForCredits } from "@datagrab/datagrab-common/products"
import makeStyles from '@mui/styles/makeStyles'
import { RunRestrictionAlert } from "../RunRestrictionAlert"
import { ExtensionMissingAlert } from "../../common/ExtensionMissingAlert"
import { ScraperManager } from './ScraperManager'
import { ChangeScraperSettingsDialog } from './ChangeScraperSettingsDialog'
import { ConnectOutputGoogleSheetDialog } from './sheets/ConnectOutputGoogleSheetDialog'
import { OutputGoogleSheetConnector } from './sheets/OutputGoogleSheetConnector'
import { RunsPanel } from './RunsPanel'
import { Box, Button, Stack, Typography } from '@mui/material'
import { InputUrlsSource } from './InputUrlsSource'
import { ChangeInputUrlsSourceDialog } from './ChangeInputUrlsSourceDialog'


const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(9),
        }
    },
    paddingReset: {
        [theme.breakpoints.down('md')]: {
            marginLeft: -theme.spacing(4),
            marginRight: -theme.spacing(4)
        }
    },
    progressSpinner: {
        color: 'inherit',
        marginRight: theme.spacing(2)
    },
    em: {
        fontWeight: 'bold',
        color: theme.palette.grey[800]
    },
    errorText: {
        color: theme.palette.error.light
    },
    muted: {
        color: theme.palette.grey[600],
        fontSize: theme.fontSize[1]
    }
}))

const calculateMaxConcurrencyForPlan = ({ subscription, bulkCredits }) => {
    if (subscription) return subscription.concurrency
    return maxConcurrencyForCredits(bulkCredits)
}

/**
 * Displays the details of a scraper. It allows editing its general settings. It also displays its run history and statistics.
 *
 * @author Robert Balazsi
 */
const ScraperDetailsPage = ({
    extensionMissing, subscription, bulkCredits, scraper, connectedAccounts,
    parallelLimitReached, maxParallel, starting, onStart, stopping, onStop, onOpenWithExtension,
    onExportConfig, onSaveSettings, settingsSubmitting, onSelectRun = f => f
}) => {
    const classes = useStyles()
    const suspendedSubscription = subscription && subscription.status === PlanStatus.SUSPENDED
    const noCredits = (!subscription || subscription.currentUsage >= subscription.monthlyCredits) && !bulkCredits
    const startDisabled = noCredits || suspendedSubscription || parallelLimitReached
    const [changeSettingsToggled, setChangeSettingsToggled] = React.useState(false)
    const [changeInputUrlsToggled, setChangeInputUrlsToggled] = React.useState(false)
    const [connectGoogleSheetToggled, setConnectGoogleSheetToggled] = React.useState(false)

    const maxConcurrency = calculateMaxConcurrencyForPlan({ subscription, bulkCredits })

    return <div className={classes.wrapper}>
        <RunRestrictionAlert suspendedSubscription={suspendedSubscription}
            noCredits={noCredits}
            maxParallel={maxParallel}
            parallelLimitReached={parallelLimitReached} style={{ marginBottom: 32 }} />

        {extensionMissing && <ExtensionMissingAlert style={{ marginBottom: 32 }} />}

        {scraper.cloudEnabled ? <Stack mt={5} spacing={8}>
            <ScraperManager scraper={scraper}
                startDisabled={startDisabled}
                extensionMissing={extensionMissing}
                onStart={onStart}
                starting={starting}
                onStop={onStop}
                stopping={stopping}
                onOpenWithExtension={onOpenWithExtension}
                onChangeSettings={() => setChangeSettingsToggled(true)}
                onExportConfig={onExportConfig} />

            <Stack spacing={8} mt={4} direction={{ xs: 'column', md: 'row' }} alignItems="flex-start">
                <InputUrlsSource source={scraper.inputUrls} onUpdate={() => setChangeInputUrlsToggled(true)} />

                <OutputGoogleSheetConnector connectedSheet={scraper.output}
                    onConnect={() => setConnectGoogleSheetToggled(true)}
                    onUnlink={() => onSaveSettings({ id: scraper.id, data: { output: null } })} />
            </Stack>

            <Box mt={8}>
                <RunsPanel scraper={scraper} selectedRunID={scraper.selectedRunID} onSelectRun={onSelectRun} />
            </Box>
        </Stack> : <Stack spacing={5} sx={{maxWidth: 300}}>
            <Typography>This scraper is not cloud-enabled.</Typography>
            <Button sx={{maxWidth: 200}} variant="contained" color="primary" onClick={() => onSaveSettings({ id: scraper.id, data: { cloudEnabled: true } })}>Enable Cloud Runs</Button>
        </Stack>}

        {changeSettingsToggled && <ChangeScraperSettingsDialog scraper={scraper}
            maxConcurrency={maxConcurrency}
            onClose={() => setChangeSettingsToggled(false)}
            onSubmit={(details) => onSaveSettings({ id: scraper.id, data: details })}
            submitting={settingsSubmitting} />}

        {changeInputUrlsToggled && <ChangeInputUrlsSourceDialog connectedAccounts={connectedAccounts}
            source={scraper.inputUrls}
            onClose={() => setChangeInputUrlsToggled(false)}
            onSubmit={(details) => onSaveSettings({ id: scraper.id, data: { inputUrls: details } })}
            submitting={settingsSubmitting} />}

        {connectGoogleSheetToggled && <ConnectOutputGoogleSheetDialog connectedAccounts={connectedAccounts}
            scraper={scraper}
            onClose={() => setConnectGoogleSheetToggled(false)}
            onSubmit={(details) => onSaveSettings({ id: scraper.id, data: { output: details } })}
            submitting={settingsSubmitting} />}
    </div>
}

ScraperDetailsPage.displayName = 'ScraperDetailsPage'

export default ScraperDetailsPage