import {call, put, take, fork} from 'redux-saga/effects'
import {createApiRequestWatcher} from "../saga-helpers"
import {API_URL} from "../constants"
import {sendMessageToExtension, withAuthToken} from "../utils"
import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_NOTIFY,
    CLOSE_ACCOUNT,
    FETCH_USER_PROFILE,
    UPDATE_USER_PROFILE
} from "./actions"
import {
    CHANGE_PASSWORD_SUCCESS_MSG,
    UPDATE_USER_PROFILE_FAILURE_MSG,
    UPDATE_USER_PROFILE_SUCCESS_MSG
} from "./constants"
import {logout} from "../auth/actions"
import firebase from "../auth/firebase"
import { DATAGRAB_SYNC_USER_PROFILE } from '../auth/constants'


const updateUserProfileFlow = createApiRequestWatcher({
    actionPrefix: UPDATE_USER_PROFILE,

    fetcher: ({fullName, email, optedInForMarketing, connectedAccounts}) => withAuthToken().then(token => fetch(`${API_URL}/profile`, {
        method: 'PATCH',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify({fullName, email, optedInForMarketing, connectedAccounts}),
    }).then(resp => resp.json())
    .then((data) => {
        sendMessageToExtension({
            type: DATAGRAB_SYNC_USER_PROFILE,
            profile: {
                userId: data.result.userId, 
                bulkCredits: data.result.bulkCredits, 
                subscriptions: data.result.subscriptions,
                connectedAccounts: data.result.connectedAccounts
            }
        })

        return data
    })),

    meta: ({requestData}) => requestData,

    onSuccess: ({requestData}) => requestData.needsLogout ? logout() : null,

    notify: {
        success: UPDATE_USER_PROFILE_SUCCESS_MSG,
        failure: ({code}) => code === 409
            ? 'The email address you provided is already taken.'
            : code === 400 ? 'Invalid email address.' : UPDATE_USER_PROFILE_FAILURE_MSG
    }
})

const changePasswordFlow = function* () {
    while (true) {
        const request = yield take(CHANGE_PASSWORD_REQUEST)

        try {
            const cred = firebase.auth.EmailAuthProvider.credential(
                firebase.auth().currentUser.email, request.data.oldPassword)
            yield call(() => firebase.auth().currentUser.reauthenticateWithCredential(cred)
                .then(() => firebase.auth().currentUser.updatePassword(request.data.newPassword)))

            yield put({type: CHANGE_PASSWORD_NOTIFY, notify: {status: 'success', message: CHANGE_PASSWORD_SUCCESS_MSG}})
        } catch (e) {
            yield put({type: CHANGE_PASSWORD_NOTIFY, notify: {status: 'failure', message: e.message}})
        }
    }
}

const fetchUserProfileFlow = createApiRequestWatcher({
    actionPrefix: FETCH_USER_PROFILE,
    fetcher: () => withAuthToken().then(token => fetch(`${API_URL}/account/me`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', token}
    }).then(resp => resp.json())),
})

const closeAccountFlow = createApiRequestWatcher({
    actionPrefix: CLOSE_ACCOUNT,

    fetcher: (data) => withAuthToken().then(token => fetch(`${API_URL}/account`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify(data)
    }).then(resp => resp.json())),

    onSuccess: () => logout(),

    notify: {
        failure: ({code, error}) => code === 403 ? 'The password is incorrect.' : error
    }
})


export function* userProfileFlows() {
    yield fork(updateUserProfileFlow)
    yield fork(changePasswordFlow)
    yield fork(fetchUserProfileFlow)
    yield fork(closeAccountFlow)
}