import React from "react"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import CircularProgress from "@mui/material/CircularProgress"
import DoneIcon from "@mui/icons-material/Done"
import StopIcon from "@mui/icons-material/Stop"
import ErrorIcon from "@mui/icons-material/Error"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { SCRAPER_STATUS_DISPLAY_NAMES } from "./constants"
import Chip from "@mui/material/Chip"
import {
    ScraperStatus,
    ScraperRunStatus,
} from "@datagrab/datagrab-common/constants"
import { Stats } from "./details/Stats"
import { Box } from "@mui/material"


const SCRAPER_STATUS_ICONS = {
    [ScraperStatus.IDLE]: <HourglassEmptyIcon />,
    [ScraperStatus.STARTING]: <CircularProgress size={18} />,
    [ScraperStatus.RUNNING]: <CircularProgress size={18} />,
    [ScraperRunStatus.COMPLETED]: <DoneIcon />,
    [ScraperStatus.STOPPING]: <StopIcon />,
    [ScraperRunStatus.STOPPED]: <StopIcon />,
    [ScraperRunStatus.FAILED]: <ErrorIcon />,
    unknown: <HelpOutlineIcon />,
}

const SCRAPER_STATUS_COLORS = {
    [ScraperStatus.IDLE]: "default",
    [ScraperStatus.STARTING]: "default",
    [ScraperStatus.RUNNING]: "success",
    [ScraperRunStatus.COMPLETED]: "success",
    [ScraperStatus.STOPPING]: "success",
    [ScraperRunStatus.STOPPED]: "default",
    [ScraperRunStatus.FAILED]: "error",
    unknown: "default",
}

/**
 * A chip displayed to highlight the different statuses of scrapers.
 */
export const StatusChip = ({
    status = "UNKNOWN",
    pagesScraped = null,
    pagesFailed = null,
    emptyPages = null,
    ...props
}) => (
    <Chip
        color={SCRAPER_STATUS_COLORS[status]}
        icon={SCRAPER_STATUS_ICONS[status]}
        label={
            <Box display="flex" alignItems="center">
                {SCRAPER_STATUS_DISPLAY_NAMES[status]}
                <Stats
                    pagesScraped={pagesScraped}
                    pagesFailed={pagesFailed}
                    emptyPages={emptyPages}
                />
            </Box>
        }
        {...props}
    />
)
