import {
    DELETE_SCRAPER_SUCCESS,
    FETCH_ALL_SCRAPERS_SUCCESS, FETCH_SCRAPER_SUCCESS, SELECT_SCRAPER_RUN,
    UPDATE_SCRAPER_DETAILS_SUCCESS,
    START_SCRAPER_SUCCESS,
    STOP_SCRAPER_SUCCESS
} from "./actions"
import { upsertInArray } from "../utils"
import { RECENTLY_FINISHED_RUN_THRESHOLD_SECONDS } from "./constants"
import { differenceInSeconds } from "date-fns"
import { PROCESS_LIVE_EVENT } from "../live-events/actions"
import { LogMsg, ScraperStatus, ScraperRunStatus } from "@datagrab/datagrab-common/constants"

const mergeScraperData = (scrapers, id, data) => {
    const result = [...scrapers]
    const index = result.findIndex(s => s.id === id)
    const scraper = result[index]

    // If the template was patched, we only spread that part
    result[index] = {
        ...scraper,
        ...data,
        template: {
            ...scraper.template,
            ...data.template
        }
    }

    return result
}

const finishCurrentRun = (scraper, action, runStatus) => {
    const currentRun = {
        ...scraper.currentRun,
        finished: action.ts,
        status: runStatus,
        pagesScraped: action.session.pagesScraped,
        pagesFailed: action.session.pagesFailed,
        // emptyPages: action.event.pageStats.empty,
    }

    return {
        ...scraper,
        currentRun: null,
        status: runStatus,
        lastFinished: action.ts,
        runHistory: [currentRun, ...scraper.runHistory]
    }
}

const mergeScraperStateWithLiveEvent = (scrapers, event) => {
    const result = [...scrapers]
    const index = result.findIndex(s => s.id === event.session.scraperId)
    const scraper = result[index]

    if (!scraper) {
        return scrapers
    }

    switch (event.event.type) {
        case LogMsg.SESSION_STARTED:
            result[index] = {
                ...result[index],
                status: ScraperRunStatus.RUNNING,
                lastStarted: event.ts,
                selectedRunID: event.session.runId,
                currentRun: {
                    runId: event.session.runId,
                    started: event.ts,
                    status: ScraperRunStatus.RUNNING,
                    pagesScraped: 0,
                    pagesFailed: 0,
                    emptyPages: 0
                }
            }
            break
        case LogMsg.PAGE_SCRAPED:
            result[index] = {
                ...result[index],
                currentRun: {
                    ...result[index].currentRun,
                    pagesScraped: event.session.pagesScraped
                }
            }
            break

        case LogMsg.PAGE_FAILED:
            result[index] = {
                ...result[index],
                currentRun: {
                    ...result[index].currentRun,
                    pagesFailed: event.session.pagesFailed
                }
            }
            break

        case LogMsg.SESSION_COMPLETED:
            result[index] = finishCurrentRun(result[index], event, ScraperRunStatus.COMPLETED)
            break

        case LogMsg.SESSION_STOPPED:
            result[index] = finishCurrentRun(result[index], event, ScraperRunStatus.STOPPED)
            break

        case LogMsg.SESSION_FAILED:
            result[index] = finishCurrentRun(result[index], event, ScraperRunStatus.FAILED)
            break

        default:
            return result
    }

    return result
}

const normalizeId = (obj) => ({
    ...obj,
    id: obj._id,
    _id: undefined
})

const isIdleWithoutRuns = (scraper) =>
    !scraper.currentRun &&
    (!scraper.runHistory || scraper.runHistory.length === 0)

const getDefaultRun = (scraper) =>
    isIdleWithoutRuns(scraper)
        ? null
        : scraper.currentRun
            ? scraper.currentRun.runId
            : scraper.runHistory[0].runId

/**
 * For recently finished scrapers, we set their status to the last run's status for a short period of time for better
 * user experience.
 */
const normalizeScraper = (scraper) => ({
    ...scraper,
    ...normalizeId(scraper),
    status: (scraper.runHistory && scraper.runHistory.length > 0 && differenceInSeconds(new Date(), new Date(scraper.lastFinished)) < RECENTLY_FINISHED_RUN_THRESHOLD_SECONDS)
        ? scraper.runHistory[0].status
        : scraper.status,
    runHistory: scraper.runHistory.map(run => normalizeId(run)),
    selectedRunID: getDefaultRun(scraper)
})

const scrapers = (state = [], action = null) => {
    switch (action.type) {
        case FETCH_ALL_SCRAPERS_SUCCESS:
            // NOTE: Filter out the ones that are not cloud-enabled
            return action.result.filter(scraper => scraper.cloudEnabled).map(scraper => normalizeScraper(scraper))

        case FETCH_SCRAPER_SUCCESS:
            return upsertInArray(state, normalizeScraper(action.result))

        case UPDATE_SCRAPER_DETAILS_SUCCESS:
            return mergeScraperData(state, action.meta.scraperID, action.meta.details)

        case START_SCRAPER_SUCCESS:
            return mergeScraperData(state, action.meta.scraperID, {
                status: ScraperStatus.STARTING
            })

        case STOP_SCRAPER_SUCCESS:
            return mergeScraperData(state, action.meta.scraperID, {
                status: ScraperStatus.STOPPING
            })

        case DELETE_SCRAPER_SUCCESS:
            return state.filter(scraper => !action.meta.ids.includes(scraper.id))

        case PROCESS_LIVE_EVENT:
            return mergeScraperStateWithLiveEvent(state, action.event)

        case SELECT_SCRAPER_RUN:
            return mergeScraperData(state, action.scraperID, { selectedRunID: action.runID })

        default:
            return state
    }
}

export default scrapers