import React from 'react'
import { AdminPage } from "./AdminPage"
import { Box, Card, CardContent, Stack } from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from "react-redux"
import { apiRequestStateSelector } from "../api/selectors"
import { FETCH_SYSTEM_STATS, fetchSystemStats } from "./actions"
import { LoadingContainer } from "../common/LoadingContainer"


const useStyles = makeStyles((theme) => ({
    statsContainer: {
        minWidth: theme.spacing(12),
        maxWidth: theme.spacing(15),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))

const reqSelector = apiRequestStateSelector([FETCH_SYSTEM_STATS])

/**
 * Displays global statistics about the system: number of users created, scrapers created, scraping runs and pages scraped.
 *
 * @author Robert Balazsi
 */
export const GlobalStatsPageContainer = () => {
    const classes = useStyles()

    const [loading,] = useSelector(state => reqSelector(state))
    const stats = useSelector(state => state.admin.stats)
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchSystemStats())
    }, [dispatch])


    return <AdminPage activeTab="stats" breadcrumbs={[{ label: 'Stats', link: '/admin/stats' }]}>
        <Card className={classes.statsContainer}>
            <CardContent>
            <LoadingContainer loading={loading}>
                <Stack spacing={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel>Users</InputLabel>
                        <span>{stats.totalUsers} ({stats.activeUsers} active, {stats.pendingUsers} pending)</span>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel>Scrapers</InputLabel>
                        <span>{stats.totalScrapers} total, {stats.runningScrapers} running</span>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel>Runs</InputLabel>
                        <span>{stats.totalRuns}</span>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel>Pages Scraped</InputLabel>
                        <span>{stats.totalPagesScraped}</span>
                    </Box>
                </Stack>
            </LoadingContainer>
            </CardContent>
        </Card>
    </AdminPage>
}