import React from 'react'
import { PaginationMethod } from "@datagrab/datagrab-common/constants"
import Box from "@mui/material/Box"
import { styled } from '@mui/material/styles'
import TableChartIcon from '@mui/icons-material/TableChart'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PanToolIcon from '@mui/icons-material/PanToolAlt'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'


const Icon = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    color: theme.palette.grey[500],
    marginRight: 4
}))

const PaginationInfo = ({ pagination }) => {
    let icon
    let message = 'Unknown pagination'

    switch (pagination.method) {
        case PaginationMethod.NEXT_LINKS:
            icon = <ContentCopyIcon fontSize="small" />
            message = 'Paginated'
            break
        case PaginationMethod.LOAD_MORE:
            icon = <PanToolIcon fontSize="small" />
            message = '"Load more" button'
            break
        case PaginationMethod.SCROLL:
            icon = <KeyboardDoubleArrowDownIcon fontSize="small" />
            message = 'Infinite scrolling'
            break
        default:
            icon = <ContentCopyIcon fontSize="small" />
    }

    return <Box display="flex" alignItems="center"><Icon>{icon}</Icon>{message}</Box>
}

const hasPagination = (p) => !!p && p.method !== PaginationMethod.NONE


/**
 * Displays information about a scraper's fields and pagination configuration.
 * @param template the template object
 * @param others other properties
 */
export const ConfigInfo = ({ template, ...others }) => <div {...others}>
    <Box display="flex" alignItems="center"><Icon><TableChartIcon fontSize="small" /></Icon>{template.table.columns.length} {template.table.columns.length === 1 ? 'field' : 'fields'}
    </Box>

    {hasPagination(template.pagination) && <Box mt={2}>
        <PaginationInfo pagination={template.pagination} />
    </Box>}
</div>