import React from 'react'
import { useDispatch } from 'react-redux'
import { API_URL } from '../../constants'
import { useAuth } from '../../hooks'
import { ACTION_EXPORT_DATA } from '../../tracking/constants'
import { trackAction } from '../../tracking/utils'
import { withAuthToken } from '../../utils'
import { exportRunData } from '../actions'
import { DataPanel } from './DataPanel'


const MAX_ROWS = 500


export const DataPanelContainer = ({ scraper, runID }) => {
    const [data, setData] = React.useState(null)
    const [fetching, setFetching] = React.useState(false)
    const dispatch = useDispatch()
    const { email } = useAuth()

    React.useEffect(() => {
        setFetching(true)

        withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraper.id}/runs/${runID}/export?format=json&row_limit=${MAX_ROWS}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', token }
        }).then(res => res.json())
            .then(data => {
                setData(data)
                setFetching(false)
            })
            .catch(err => {
                console.error(err)
                setFetching(false)
            }))
    }, [scraper.id, runID])

    const handleExportRunData = React.useCallback((format) => {
        if (scraper) {
            trackAction(email, ACTION_EXPORT_DATA, { scraper: scraper.name, runID, format })
            dispatch(exportRunData({ scraperID: scraper.id, scraperName: scraper.name, runID, format }))
        }
    }, [dispatch, email, scraper, runID])

    return <DataPanel fetching={fetching} data={data} onExport={handleExportRunData} maxRows={MAX_ROWS} />
}