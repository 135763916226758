import React from 'react'
import {sendMessageToExtension, withAuthToken} from "./utils"
import {useSelector} from "react-redux"
import {API_URL} from "./constants"


/**
 * Hook for detecting clicks outside of an element, given by its ref.
 * @param ref the React ref of the element
 * @param callback the callback to be invoked when clicking outside the element
 */
export const useClickAwayListener = (ref, callback) => {
    React.useEffect( () => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, callback])
}

/**
 * Returns the auth Redux state.
 */
export const useAuth = () => useSelector(state => state.firebase.auth)

/**
 * Sends a message to the Chrome extension to query its version. Used mostly for checking whether the extension is installed.
 */
export const useExtensionVersion = () => {
    const [version, setVersion] = React.useState(null)
    const {email} = useAuth()

    React.useEffect( () => {
        sendMessageToExtension({type: 'get_version'}, (resp) => {
            setVersion(resp.version)

            /* Check if the user is logged into the extension and with the same email. If not, fetch a custom token,
               and send a message to sync the authentication state. */
            if (!resp.email || resp.email !== email) {
                withAuthToken().then(token => fetch(`${API_URL}/account/customToken`, {
                    headers: {token}
                })).then(res => res.json())
                    .then(data => sendMessageToExtension({
                        type: 'sync_auth',
                        token: data.result,
                        email,
                    }))
                    .catch(e => console.error('Error getting custom token. ', e))
            }
        })
    }, [email])

    return version
}