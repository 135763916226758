// import './resources/css/styles.min.css'
import './resources/css/styles.css'


import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import reduxReset from 'redux-reset'
import {createStore, applyMiddleware, compose} from 'redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers'
import {createBrowserHistory} from 'history'
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import rootSaga from "./root-saga";
import {APP_PERSIST_KEY} from "./constants";
import {LOGOUT} from "./auth/actions";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./theme";
import {App} from "./site/App";
import {CssBaseline} from "@mui/material"
import reportWebVitals from "./reportWebVitals"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import firebase from "./auth/firebase"

// The Redux Devtools extension (Chrome and Firefox), if available
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();

const persisterConfig = {
    key: APP_PERSIST_KEY,
    storage,
    blacklist: ['app', 'notify']
};

const sagaMiddleware = createSagaMiddleware();

const appReducer = persistReducer(persisterConfig, createRootReducer(history));

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        return undefined;
    }

    return appReducer(state, action);
};

const middlewares = [
    routerMiddleware(history),
    sagaMiddleware
];

// Add the logger middleware only in development mode
if (process.env.NODE_ENV === 'development') {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
}

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            ...middlewares
        ),
        reduxReset(),
    )
)

// react-redux-firebase config
const rrfConfig = {
    // userProfile: 'users',
    // useFirestoreForProfile: true
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
}

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

/**
 * The root component.
 */
const Root = () => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <ConnectedRouter history={history}>
                        <App />
                    </ConnectedRouter>
                </ThemeProvider>
            </PersistGate>
        </ReactReduxFirebaseProvider>
    </Provider>
);


render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()