import { Alert } from '@mui/material'
import React from 'react'


/**
 * Renders an optional alert in case the user's subscription is suspended because of payment failure, she has no more bulk
 * credits, or the parallel limit has been reached.
 */
export const RunRestrictionAlert = ({suspendedSubscription, noCredits, parallelLimitReached, maxParallel, ...others}) => {
    if (suspendedSubscription) {
        return <Alert variant="outlined" severity="error" {...others}>
            Your subscription was suspended because a previous payment has failed. Please go to your Billing settings and update your payment method.
        </Alert>
    } else if (noCredits) {
        return <Alert variant="outlined" severity="error" {...others}>
            You have no more cloud credits left. Please upgrade your subscription or purchase more credits.
        </Alert>
    } else if (parallelLimitReached) {
        return <Alert variant="outlined" severity="warning" {...others}>
            You reached the limit of <strong>{maxParallel} scrapers</strong> running in parallel.
        </Alert>
    }

    return null
}