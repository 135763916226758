/**
 * Exports a scraper.
 * @param scraper the scraper
 */
export const exportScraper = (scraper) => ({
    id: scraper.id,
    name: scraper.name,
    type: scraper.type,
    inputUrls: scraper.inputUrls,
    maxRequests: scraper.maxRequests,
    requestInterval: scraper.requestInterval,
    cloudEnabled: scraper.cloudEnabled,
    concurrency: scraper.concurrency,
    rendering: scraper.rendering,
    output: scraper.output,
    template: scraper.template
})
