import {API_URL} from "../constants";
import {jsonWithAuth} from "../utils";

const BLACKLIST = !process.env.REACT_APP_TRACKING_BLACKLIST ? [] : process.env.REACT_APP_TRACKING_BLACKLIST.split(',');

/**
 * Tracks an action, reporting it to the Backend.
 * NOTE: Only done if the tracking level is set to detailed.
 */
export const trackAction = (email, type, data) => (process.env.REACT_APP_TRACKING_LEVEL === 'detailed' && !BLACKLIST.includes(email))
    ? fetch(`${API_URL}/activity`, {
        method: 'POST',
        headers: jsonWithAuth(),
        body: JSON.stringify({
            type,
            data
        })
    }) : null; // NOTE: We run this async and do not wait for the result to avoid delays.
