import React from 'react'
import LoadingButton from "@mui/lab/LoadingButton"
import {styled} from "@mui/material/styles"
import GoogleLogo from '../resources/icons/google_logo.svg'


const StyledButton = styled(LoadingButton)( ({theme}) => ({
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: 40,
    lineHeight: 1,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: 'white',
    '& .MuiButton-startIcon': {
        marginRight: 24
    },

    '&:hover, &:focus': {
        backgroundColor: 'hsl(0, 0%, 93%)'
    }
}))


/**
 * Google social button for sign-ins or sign-ups.
 */
export const GoogleSocialButton = ({children, ...others}) => <StyledButton startIcon={<img src={GoogleLogo} alt="google logo" />} {...others}>{children}</StyledButton>
