import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material"
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { deepPurple } from '@mui/material/colors'


const getInitials = (name) => !name ? 'U' : name.split(/\s+/).map(n => n[0]).join('').substring(0, 2)

/**
 * Displays a dropdown menu with the user's avatar and name, and it allows opening the settings page or logging out.
 */
export const UserProfileMenu = ({
    user,
    onSignOut = (f) => f
}) => {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return <React.Fragment>
        <Tooltip title="Account settings">
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                {user.photoURL
                    ? <Avatar sx={{ width: 40, height: 40 }} imgProps={{referrerPolicy: "no-referrer"}} src={user.photoURL} alt="avatar" />
                    : <Avatar sx={{ width: 40, height: 40, bgcolor: deepPurple[500] }}>{getInitials(user.displayName)}</Avatar>}
            </IconButton>
        </Tooltip>

        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 2,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -1,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem component={RouterLink} to="/settings">
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem onClick={onSignOut}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    </React.Fragment>
}
