import React from 'react';
import Rocket from "../resources/icons/rocket.svg";
import Button from "@mui/material/Button"
import {Link} from "react-router-dom";
import {EmptyStatePanel} from "../common/EmptyStatePanel";
import {UserPage} from "./UserPage";


/**
 * A "Thank You" page displayed after the user upgrades his plan.
 */
export const ThankYouPage = () =>
    <UserPage>
        <EmptyStatePanel icon={<img src={Rocket} alt="billing"/>}
                         title="Thank you for subscribing!"
                         content={<span>You successfully subscribed and now have unlimited access to set up and run cloud scrapers. If you have any questions, contact us at <strong>support@datagrab.io</strong>.</span>}
                         actions={<Button type="button" component={Link} color="primary" variant="contained"
                                          to="/settings/billing">Back to Billing</Button>}/>

    </UserPage>;