import React from 'react'
import { BaseSettingsPage } from "./BaseSettingsPage"
import { UserProfileForm } from "./UserProfileForm"
import { ChangePasswordForm } from "./ChangePasswordForm"
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch } from "react-redux"
import { fetchUserProfile, updateUserProfile } from "./actions"
import { Card, CardContent, CardHeader, Container, Stack } from '@mui/material'
import { ConnectedAccountsPanel } from './ConnectedAccountsPanel'
import { useCurrentProfile } from '../profile/hooks'
import { redirectToGoogleAuth } from '../integrations/actions'


/*const deletionSchema = Yup.object({
    reason: Yup.string().test('is-set', 'Please give us a reason.', val => val !== '-'),
    password: Yup.string().required('Password is required.')
})*/

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 400,
        width: '100%'
    },
    supportText: {
        color: theme.palette.grey[600],
        fontSize: theme.fontSize[2],
        marginTop: 0,
        marginBottom: theme.spacing(4)
    }
}))

// const deleteReqSelector = apiRequestStateSelector([CLOSE_ACCOUNT])

/**
 * Manages the user's account details.
 */
export const AccountSettingsPage = () => {
    const classes = useStyles()
    // const [deleteAccountRequested, setDeleteAccountRequested] = React.useState(false)
    // const [submitting,] = useSelector(state => deleteReqSelector(state))
    const { connectedAccounts, provider } = useCurrentProfile()
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchUserProfile())
    }, [dispatch])

    const handleRemoveConnectedAccount = React.useCallback(({ email, provider }) => {
        dispatch(updateUserProfile({
            connectedAccounts: connectedAccounts.filter(ca => ca.provider !== provider || ca.email !== email)
        }))
    }, [dispatch, connectedAccounts])

    /*const handleKeyDown = React.useCallback((e) => {
        if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault()
        }
    }, [])

    const handleDeleteAccount = React.useCallback((values) => {
        dispatch(closeAccount({
            reason: values.reason.toUpperCase(),
            feedback: values.feedback,
            password: values.password
        }))

        setDeleteAccountRequested(false)
    }, [dispatch])*/

    return <BaseSettingsPage activeTab="/settings/account">
        <Container maxWidth="sm">
            <Stack spacing={7}>
                <Card className={classes.card}>
                    <CardHeader title="Profile" />
                    <CardContent>
                        <UserProfileForm />
                    </CardContent>
                </Card>

                <Card className={classes.card}>
                    <CardHeader title="Connected Accounts" />
                    <CardContent>
                        <ConnectedAccountsPanel accounts={connectedAccounts}
                            onConnect={redirectToGoogleAuth}
                            onRemove={handleRemoveConnectedAccount} />
                    </CardContent>
                </Card>

                {provider === 'email' && <Card className={classes.card}>
                    <CardHeader title="Password" />
                    <CardContent>
                        <ChangePasswordForm />
                    </CardContent>
                </Card>}
            </Stack>
        </Container>

        {/*<Card className={classes.card} title="Delete Account">
                <p className={classes.supportText}>All data will be removed and the account will
                    not be available
                    anymore. Please be certain.</p>

                <Button color="danger" variant="text" onClick={() => setDeleteAccountRequested(true)}>Delete
                    Account</Button>
            </Card>*/}

        {/*{deleteAccountRequested && <Formik validationSchema={deletionSchema}
                                           validateOnChange={false}
                                           validateOnBlur={false}
                                           initialValues={{
                                               reason: '-',
                                               feedback: '',
                                               password: '',
                                           }}
                                           onSubmit={handleDeleteAccount}>
            {({values, errors, setFieldValue, handleSubmit}) => <ModalDialog title="Delete Account"
                                                                             form
                                                                             formProps={{
                                                                                 onKeyDown: handleKeyDown,
                                                                                 onSubmit: handleSubmit
                                                                             }}
                                                                             buttons={[
                                                                                 <Button key="cancel_button"
                                                                                         variant="text" color="primary"
                                                                                         onClick={() => setDeleteAccountRequested(false)}>Nevermind</Button>,
                                                                                 <LoaderButton key="submit_button"
                                                                                               type="submit"
                                                                                               color="error"
                                                                                               variant="contained"
                                                                                               label="Delete Account"
                                                                                               loading={submitting}
                                                                                               loadingLabel="Submitting"/>
                                                                             ]}>
                <Grid column fullWidth spacing={6}>
                    <p>This will wipe out all of your data and make your account permanently unavailable. There is no
                        going back.
                        Are you sure you want to delete your account?</p>

                    <Select id="reason-select"
                            fullWidth
                            labelId="reason-select-label"
                            label="Feedback"
                            value={values.reason}
                            error={errors.reason}
                            onChange={(e) => setFieldValue('reason', e.target.value)}>
                        {CANCELLATION_REASON_OPTS.map(opt => <MenuItem key={opt.value}
                                                                       value={opt.value}>{opt.label}</MenuItem>)}
                    </Select>

                    <TextField
                        fullWidth
                        label="What should we improve?"
                        multiline
                        rows="5"
                        optional
                        value={values.feedback}
                        onChange={(e) => setFieldValue('feedback', e.target.value)}
                    />

                    <TextField
                        fullWidth
                        label="Password Confirmation"
                        type="password"
                        value={values.password}
                        error={errors.password}
                        helperText="This is required to prevent unauthorized account deletion."
                        onChange={(e) => setFieldValue('password', e.target.value)}
                    />
                </Grid>
            </ModalDialog>}

        </Formik>}*/}
    </BaseSettingsPage >
}