import React from 'react'
import {styled} from '@mui/material/styles'
import { LogRenderInfo } from '../constants'


const renderTs = (log) => `[${new Date(log.ts).toLocaleString()}]`

const Msg = styled('div')( ({theme}) => ({
    display: 'flex',
    marginTop: 0,
    marginBottom: '.5rem',
    '&.warning': {
        color: theme.palette.warning.main
    },
    '&.error': {
        color: theme.palette.error.main
    },
    '& .link': {
        color: 'inherit',
        textDecoration: 'underline'
    }
}))

export const LogEntry = ({ log, className = '', ...others }) => <Msg className={`${className} ${LogRenderInfo[log.msg].color(log)}`} {...others}>
    <span style={{display: 'inline-block', marginRight: 16, whiteSpace: 'nowrap'}}>{renderTs(log)}</span>
    <span>{LogRenderInfo[log.msg].html(log)}</span>
</Msg>