import {connectRouter} from 'connected-react-router'
import {firebaseReducer} from "react-redux-firebase"
import {combineReducers} from 'redux'
import scrapers from "./scrapers/reducers"
import notify from "./notify/reducers"
import account from "./account/reducers"
import app from "./site/reducers"
import api from "./api/reducers"
import admin from "./admin/reducers"
import profile from "./profile/reducers"
import systemPrefs from "./systemPrefs/reducers"


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    admin,
    api,
    app,
    account,
    firebase: firebaseReducer,
    scrapers,
    notify,
    profile,
    systemPrefs
})

export default createRootReducer