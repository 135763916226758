import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import withStyles from '@mui/styles/withStyles';
import {Form} from "formik"
import CloseIcon from '@mui/icons-material/Close'
import IconButton from "@mui/material/IconButton"
import { Stack } from '@mui/material'


const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: 'white',
        minWidth: 300,
        '& > h2': {
            fontSize: theme.fontSize[5],
            fontWeight: 'normal',
            color: theme.palette.grey[800]
        }
    },
    dialogContent: {
        backgroundColor: 'white',
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(5)} ${theme.spacing(6)}`,
        }
    },
}))

const Footer = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(4)
    }
}))(DialogActions)

/**
 * A modal dialog that optionally wraps all its content inside a form.
 *
 * @author Robert Balazsi
 */
export const ModalDialog = ({title, onClose, footer, closeButton, buttons, form, formProps, children, ...others}) => {
    const classes = useStyles()

    const content = <React.Fragment>
        <DialogTitle className={classes.dialogTitle} id="modal-dialog-title">
            {title}
            {closeButton &&
            <IconButton
                aria-label="close"
                sx={{position: 'absolute', top: 4, right: 4, color: (theme) => theme.palette.grey[500]}}
                onClick={onClose}
                size="large">
                <CloseIcon/>
            </IconButton>}
        </DialogTitle>
        <DialogContent className={classes.dialogContent} id="modal-dialog-description">
            {children}
        </DialogContent>
        <Footer classes={classes.footer}>
            {!footer ? <Stack direction="row" spacing={4}>{buttons}</Stack> : footer}
        </Footer>
    </React.Fragment>

    return <Dialog {...others}
                   open
                   disableScrollLock
                   onClose={onClose}
                   aria-labelledby="modal-dialog-title"
                   aria-describedby="modal-dialog-description">
        {form ? <Form {...formProps}>{content}</Form> : content}
    </Dialog>
}