import React from 'react'
import { Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'


const Tab = styled('button')(({ theme }) => ({
    outline: 0,
    border: 0,
    background: 'transparent',
    color: theme.palette.grey[500],
    fontSize: theme.fontSize[3],
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(2),
    borderBottom: `3px solid transparent`,
    cursor: 'pointer',

    '&:hover, &:focus': {
        color: theme.palette.grey[800]
    },

    '&.active': {
        color: theme.palette.grey[800],
        borderBottom: `3px solid ${theme.palette.primary[500]}`
    }
}))

const Divider = styled('hr')(({ theme }) => ({
    margin: 0,
    border: 'none',
    height: 1,
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[200],
}))

/**
 * Displays module tabs.
 */
export const Tabs = ({ tabs, activeTab, onChange = f => f, className = '', ...others }) => {

    return <Box sx={{ width: '100%' }} {...others}>
        <Stack direction="row" spacing={6}>
            {tabs.map((tab, i) => <Tab className={tab.value === activeTab ? 'active' : ''} onClick={() => onChange(tab.value)} key={i}>{tab.label}</Tab>)}
        </Stack>
        <Divider />
    </Box>
}