import React from "react"
import { Box, Hidden, Drawer } from "@mui/material"
import { UserSideNav } from "./UserSideNav"
import { Breadcrumbs } from "../common/Breadcrumbs"
import { ToastNotifications } from "../notify/ToastNotifications"
import { UserMobileNav } from "./UserMobileNav"
import { FeedbackWidget } from "../feedback/FeedbackWidget"
import { UserProfileMenu } from "./UserProfileMenu"
import { useAuth } from "../hooks"
import { useDispatch } from "react-redux"
import { trackAction } from "../tracking/utils"
import { ACTION_LOGOUT } from "../tracking/constants"
import { logout } from "../auth/actions"
import { styled, useTheme } from "@mui/material/styles"


const DRAWER_WIDTH = 128

const Root = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    minHeight: "100vh",
}))

const Main = styled("main")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: 0,
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowY: "auto",

    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(5),
    },
    [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingTop: theme.spacing(6),
    },
}))

/**
 * The page skeleton displayed for users. It includes a side navigation (on tablet devices and above) or bottom navigation
 * (for phones). It also displays a breadcrumb on the page. This will be hidden on phones for secondary screens, in which
 * case a top navigation header will show with the title of the last breadcrumb item and a left arrow for going back.
 *
 * @author Robert Balazsi
 */
export const UserPage = ({ activeTab, breadcrumbs, children }) => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const theme = useTheme()

    const handleSignOut = React.useCallback(() => {
        trackAction(auth.email, ACTION_LOGOUT)
        dispatch(logout())
    }, [auth.email, dispatch])

    // Defer rendering until the auth state is loaded
    if (!auth.isLoaded) {
        return null
    }

    const user = { displayName: auth.displayName, photoURL: auth.photoURL }

    return (
        <Root>
            <ToastNotifications />

            <FeedbackWidget />

            <Hidden smDown>
                <Drawer
                    sx={{
                        width: DRAWER_WIDTH,
                        flexShrink: 0,
                        ".MuiDrawer-paper": {
                            width: DRAWER_WIDTH,
                            backgroundColor: theme.palette.primary.main,
                            border: 0,
                            overflow: "auto",
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                    open
                >
                    <UserSideNav activeTab={activeTab} />
                </Drawer>
            </Hidden>

            <Main>
                {breadcrumbs && breadcrumbs.length > 0
                    ? <Box display="flex" justifyContent="space-between" alignItems="center" mb={7}>
                        <Breadcrumbs items={breadcrumbs} />
                        <UserProfileMenu user={user} onSignOut={handleSignOut} />
                    </Box>
                    : <Box display="flex" justifyContent="flex-end" mb={7}>
                        <UserProfileMenu user={user} onSignOut={handleSignOut} />
                    </Box>}

                <Box display="flex" flexDirection="column" flexGrow={1}>{children}</Box>
            </Main>

            <Hidden smUp>
                <UserMobileNav activeTab={activeTab} />
            </Hidden>
        </Root>
    )
}
