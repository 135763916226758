import { Alert, Box, Button, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { LoadingContainer } from '../../common/LoadingContainer'
import {tabularize} from '@datagrab/datagrab-common/tabularizer'


export const DataPanel = ({ data, fetching, maxRows, onExport = f => f }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const columns = !data || data.length === 0 ? [] : Object.keys(data[0])
    const tabularData = !data || data.length === 0 ? [] : tabularize({columns, data})

    const handleMenu = React.useCallback((e) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const handleClose = React.useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleExport = React.useCallback((format) => {
        onExport(format)
        handleClose()
    }, [onExport, handleClose])

    return <LoadingContainer loading={fetching}>
        {!tabularData || tabularData.length === 0
            ? <Typography variant="body2">
                No data is available for this run. It might have been automatically cleaned up.
            </Typography>
            : <div>
                <Box display="flex" justifyContent="space-between" mb={5} flexWrap="wrap">
                    <Alert severity='info'>A maximum of {maxRows} rows are displayed. To get the full dataset, export it.</Alert>

                    <div>
                        <Button variant="outlined"
                            endIcon={<ArrowDropDownIcon />}
                            onClick={handleMenu}
                            aria-controls="export-data-menu"
                            aria-haspopup="true">Export Data</Button>

                        <Menu
                            id="export-data-menu"
                            anchorEl={anchorEl}
                            disableScrollLock
                            open={Boolean(anchorEl)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            onClose={handleClose}
                            MenuListProps={{
                                sx: { width: anchorEl && anchorEl.offsetWidth }
                            }}
                        >
                            <MenuItem onClick={() => handleExport('csv')}>CSV</MenuItem>
                            <MenuItem onClick={() => handleExport('json')}>JSON</MenuItem>
                        </Menu>
                    </div>
                </Box>

                <TableContainer mt={4}>
                    <Table size="small" aria-label="data">
                        <TableHead>
                            <TableRow>
                                {columns.map((col, i) => <TableCell key={i}>{col}</TableCell>)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tabularData.map((row, i) => <TableRow key={i}>
                                {Object.keys(row).map((col, j) => <TableCell key={j}>{row[col]}</TableCell>)}
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
    </LoadingContainer>
}