import {fork} from 'redux-saga/effects'
import {createApiRequestWatcher} from "../saga-helpers"
import {API_URL} from "../constants"
import {
    REQUEST_PASSWORD_RESET
} from "./actions"
import {EMAIL_SIGNUP} from "./actions"
import {push} from "connected-react-router"
import firebase from '../auth/firebase'


const emailSignupFlow = createApiRequestWatcher({
    actionPrefix: EMAIL_SIGNUP,

    fetcher: (details) => {
        const body = JSON.stringify({
            fullName: details.fullName,
            email: details.email,
            password: details.password,
            provider: 'email',
            optedInForMarketing: details.optedInForMarketing
        })

        return fetch(`${API_URL}/account/signup`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body
        })
            .then(resp => resp.json())
            .then(json => {
                // Log in the user if signed up successfully
                if (json.code === 201) {
                    firebase.auth().signInWithEmailAndPassword(details.email, details.password)
                        .then(res => res.user.sendEmailVerification())
                        .then(() => console.log('Verification email sent.'))
                        .catch(e => console.log('Error sending verification email.', e))
                }
                return json
            })

    },

    notify: {
        failure: ({code}) => code === 0 ? 'Failed to sign up. The server may be down for maintenance.'
            : code === 409
                ? 'The email address you provided is already taken.'
                : code === 400
                    ? 'Invalid email address.'
                    : 'Error occurred during sign up.'
    }
})

const requestPasswordResetFlow = createApiRequestWatcher({
    actionPrefix: REQUEST_PASSWORD_RESET,

    fetcher: (email) => firebase.auth().sendPasswordResetEmail(email)
        .then(() => ({code: 200}))
        .catch((e) => e.code
            ? {code: 400, error: e.message}
            : {code: 0, error: 'Network failure'}),

    onSuccess: () => push('/signin'),
    onFailure: () => push('/signin'),

    notify: {
        success: 'The email has been sent to reset your password',
        // NOTE: We hide errors regarding inexistent account to prevent email email probing
        failureAsSuccess: ({code}) => code === 400,
        failure: ({code}) => code === 0 ? 'Failed to send password reset link. The server may be down for maintenance.' : null
    }
})


export function* accountFlows() {
    yield fork(emailSignupFlow)
    yield fork(requestPasswordResetFlow)
}