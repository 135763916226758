import { API_URL } from "../constants"
import { withAuthToken } from "../utils"


/**
 * Redirects the customer to Google's OAuth2 
 */
export const redirectToGoogleAuth = () => withAuthToken().then(token => fetch(`${API_URL}/google-oauth`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', token }
})
    .then(resp => resp.json())
    .then((result) => {
        window.location.href = result.result
    }))

/**
 * Loads a Google spreadsheet by a given ID, returns info about it.
 */
export const loadSpreadsheet = ({ id, account, callback }) => {
    callback({ loading: true, data: [] })

    return withAuthToken().then(token => fetch(`${API_URL}/sheets/${id}?account=${encodeURIComponent(account)}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', token }
    })
        .then(resp => resp.json())
        .then(data => {
            if (data.code === 200) {
                callback({ data: data.result, loading: false })
            } else {
                callback({ data: [], error: data.error, loading: false })
            }
        }))
        .catch(e => callback({ data: [], error: e.message, loading: false }))
}

export const createSpreadsheet = ({ account, title, callback }) => {
    callback({ loading: true, data: [] })

    return withAuthToken().then(token => fetch(`${API_URL}/sheets`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', token },
        body: JSON.stringify({account, title})
    })
        .then(resp => resp.json())
        .then(data => {
            if (data.code === 201) {
                callback({ data: data.result, loading: false })
            } else {
                callback({ data: [], error: data.error, loading: false })
            }
        }))
        .catch(e => callback({ data: [], error: e.message, loading: false }))
}