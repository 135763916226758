import { Box, CircularProgress, FormHelperText, InputLabel } from "@mui/material"
import React from "react"
import { DebouncedTextField } from "../../../common/DebouncedTextField"
import SuccessIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { extractSpreadSheetId } from "@datagrab/datagrab-common/utils"
import { loadSpreadsheet } from "../../../integrations/actions"


const displaySpreadsheetLoadingState = (sheet) => !sheet
    ? null
    : <Box ml={2} display="flex">
        {sheet.error
            ? <ErrorIcon color="error" />
            : sheet.loading ? <CircularProgress size={18} /> : <SuccessIcon color="success" />}
    </Box>

export const GoogleSheetLoader = ({
    account,
    spreadsheet,
    loadedSpreadsheet,
    onChange = f => f,
    onLoad = f => f
}) => {
    const [error, setError] = React.useState(null)

    // Reload the spreadsheet to list its sheets
    React.useEffect(() => {

        if (account && spreadsheet) {
            const id = extractSpreadSheetId(spreadsheet)

            if (id) {
                loadSpreadsheet({
                    id, account, callback: (loaded) => {
                        onLoad(loaded)
                        setError(loaded.error || null)
                    }
                })
            }
        }

    }, [account, spreadsheet, onLoad])

    return <div>
        <InputLabel style={{ marginBottom: 8 }}>Spreadsheet URL</InputLabel>
        <Box display="flex" alignItems="center">
            <DebouncedTextField fullWidth
                placeholder='e.g. https://docs.google.com/spreadsheets/d/1abcd_123Hc9AiHYQ2iRo4F77nbp0_9gbOQiyqQ3uPO/edit#gid=0'
                defaultValue={spreadsheet}
                error={!!error}
                onDebounce={onChange} />

            {displaySpreadsheetLoadingState(loadedSpreadsheet)}
        </Box>

        {error && <FormHelperText error>{error}</FormHelperText>}

    </div>
}