import React from "react"
import { useDebouncedCallback } from "use-debounce"
import { DEBOUNCE_DELAY } from "../constants"
import { TextField } from "@mui/material"

/**
 * A Material UI Text that can be debounced (an expensive action deferred until the user is finished typing). The delay
 * is defined externally as a DEBOUNCE_DELAY constant.
 * It takes a function onNormalizeValue that is called for normalizing the input. It is useful for enforcing positive numbers,
 * etc.
 * This component does not integrate with Formik but instead it maintains the value in its own state.
 */
export const DebouncedTextField = ({
    defaultValue,
    onDebounce,
    onNormalizeValue,
    ...others
}) => {
    const [value, setValue] = React.useState(defaultValue)

    const [debounceCallback] = useDebouncedCallback(
        React.useCallback(
            (value) => {
                onDebounce(value)
            },
            [onDebounce]
        ),
        DEBOUNCE_DELAY
    )

    const handleChange = React.useCallback(
        (e) => {
            const value = !onNormalizeValue
                ? e.target.value
                : onNormalizeValue(e.target.value)
            setValue(value)
            debounceCallback(value)
        },
        [debounceCallback, onNormalizeValue]
    )

    return <TextField value={value} onChange={handleChange} {...others} />
}
