import { createTheme } from "@mui/material/styles"


/**
 * The main theme of DataGrab.
 */
const theme = createTheme({
    palette: {
        primary: {
            main: "hsl(220, 81%, 56%)",
            light: "hsl(220, 87%, 77%)",
            dark: "hsl(220, 84%, 36%)",

            100: "hsl(220, 92%, 97%)",
            200: "hsl(220, 91%, 89%)",
            300: "hsl(220, 87%, 77%)",
            400: "hsl(220, 84%, 67%)",
            500: "hsl(220, 81%, 56%)",
            600: "hsl(220, 82%, 44%)",
            700: "hsl(220, 84%, 36%)",
            800: "hsl(220, 84%, 27%)",
            900: "hsl(220, 95%, 18%)",
        },

        secondary: {
            main: "hsl(167, 87%, 29%)",
            light: "hsl(166, 47%, 44%)",
            dark: "hsl(166, 87%, 21%)",
        },

        grey: {
            100: "hsl(215, 49%, 96%)",
            200: "hsl(215, 45%, 90%)",
            300: "hsl(215, 34%, 81%)",
            400: "hsl(215, 28%, 70%)",
            500: "hsl(215, 20%, 56%)",
            600: "hsl(215, 23%, 46%)",
            700: "hsl(215, 25%, 35%)",
            800: "hsl(215, 28%, 27%)",
            900: "hsl(215, 35%, 12%)",
        },

        success: {
            100: "hsl(170, 81%, 95%)",
            200: "hsl(170, 75%, 83%)",
            300: "hsl(170, 69%, 72%)",
            400: "hsl(170, 65%, 60%)",
            500: "hsl(170, 63%, 48%)",
            600: "hsl(170, 67%, 40%)",
            700: "hsl(170, 71%, 33%)",
            800: "hsl(170, 77%, 25%)",
            900: "hsl(170, 85%, 17%)",
        },

        gold: {
            light: "hsl(54, 89%, 89%)"
        },

        background: {
            paper: "#fff",
        }
    },

    spacing: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768],

    borderRadius: [0, 2, 4, 8, 16, 32],

    fontSize: [0, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 74],

    typography: {
        button: {
            textTransform: "none",
        },

        fontFamily: [
            "Monda",
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
    },

    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            }
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiSelect: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    position: 'static',
                    transform: 'none'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 14
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: 'white',
                    'label + &': {
                        marginTop: 4
                    }
                },
                notchedOutline: {
                    top: 0,
                    '& legend': {
                        display: 'none'
                    }
                }
            }
        },
        MuiCard: {
            // defaultProps: {
            //     variant: 'outlined'
            // }
        },
        MuiCardHeader: {
            styleOverrides: {
                content: {
                    width: '100%'
                },
                title: {
                    fontSize: 18,
                    // fontWeight: 'bold'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 'bold'
                }
            }
        }
    },
})

export default theme
