import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";


const useStyles = makeStyles( () => ({
    loaderContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));


/**
 * Displays an animating spinner while in the loading state, then it returns its children.
 */
export const LoadingContainer = ({loading, children}) => {
    const classes = useStyles();

    return loading
        ? <div className={classes.loaderContainer}><CircularProgress /></div>
        : children;
};