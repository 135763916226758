import React from 'react'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import {Link} from "react-router-dom"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import makeStyles from '@mui/styles/makeStyles';
import {ConfirmationDialog} from "../common/ConfirmationDialog"
import {useDispatch, useSelector} from "react-redux"
import {trackAction} from "../tracking/utils"
import {ACTION_LOGOUT} from "../tracking/constants"
import {logout} from "../auth/actions"
import Logo from "../resources/icons/logo_white.svg"
import BarChartIcon from '@mui/icons-material/BarChart'
import FeedbackIcon from '@mui/icons-material/Feedback'
import PercentIcon from '@mui/icons-material/Percent'
import PeopleIcon from '@mui/icons-material/People'
import HistoryIcon from '@mui/icons-material/History'
import LogoutIcon from '@mui/icons-material/Logout'


const useStyles = makeStyles(theme => ({
    brand: {
        display: 'flex',
        width: theme.spacing(9),
        height: theme.spacing(9),
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid hsla(170, 69%, 72%, 0.5)',
    },
    logo: {
        width: theme.spacing(6),
        height: theme.spacing(6)
    },
    navItem: {
        padding: 0,
        flexFlow: 'column',
        color: theme.palette.success[300],
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        borderBottom: '1px solid hsla(170, 69%, 72%, 0.5)',
        '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: theme.palette.success[500],
            '& > .MuiListItemIcon-root': {
                color: 'white',
            },
        }
    },
    navItemButton: {
        backgroundColor: 'transparent',
        border: 0,
        borderBottom: '1px solid hsla(170, 69%, 72%, 0.5)',
        outline: 0,
        cursor: 'pointer'
    },
    navIcon: {
        color: theme.palette.success[300],
        minWidth: 0,
        fontSize: theme.fontSize[6]
    },
    navText: {
        textTransform: 'uppercase',
        '& > span': {
            fontSize: theme.fontSize[1],
            letterSpacing: 1
        },
    }
}))

/**
 * A side navigation displayed to the admin.
 *
 * @author Robert Balazsi
 */
export const AdminSideNav = ({activeTab}) => {
    const classes = useStyles()
    const email = useSelector(state => state.firebase.auth.email)
    const [logoutTriggered, setLogoutTriggered] = React.useState(false)
    const dispatch = useDispatch()

    const handleLogout = React.useCallback(() => {
        setLogoutTriggered(false)
        trackAction(email, ACTION_LOGOUT)
        dispatch(logout())
    }, [dispatch, email])

    return <div>
        <div className={classes.brand}>
            <img className={classes.logo}
                 src={Logo}
                 alt="logo"/>
        </div>

        <List disablePadding>
            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'users'} to="/admin/users">
                <ListItemIcon className={classes.navIcon}><PeopleIcon /></ListItemIcon>
                <ListItemText className={classes.navText} primary="Users"/>
            </ListItem>

            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'stats'} to="/admin/stats">
                <ListItemIcon className={classes.navIcon}><BarChartIcon /></ListItemIcon>
                <ListItemText className={classes.navText} primary="Stats"/>
            </ListItem>

            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'quotas'} to="/admin/quotas">
                <ListItemIcon className={classes.navIcon}><PercentIcon /></ListItemIcon>
                <ListItemText className={classes.navText} primary="Quotas"/>
            </ListItem>

            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'feedback'}
                      to="/admin/feedback">
                <ListItemIcon className={classes.navIcon}><FeedbackIcon /></ListItemIcon>
                <ListItemText className={classes.navText} primary="Feedback"/>
            </ListItem>

            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'logs'} to="/admin/logs">
                <ListItemIcon className={classes.navIcon}><HistoryIcon /></ListItemIcon>
                <ListItemText className={classes.navText} primary="Logs"/>
            </ListItem>

            <ListItem className={`${classes.navItem} ${classes.navItemButton}`} component="button"
                      onClick={() => setLogoutTriggered(true)}>
                <ListItemIcon className={classes.navIcon}><LogoutIcon /></ListItemIcon>
                <ListItemText className={classes.navText} primary="Logout"/>
            </ListItem>
        </List>

        {logoutTriggered && <ConfirmationDialog title="Confirm Logout"
                                                cancelButton={{label: 'No'}}
                                                submitButton={{label: 'Yes'}}
                                                onCancel={() => setLogoutTriggered(false)}
                                                onSubmit={handleLogout}
        >
            Done for now?
        </ConfirmationDialog>}
    </div>
}