import React from "react"
import { ScraperStatus } from '@datagrab/datagrab-common/constants'
import { StatusChip } from "./StatusChip"
import { formatDistanceToNow } from 'date-fns'
import { isDynamicPaginationMethod } from "@datagrab/datagrab-common/pagination"
import { FINISHED_STATES } from "./constants"


export const displayLastRunDate = (scraper) => scraper.lastFinished
    ? formatDistanceToNow(new Date(scraper.lastFinished), { addSuffix: true })
    : '- - -'

/**
 * Generates a scraper configuration to be submitted to the backend.
 * @param scraper general details about the scraper
 * @param template the template
 */
export const generateScraperConfig = ({ scraper, template }) => ({
    name: scraper.name,
    cloudEnabled: true, // We mandatorily set this to cloudEnabled as otherwise the scraper cannot be imported
    requestInterval: scraper.requestInterval,
    maxRequests: scraper.maxRequests,
    concurrency: scraper.concurrency,
    inputUrls: scraper.inputUrls,
    rendering: scraper.rendering,
    template
})

export const getURLsFromString = (str) => !str
    ? []
    : str.split(/\s+/).filter(e => !!e)

export const isJsRenderingMandatory = (config) => config && config.pagination && isDynamicPaginationMethod(config.pagination.method)

export const isFinished = (status) => FINISHED_STATES.includes(status)

export const displayScraperStatus = (scraper) => {
    const pagesScraped = !scraper.currentRun ? null : scraper.currentRun.pagesScraped
    const pagesFailed = !scraper.currentRun ? null : scraper.currentRun.pagesFailed
    const emptyPages = !scraper.currentRun ? null : scraper.currentRun.emptyPages

    return (scraper.status === ScraperStatus.RUNNING)
        ? <StatusChip status={scraper.status} pagesScraped={pagesScraped} pagesFailed={pagesFailed} emptyPages={emptyPages} />
        : <StatusChip status={scraper.status} />
}