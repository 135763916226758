import {requestAction, successAction, notifyAction} from "../saga-helpers"


export const UPDATE_USER_PROFILE = 'profile/update'
export const UPDATE_USER_PROFILE_REQUEST = requestAction(UPDATE_USER_PROFILE)
export const UPDATE_USER_PROFILE_SUCCESS = successAction(UPDATE_USER_PROFILE)

export const CHANGE_PASSWORD = 'profile/changePassword'
export const CHANGE_PASSWORD_REQUEST = requestAction(CHANGE_PASSWORD)
export const CHANGE_PASSWORD_NOTIFY = notifyAction(CHANGE_PASSWORD)

export const FETCH_USER_PROFILE = 'profile/fetchPlan'
export const FETCH_USER_PROFILE_REQUEST = requestAction(FETCH_USER_PROFILE)
export const FETCH_USER_PROFILE_SUCCESS = successAction(FETCH_USER_PROFILE)

export const CLOSE_ACCOUNT = 'account/close'
export const CLOSE_ACCOUNT_REQUEST = requestAction(CLOSE_ACCOUNT)
export const CLOSE_ACCOUNT_SUCCESS = successAction(CLOSE_ACCOUNT)


export const updateUserProfile = (data) => ({
    type: UPDATE_USER_PROFILE_REQUEST,
    data
})

export const changePassword = (data) => ({
    type: CHANGE_PASSWORD_REQUEST,
    data
})

export const fetchUserProfile = () => ({
    type: FETCH_USER_PROFILE_REQUEST
})

export const closeAccount = (data) => ({
    type: CLOSE_ACCOUNT_REQUEST,
    data
})