import { sanitizeStringForFilename } from '@datagrab/datagrab-common/utils'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API_URL } from '../../constants'
import { useScraperLiveMonitor } from '../../live-events/hooks'
import { exportTextToFile, withAuthToken } from '../../utils'
import { LogsPanel } from './LogsPanel'
import { LogRenderInfo } from '../constants'

const renderLogs = (logs) => logs.map(log => `[${new Date(log.ts).toLocaleString()}] ${LogRenderInfo[log.msg].text(log)}`)
    .join('\n')


const fetchLogs = ({ scraperID, runID, onSuccess, onError }) =>
    withAuthToken().then(token => fetch(`${API_URL}/scrapers/${scraperID}/runs/${runID}/logs`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', token }
    }).then(res => res.json())
        .then(logs => onSuccess(logs.result))
        .catch(err => onError(err)))


export const LogsPanelContainer = ({ scraper, runID }) => {
    const userId = useSelector(state => !state.profile ? undefined : state.profile.userId)
    const [logs, setLogs] = React.useState([])
    // const eventIds = logs.map(log => log.eventId)
    const [fetched, setFetched] = React.useState(false)
    const [liveEvents, setLiveEvents] = React.useState([])
    const dispatch = useDispatch()

    // Clear the live events before each run
    React.useEffect( () => {
        setLiveEvents([])
    }, [runID])

    React.useEffect(() => {
        let isMounted = true
        fetchLogs({
            scraperID: scraper.id, runID, onSuccess: (logs) => {
                if (isMounted) {
                    setLogs(logs)
                    setFetched(true)
                }
            }, onError: (err) => {
                console.error(err)
                if (isMounted) {
                    setFetched(true)
                }
            }
        })

        return () => {
            isMounted = false
        }
    }, [dispatch, scraper.id, runID])

    // Monitor live events
    const handleLiveEvent = React.useCallback((event) => {
        try {
            const eventData = JSON.parse(event)
            const { type: msg, ...data } = eventData.event
            // NOTE: Only display the live event if it doesn't overlap with the logs fetched
            // if (eventData.session.runId === runID && !eventIds.includes(eventData.event.eventId)) {
                setLiveEvents([...liveEvents, {
                    ts: eventData.ts,
                    userId,
                    msg,
                    pageStats: {
                        scraped: eventData.session.pagesScraped,
                        failed: eventData.session.pagesFailed,
                    },
                    creditsUsed: eventData.session.creditsUsed,
                    ...data
                }])
            // }
        } catch (e) {
            console.error('Error parsing live event. ', e)
        }
    }, [liveEvents, userId/*, , runIDeventIds*/])
    useScraperLiveMonitor(userId, handleLiveEvent)

    const handleDownload = React.useCallback(() => {
        fetchLogs({
            scraperID: scraper.id, runID, onSuccess: (logs) => {
                const sanitizedName = sanitizeStringForFilename(scraper.name)

                const renderedLogs = renderLogs(logs)

                exportTextToFile({ mimeType: 'text/plain', text: renderedLogs, fileName: `${sanitizedName}_logs.log` })
            }, onError: (err) => {
                console.error(err)
            }
        })
    }, [scraper, runID])

    return <LogsPanel
        fetching={!fetched}
        logs={[...logs, ...liveEvents]}
        onDownload={handleDownload} />
}