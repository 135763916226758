import { InputUrlsSource } from '@datagrab/datagrab-common/constants'
import { getSpreadsheetUrl } from '@datagrab/datagrab-common/utils'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material'
import React from 'react'
import { ModalDialog } from '../../common/ModalDialog'
import { redirectToGoogleAuth } from '../../integrations/actions'
import { getURLsFromString } from '../utils'
import { GoogleSheetLoader } from './sheets/GoogleSheetLoader'


export const ChangeInputUrlsSourceDialog = ({
    connectedAccounts,
    source,
    onClose = f => f,
    onSubmit = f => f,
    submitting
}) => {
    const [sourceType, setSourceType] = React.useState(source.source)
    const [urls, setUrls] = React.useState(source.source === InputUrlsSource.LIST ? source.list.join("\n") : '')
    const googleAccounts = !connectedAccounts ? [] : connectedAccounts.filter(ca => ca.provider === 'google')
    const accOptions = googleAccounts.length === 0
        ? [{ label: '- Select -', value: '-' }]
        : googleAccounts.map(a => ({ label: a.email, value: a.email }))
    const [account, setAccount] = React.useState(source.source === InputUrlsSource.GOOGLE_SHEET 
        ? source.account
        : googleAccounts.length > 0 ? googleAccounts[0].email : '-')
    const [spreadsheet, setSpreadsheet] = React.useState(source.source === InputUrlsSource.LIST ? '' : getSpreadsheetUrl(source.spreadsheetId))
    const [loadedSpreadsheet, setLoadedSpreadsheet] = React.useState(null)
    const [sheet, setSheet] = React.useState(source.source === InputUrlsSource.LIST ? '-' : source.sheet)
    const [column, setColumn] = React.useState(source.source === InputUrlsSource.LIST ? '' : source.column)
    const [mapper, setMapper] = React.useState(source.source === InputUrlsSource.LIST ? '' : source.mapper)
    const sourceSet = sourceType === InputUrlsSource.LIST
        ? getURLsFromString(urls).length > 0
        : account !== '-' && spreadsheet !== '' && sheet !== '-' && column !== ''
    const sheetOptions = !loadedSpreadsheet || !loadedSpreadsheet.data.sheets ? [] : loadedSpreadsheet.data.sheets.map(s => ({ label: s.title, value: s.title }))

    const handleLoadSpreadsheet = React.useCallback((loaded) => {
        setLoadedSpreadsheet(loaded)

        // Set the sheet to the first one by default
        if (loaded.data.sheets && loaded.data.sheets.length > 0) {
            setSheet(source.sheet || loaded.data.sheets[0].title)
        }
    }, [source.sheet])

    const handleSave = React.useCallback(() => {
        if (sourceType === InputUrlsSource.LIST) {
            onSubmit({
                source: InputUrlsSource.LIST,
                list: getURLsFromString(urls)
            })
        } else {
            onSubmit({
                source: InputUrlsSource.GOOGLE_SHEET,
                account,
                spreadsheetId: loadedSpreadsheet.data.id,
                spreadsheetTitle: loadedSpreadsheet.data.title,
                sheet,
                column,
                mapper: mapper !== '' ? mapper : null
            })
        }

        onClose()
    }, [onSubmit, onClose, sourceType, urls, account, loadedSpreadsheet, sheet, column, mapper])

    return <ModalDialog title="Change Input URLs"
        fullWidth
        maxWidth="sm"
        closeButton
        onClose={onClose}
        buttons={[
            <LoadingButton key="submit_button"
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSave}
                loading={submitting}
                disabled={!sourceSet}>Save</LoadingButton>
        ]}>
        <Stack spacing={5}>
            <FormControl>
                <FormLabel id="source-type-label">Source</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="source-type-label"
                    name="source-type"
                    value={sourceType}
                    onChange={e => setSourceType(e.target.value)}
                >
                    <FormControlLabel value={InputUrlsSource.LIST} control={<Radio />} label="List" />
                    <FormControlLabel value={InputUrlsSource.GOOGLE_SHEET} control={<Radio />} label="Google Sheet" />
                </RadioGroup>
            </FormControl>

            {sourceType === InputUrlsSource.LIST
                ? <TextField
                    autoFocus
                    fullWidth
                    multiline
                    rows={10}
                    name="urls"
                    label="URLs"
                    tabIndex={3}
                    helperText="Separate them with whitespace."
                    value={urls}
                    onChange={(e) => setUrls(e.target.value)}
                /> :

                <React.Fragment>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel>Account</InputLabel>
                            <Select
                                id="account"
                                value={account}
                                onChange={(e) => setAccount(e.target.value)}>
                                {accOptions.map(opt => <MenuItem key={opt.value}
                                    value={opt.value}>{opt.label}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <Button sx={{ mt: 2 }}
                            type="button"
                            variant="outlined"
                            onClick={redirectToGoogleAuth}>Add Account</Button>
                    </div>

                    {account !== '-' && <GoogleSheetLoader account={account}
                        spreadsheet={spreadsheet}
                        loadedSpreadsheet={loadedSpreadsheet}
                        onChange={setSpreadsheet}
                        onLoad={handleLoadSpreadsheet} />}

                    {loadedSpreadsheet && !loadedSpreadsheet.loading && !loadedSpreadsheet.error && <React.Fragment>
                        <FormControl fullWidth>
                            <InputLabel>Sheet</InputLabel>
                            <Select fullWidth
                                id="sheet"
                                labelId="sheet-label"
                                label="Sheet"
                                value={sheet}
                                onChange={(e) => setSheet(e.target.value)}>
                                {sheetOptions.map(opt => <MenuItem key={opt.value}
                                    value={opt.value}>{opt.label}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <TextField fullWidth
                            value={column}
                            label="Column"
                            placeholder='e.g. A'
                            onChange={(e) => setColumn(e.target.value.toUpperCase())} />

                        <TextField fullWidth
                            value={mapper}
                            label="Mapper"
                            placeholder='e.g. https://www.google.com/search?q={value}'
                            helperText={<span>Optional. Cell value can be referenced as <strong>{"{value}"}</strong>. It will be URL-encoded.</span>}
                            onChange={(e) => setMapper(e.target.value)} />
                    </React.Fragment>}
                </React.Fragment>}
        </Stack>
    </ModalDialog>
}