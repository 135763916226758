import {SW_UPDATE} from "./actions";

const app = (state = {
    swUpdated: false,
    swRegistration: null
}, action = null) => {
    if (action.type === SW_UPDATE) {
        return {
            swUpdated: !state.swUpdated,
            swRegistration: action.registration
        }
    }

    return state;
};


export default app;