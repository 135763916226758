import {maxConcurrencyForCredits} from "@datagrab/datagrab-common/products"


/**
 * Returns the maximum allowed concurrency for the user's plan, taking into account the subscription or bulk credits.
 * @param subscription the subscription
 * @param bulkCredits the number of bulk credits
 */
export const calculateMaxConcurrencyForPlan = ({subscription, bulkCredits}) => {
    if (subscription)  return subscription.concurrency
    return maxConcurrencyForCredits(bulkCredits)
}