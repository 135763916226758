import React from 'react';
import {AdminPage} from "./AdminPage";
import {DataTable} from "../common/DataTable";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FETCH_ALL_USERS_DATA, fetchAllUsersData} from "./actions";
import {apiRequestStateSelector} from "../api/selectors";
import {ACCOUNT_STATUS_DISPLAY_NAMES} from "./constants";
import {LoadingContainer} from "../common/LoadingContainer";
import { formatDistance } from 'date-fns'


const columns = [
    {
        id: 'email',
        header: 'Email',
        cell: ({row}) => <Link component={RouterLink} to={"/admin/users/" + row.id}>{row.email}</Link>,
        truncate: true,
        sortable: true
    },
    {
        id: 'fullName',
        header: 'Name',
        cell: ({row}) => row.fullName ? row.fullName : row.firstName + row.lastName,
        sortable: true
    },
    {
        id: 'accountStatus',
        header: 'Account Status',
        cell: ({row}) => ACCOUNT_STATUS_DISPLAY_NAMES[row.accountStatus],
        sortable: true
    },
    {
        id: 'dateJoined',
        header: 'Joined',
        cell: ({row}) => formatDistance(new Date(row.dateJoined * 1000), new Date()),
        sortable: true
    },
    {
        id: 'lastOnline',
        header: 'Last Online',
        cell: ({row}) => !!row.lastOnline ? formatDistance(new Date(row.lastOnline * 1000), new Date()) : '-',
        sortable: true
    },
    {
        id: 'nrScrapers',
        header: 'Scrapers',
        cell: ({row}) => row.nrScrapers,
        sortable: true
    },
    {
        id: 'nrActions',
        header: 'Actions',
        cell: ({row}) => row.nrActions,
        sortable: true
    },
    {
        id: 'totalPagesScraped',
        header: 'Pages Scraped',
        cell: ({row}) => row.totalPagesScraped,
        sortable: true
    },
];

const reqSelector = apiRequestStateSelector([FETCH_ALL_USERS_DATA]);

/**
 * Container component for displaying the users' details and stats.
 *
 * @author Robert Balazsi
 */
export const UsersListPageContainer = () => {
    const usersData = useSelector(state => state.admin.users.all.list);
    const [loading, ] = useSelector(state => reqSelector(state));

    const dispatch = useDispatch();

    React.useEffect( () => {
        dispatch( fetchAllUsersData() );
    }, [dispatch]);

    return <AdminPage activeTab="users" breadcrumbs={[{label: 'Users', link: '/admin/users'}]}>
        <LoadingContainer loading={loading}>
            <DataTable columns={columns}
                       data={usersData}
                       emptyMessage="No users signed up yet." />
        </LoadingContainer>
    </AdminPage>
};