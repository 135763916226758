import {fork} from 'redux-saga/effects';
import {createApiRequestWatcher} from "../saga-helpers";
import {API_URL} from "../constants";
import {jsonWithAuth} from "../utils";
import {FETCH_SYSTEM_PREFS} from "./actions";


const fetchSystemPrefsFlow = createApiRequestWatcher({
    actionPrefix: FETCH_SYSTEM_PREFS,

    fetcher: () => fetch(`${API_URL}/system/prefs`, {
        method: 'GET',
        headers: jsonWithAuth()
    }).then( resp => resp.json() )
});

export function* systemPrefsFlows() {
    yield fork(fetchSystemPrefsFlow);
}