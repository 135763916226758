import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Stack,
} from "@mui/material"
import React from "react"
import { styled } from "@mui/material/styles"
import GoogleSheetsIcon from "../../../resources/icons/google_sheets.svg"
import GoogleSheetsGreyIcon from "../../../resources/icons/google_sheets_grey.svg"
import { getSpreadsheetUrl } from "@datagrab/datagrab-common/utils"
import { LongLink } from "../../../common/LongLink"
import { LongText } from "../../../common/LongText"


const Icon = styled("img")(() => ({
    height: 20,
    marginRight: 8,
}))


export const OutputGoogleSheetConnector = ({
    connectedSheet,
    onConnect = (f) => f,
    onUnlink = (f) => f,
}) => (
    <Card sx={{minWidth: 300, maxWidth: 500}}>
        <CardHeader title="Output Sheet" />
        <CardContent>
            <Box display="flex" alignItems="center" mb={5}>
                <Icon
                    src={
                        connectedSheet
                            ? GoogleSheetsIcon
                            : GoogleSheetsGreyIcon
                    }
                    alt="google sheets"
                />
                <span
                    style={{
                        marginRight: 24,
                        minWidth: 0,
                        maxWidth: "100%",
                    }}
                >
                    {!connectedSheet ? (
                        "Not connected to a Google Sheet."
                    ) : (
                        <LongText>
                            Connected to
                            <Box
                                mx={2}
                                minWidth={0}
                                width="100%"
                                display="inline-flex"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                <LongLink
                                    href={getSpreadsheetUrl(
                                        connectedSheet.spreadsheetId
                                    )}
                                >
                                    {connectedSheet.spreadsheetTitle}
                                </LongLink>
                            </Box>
                        </LongText>
                    )}
                </span>
            </Box>

            {!connectedSheet ? (
                <Button onClick={onConnect} variant="outlined">
                    Connect
                </Button>
            ) : (
                <Stack direction="row" spacing={4}>
                    <Button onClick={onUnlink} variant="outlined" color="error">
                        Unlink
                    </Button>

                    <Button onClick={onConnect} variant="outlined">
                        Update
                    </Button>
                </Stack>
            )}
        </CardContent>
    </Card>
)
