import React from 'react'
import { PlanStatus } from '@datagrab/datagrab-common/constants'
import makeStyles from '@mui/styles/makeStyles'
import Box from "@mui/material/Box"
import { Metric } from "./Metric"
import { Alert, Button, Chip, Stack, Typography } from "@mui/material"
import { STATUS_CHIP_INFO } from './constants'
import { getTotalPrice } from './utils'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        color: theme.palette.grey[800],
    },
    noSubNotice: {
        marginTop: 0,
        marginBottom: theme.spacing(6)
    },
    section: {
        margin: `${theme.spacing(6)} 0`,
        // borderBottom: `1px solid ${theme.palette.grey[200]}`,

        '& > h4': {
            marginTop: 0,
            marginBottom: theme.spacing(5),
            color: theme.palette.grey[500],
            textTransform: 'uppercase'
        }
    },
    name: {
        marginTop: 0,
        color: theme.palette.grey[900],
        fontSize: theme.fontSize[5]
    },
    body: {
        fontSize: theme.fontSize[2]
    }
}))


/**
 * Displays the user's subscription, allows managing it.
 */
export const SubscriptionPanel = ({ subscription, onAddSubscription = f => f, onManageSubscription = f => f }) => {
    const classes = useStyles()

    const periodEndStr = !subscription ? null : new Date(subscription.periodEnd).toDateString()

    return <div className={classes.wrapper}>
        {!subscription
            ? <div>
                <Box mb={6}>You are not subscribed to any of the recurring plans.</Box>

                <Button variant="contained" color="primary" onClick={onAddSubscription}>Add Subscription</Button>
            </div>
            : <div>
                <h3 className={classes.name}>{subscription.name}</h3>
                <Chip color={STATUS_CHIP_INFO[subscription.status].color}
                    label={STATUS_CHIP_INFO[subscription.status].label}
                    size='small' />

                {subscription.status === PlanStatus.CANCELLING &&
                    <Box my={5}><Alert variant="outlined" severity="error">You have cancelled your subscription, but you can still renew it
                        until <strong>{periodEndStr}</strong> if you change your mind.</Alert></Box>}

                {subscription.status === PlanStatus.SUSPENDED &&
                    <Box my={5}><Alert variant="outlined" severity="error">Your subscription is suspended because the last payment had failed. Please
                        update your payment method.</Alert></Box>}

                <Box display="flex" justifyContent="space-between" my={6}>
                    <Stack spacing={6}>
                        <Metric label="Credits Used" value={`${subscription.currentUsage}/${subscription.monthlyCredits.toLocaleString()}`} />

                        {(subscription.status !== PlanStatus.CANCELLING) && <div>
                            <Metric label="Next Payment" value={`$${getTotalPrice(subscription)}`} color="success" />
                            <Typography variant="body2">on <strong>{periodEndStr}</strong></Typography>
                        </div>}
                    </Stack>

                    <Stack spacing={6}>
                        <div>
                            <Typography variant="body2">Created on <strong>{new Date(subscription.created).toDateString()}</strong></Typography>
                            <Typography variant="body2"><strong>${subscription.unitPrice}/month</strong>, billed {subscription.billingInterval}</Typography>
                            <Typography variant="body2"><strong>{subscription.monthlyCredits.toLocaleString()}</strong> credits / month</Typography>
                            <Typography variant="body2"><strong>{subscription.concurrency}</strong> concurrent requests / run</Typography>
                        </div>

                        <Button variant="outlined" color="primary" onClick={onManageSubscription}>Manage Subscription</Button>
                    </Stack>
                </Box>

            </div>}
    </div>
}
