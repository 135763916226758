import {call, fork, put, take} from 'redux-saga/effects'
import firebase from "../auth/firebase"
import {
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    syncExtensionAuthState, SOCIAL_SIGNIN_REQUEST, SOCIAL_SIGNIN_FAILURE, SOCIAL_SIGNIN_SUCCESS
} from "./actions"
import {DATAGRAB_SIGNOUT} from "./constants"
import {sendMessageToExtension} from "../utils"
import {API_URL} from "../constants"


const googleProvider = new firebase.auth.GoogleAuthProvider()


const loginFlow = function* () {
    while (true) {
        const request = yield take(LOGIN_REQUEST)

        try {
            yield call((email, password) => firebase.auth().signInWithEmailAndPassword(email, password).then(cred => {
                const user = cred.user

                // Generate a custom token and send it to the extension to sync auth state
                user.getIdToken(true).then(syncExtensionAuthState)

            }), request.email, request.password)

            yield put({type: LOGIN_SUCCESS})
        } catch (e) {
            const error = e.code
                ? e.code === 'auth/wrong-password'
                    ? {status: 401, message: 'Invalid credentials'}
                    : {status: 400, message: e.message}
                : {status: 0, message: 'Network failure'}
            yield put({type: LOGIN_FAILURE, error})
        }
    }
}

const socialSignInFlow = function* () {
    while (true) {
        const request = yield take(SOCIAL_SIGNIN_REQUEST)
        let response

        try {

            response = yield call(() => firebase.auth().signInWithPopup(googleProvider).then(response => response ))

            // If this is a new user, proceed with the signup flow
            if (response.additionalUserInfo.isNewUser) {
                const token = yield call( (resp) => resp.user.getIdToken(), response)

                const res = yield call( (token, provider) => fetch(`${API_URL}/account/signup`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', token},
                    body: JSON.stringify({provider, optedInForMarketing: true})
                })
                    .then(res => res.json()), token, request.provider)

                // If signup is successful, send a message to the extension to sync the auth state
                if (res.code === 201) {
                    yield fork(syncExtensionAuthState, token)
                }

                yield put({type: SOCIAL_SIGNIN_SUCCESS, newUser: true})
            }
            // Otherwise report sign-in success
            else {
                yield put({type: SOCIAL_SIGNIN_SUCCESS, newUser: false})
            }
        } catch (e) {
            // On error, delete the user and log out
            if (response) {
                yield call( (resp) => resp.user.delete().then( () => firebase.auth().signOut() ), response)
            }

            const error = e.code
                ? {status: 400, message: e.message}
                : {status: 0, message: 'Network failure'}

            yield put({type: SOCIAL_SIGNIN_FAILURE, error})
        }
    }
}

const logoutFlow = function* () {
    while (true) {
        yield take(LOGOUT_REQUEST)

        try {
            // Sign out and send a message to the Chrome extension to sync auth state
            yield call(() => firebase.auth().signOut().then( () => sendMessageToExtension({
                type: DATAGRAB_SIGNOUT
            })))

            yield put({type: LOGOUT_SUCCESS})
        } catch (e) {
            yield put({type: LOGOUT_FAILURE, error: e})
        }
    }
}


export function* authFlows() {
    yield fork(loginFlow)
    yield fork(socialSignInFlow)
    yield fork(logoutFlow)
}