export const UPDATE_USER_PROFILE_SUCCESS_MSG = 'Profile updated.'
export const UPDATE_USER_PROFILE_FAILURE_MSG = 'Failed to update profile.'
export const CHANGE_PASSWORD_SUCCESS_MSG = 'Password changed.'
export const CHANGE_PASSWORD_FAILURE_MSG = 'Failed to change password.'

export const CANCELLATION_REASON_OPTS = [
    {value: '-', label: '- Reason for leaving -'},
    {value: 'no_more_need', label: "No longer need it"},
    {value: 'too_expensive', label: "Too expensive"},
    {value: 'need_more_features', label: 'Need more features'},
    {value: 'poor_quality', label: 'Poor product quality'},
    {value: 'poor_support', label: 'Poor customer support'},
    {value: 'need_more_trial_credits', label: 'Need more free trial credits'},
    {value: 'found_alternative', label: 'Found an alternative'},
]
