import {requestAction, successAction} from "../saga-helpers"
import {sendMessageToExtension} from "../utils"
import {LOAD_SCRAPER_FOR_SETUP} from "./constants"

export const FETCH_ALL_SCRAPERS = 'scrapers/fetchAll'
export const FETCH_ALL_SCRAPERS_REQUEST = requestAction(FETCH_ALL_SCRAPERS)
export const FETCH_ALL_SCRAPERS_SUCCESS = successAction(FETCH_ALL_SCRAPERS)

export const FETCH_SCRAPER = 'scrapers/fetchOne'
export const FETCH_SCRAPER_REQUEST = requestAction(FETCH_SCRAPER)
export const FETCH_SCRAPER_SUCCESS = successAction(FETCH_SCRAPER)

export const CREATE_SCRAPER = 'scraper/create'
export const CREATE_SCRAPER_REQUEST = requestAction(CREATE_SCRAPER)
export const CREATE_SCRAPER_SUCCESS = successAction(CREATE_SCRAPER)

export const START_SCRAPER = 'scrapers/start'
export const START_SCRAPER_SUCCESS = successAction(START_SCRAPER)
export const START_SCRAPER_REQUEST = requestAction(START_SCRAPER)

export const EXPORT_RUN_DATA = 'scrapers/exportRunData'
export const EXPORT_RUN_DATA_REQUEST = requestAction(EXPORT_RUN_DATA)

export const STOP_SCRAPER = 'scrapers/stop'
export const STOP_SCRAPER_SUCCESS = successAction(STOP_SCRAPER)
export const STOP_SCRAPER_REQUEST = requestAction(STOP_SCRAPER)

export const UPDATE_SCRAPER_DETAILS = 'scrapers/updateDetails'
export const UPDATE_SCRAPER_DETAILS_REQUEST = requestAction(UPDATE_SCRAPER_DETAILS)
export const UPDATE_SCRAPER_DETAILS_SUCCESS = successAction(UPDATE_SCRAPER_DETAILS)

export const DELETE_SCRAPER = 'scrapers/delete'
export const DELETE_SCRAPER_REQUEST = requestAction(DELETE_SCRAPER)
export const DELETE_SCRAPER_SUCCESS = successAction(DELETE_SCRAPER)

export const SELECT_SCRAPER_RUN = 'logs/selectRun'


export const fetchAllScrapers = () => ({
    type: FETCH_ALL_SCRAPERS_REQUEST
})

export const fetchScraper = (scraperID) => ({
    type: FETCH_SCRAPER_REQUEST,
    data: {scraperID}
})

export const createScraper = ({scraper, start}) => ({
    type: CREATE_SCRAPER_REQUEST,
    data: {scraper, start}
})

export const startScraper = (scraperID) => ({
    type: START_SCRAPER_REQUEST,
    data: {scraperID}
})

export const exportRunData = ({scraperID, scraperName, runID, format}) => ({
    type: EXPORT_RUN_DATA_REQUEST,
    data: {scraperID, scraperName, runID, format}
})

export const stopScraper = (scraperID) => ({
    type: STOP_SCRAPER_REQUEST,
    data: {scraperID}
})

export const deleteScraper = (id) => ({
    type: DELETE_SCRAPER_REQUEST,
    data: {id}
})

export const updateScraperGeneralDetails = (scraperID, details) => ({
    type: UPDATE_SCRAPER_DETAILS_REQUEST,
    data: {scraperID, details}
})

export const loadScraperForSetup = (scraper) => {
    sendMessageToExtension(({
        type: LOAD_SCRAPER_FOR_SETUP,
        scraper
    }))
}

export const selectScraperRun = ({scraperID, runID}) => ({
    type: SELECT_SCRAPER_RUN,
    scraperID,
    runID
})