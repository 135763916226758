import React from 'react'
import { AdminPage } from "./AdminPage"
import { useDispatch, useSelector } from "react-redux"
import { UserProfilePanel } from "./UserProfilePanel"
import { UserScrapersPanel } from "./UserScrapersPanel"
import { UserActivityPanel } from "./UserActivityPanel"
import { fetchUserData } from "./actions"
import { Stack } from '@mui/material'


export const UserDetailsPageContainer = ({ match }) => {
    const { params: { id } } = match
    const dispatch = useDispatch()

    // Refresh the user's details
    React.useEffect(() => {
        if (!!id) {
            dispatch(fetchUserData(id))
        }
    }, [dispatch, id])

    const user = useSelector(state => state.admin.users.all.list.find(s => s.id === id))

    const breadcrumbs = [{ label: "Users", link: '/admin/users' }]
    if (user) {
        breadcrumbs.push({ label: user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`, link: `/admin/users/${user.id}` })
    } else {
        return <AdminPage activeTab="users" breadcrumbs={breadcrumbs}>
            User not found.
        </AdminPage>
    }

    return <AdminPage activeTab="users" breadcrumbs={breadcrumbs}>
        <Stack spacing={9}>
            <UserProfilePanel user={user} />

            <UserScrapersPanel userID={id} />

            <UserActivityPanel userID={id} />
        </Stack>
    </AdminPage>
}