import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {List, ListItem, ListItemIcon} from '@mui/material'
import {Link} from "react-router-dom"
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import MapIcon from '@mui/icons-material/Map'
import SettingsIcon from '@mui/icons-material/Settings'
import {Robot as RobotIcon} from '../resources/icons'


const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'fixed',
        zIndex: 9999,
        bottom: 0,
        width: '100%',
        height: theme.spacing(8),
        backgroundColor: theme.palette.primary[100],
        borderTop: `1px solid hsla(220, 91%, 89%, 0.5)`,
    },
    navList: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%'
    },
    navItem: {
        padding: 0,
        flexFlow: 'column',
        color: theme.palette.grey[600],
        '& > .MuiListItemIcon-root': {
            minWidth: 0
        },
        '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: theme.palette.primary[600],
            backgroundColor: 'transparent',
            '& > .MuiListItemIcon-root': {
                color: theme.palette.primary[500]
            },

            '& svg': {
                stroke: theme.palette.primary[500]
            }
        }
    },
    navItemButton: {
        backgroundColor: 'transparent',
        border: 0,
        outline: 0,
        cursor: 'pointer'
    },
    navIcon: {
        color: theme.palette.grey[500],
        minWidth: 0,
        fontSize: theme.fontSize[6],

        '& > svg': {
            stroke: theme.palette.grey[500],
            width: theme.spacing(6),
            height: theme.spacing(6),
        }
    }
}))

/**
 * Sticky navigation bar display on the bottom of the user's panel on mobile resolutions.
 */
export const UserMobileNav = ({activeTab}) => {
    const classes = useStyles()

    return <div className={classes.wrapper}>
        <List className={classes.navList} disablePadding>
            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'scrapers'} to="/scrapers">
                <ListItemIcon className={classes.navIcon}><RobotIcon /></ListItemIcon>
            </ListItem>

            <ListItem className={classes.navItem} component="a" selected={activeTab === 'guide'}
                      href="https://datagrab.io/guide">
                <ListItemIcon className={classes.navIcon}><HelpCenterIcon /></ListItemIcon>
            </ListItem>

            <ListItem className={classes.navItem} component="a" selected={activeTab === 'roadmap'}
                      href="https://trello.com/b/cltji3sP/datagrab-roadmap">
                <ListItemIcon className={classes.navIcon}><MapIcon /></ListItemIcon>
            </ListItem>

            <ListItem className={classes.navItem} component={Link} selected={activeTab === 'settings'} to="/settings">
                <ListItemIcon className={classes.navIcon}><SettingsIcon /></ListItemIcon>
            </ListItem>
        </List>
    </div>
}