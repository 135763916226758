import React from 'react';
import {ModalDialog} from "./ModalDialog";
import Button from "@mui/material/Button"


const DEFAULTS = {
    title: 'Confirm',

    cancelButton: {
        variant: 'outlined',
        color: 'primary',
        label: 'No'
    },

    submitButton: {
        variant: 'contained',
        color: 'primary',
        label: 'Yes'
    },
};

/**
 * A confirmation dialog to be displayed on the center of the screen. It has a cancel and a submit button (both of which
 * can be customized - their variants, colors and labels).
 *
 * @author Robert Balazsi
 */
export const ConfirmationDialog = ({title, cancelButton, submitButton, onCancel, onSubmit, children}) => {
    const cancelVariant = !!cancelButton && !!cancelButton.variant ? cancelButton.variant : DEFAULTS.cancelButton.variant;
    const cancelColor = !!cancelButton && !!cancelButton.color ? cancelButton.color : DEFAULTS.cancelButton.color;
    const cancelLabel = !!cancelButton && !!cancelButton.label ? cancelButton.label : DEFAULTS.cancelButton.label;

    const submitVariant = !!submitButton && !!submitButton.variant ? submitButton.variant : DEFAULTS.submitButton.variant;
    const submitColor = !!submitButton && !!submitButton.color ? submitButton.color : DEFAULTS.submitButton.color;
    const submitLabel = !!submitButton && !!submitButton.label ? submitButton.label : DEFAULTS.submitButton.label;
    const submitDanger = !submitButton ? false : submitButton.danger;
    const submitDisabled = !submitButton ? false : submitButton.disabled;

    return <ModalDialog
                   title={!!title ? title : DEFAULTS.title}
                   onClose={onCancel}
                   buttons={[
                       <Button key="cancel_button" variant={cancelVariant} color={cancelColor}
                               onClick={onCancel}>{cancelLabel}</Button>,
                       <Button key="submit_button" variant={submitVariant}
                               disabled={submitDisabled}
                               color={submitDanger ? 'error' : submitColor}
                               onClick={onSubmit}>{submitLabel}</Button>
                   ]}>
        {children}
    </ModalDialog>
};