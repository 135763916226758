import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from "@mui/material/Box/Box"
import Container from "@mui/material/Container"
import { Stack } from '@mui/material'


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(6)
    },
    icon: {
        fontSize: '8rem',
        color: 'hsla(215, 45%, 92%, 0.7)'
    },
    title: {
        marginTop: theme.spacing(3),
        marginBottom: 0,
        textAlign: 'center',
        fontSize: theme.fontSize[6],
        fontWeight: 400,
        color: theme.palette.grey[900]
    },
    content: {
        margin: 0,
        textAlign: 'justify',
        fontSize: theme.fontSize[3],
        color: theme.palette.grey[800],
        lineHeight: 1.5,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    }
}))

/**
 * Displays a panel on an empty state of some component. The panel includes an icon, a title, the content, and optionally,
 * action buttons.
 */
export const EmptyStatePanel = ({ icon, title, content, actions, ...others }) => {
    const classes = useStyles()

    return <Container maxWidth="xs" {...others}>
        <Stack spacing={6} alignItems="center">
            <div>
                <Box display="flex" justifyContent="center">{icon}</Box>

                <h1 className={classes.title}>{title}</h1>
            </div>

            <div className={classes.content}>{content}</div>

            {actions && <div className={classes.center}>{actions}</div>}
        </Stack>
    </Container>
}