import React from 'react'
import { DataTable } from "../common/DataTable"
import { useDispatch, useSelector } from "react-redux"
import { SCRAPER_STATUS_DISPLAY_NAMES } from "../scrapers/constants"
import { fetchUserScrapers } from "./actions"
import { exportTextToFile } from "../utils"
import Button from "@mui/material/Button"
import { Card, CardContent, CardHeader } from '@mui/material'
import { formatDistance } from 'date-fns'


const columns = [
    {
        id: 'name',
        header: 'Name',
        cell: ({ row }) => row.name,
        sortable: true
    },
    {
        id: 'created',
        header: 'Created',
        cell: ({ row }) => formatDistance(new Date(row.created * 1000), new Date()),
        sortable: true
    },
    {
        id: 'status',
        header: 'Status',
        cell: ({ row }) => SCRAPER_STATUS_DISPLAY_NAMES[row.status],
        sortable: true
    },
    {
        id: 'runs',
        header: 'Runs',
        cell: ({ row }) => row.runs.length,
        sortable: true
    },
    {
        id: 'totalPagesScraped',
        header: 'Pages Scraped',
        cell: ({ row }) => row.runs.map(run => run.statistics.scraped).reduce((a, b) => a + b, 0),
        sortable: true
    },
    {
        id: 'config',
        header: 'Config',
        cell: ({ row }) => <Button variant="outlined" size="small" color="primary" onClick={() => exportTextToFile({ mimeType: 'application/json', text: JSON.stringify(row.config), fileName: 'config.json' })}>Download</Button>,
        sortable: false
    }
]

/**
 * Displays a table with information about a user's scraping scrapers. Used on the admin panel.
 */
export const UserScrapersPanel = ({ userID }) => {

    const dispatch = useDispatch()

    // Refresh the user's scrapers on mount
    React.useEffect(() => {
        if (!!userID) {
            dispatch(fetchUserScrapers(userID))
        }
    }, [dispatch, userID])

    const userScrapers = useSelector(state => state.admin.users.current.scrapers)

    return <Card>
        <CardHeader title="Scrapers" />
        <CardContent>
            <DataTable columns={columns} data={userScrapers} emptyMessage="There are no scrapers set up yet." />
        </CardContent>
    </Card>
}