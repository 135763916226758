import React from 'react'
import {Button, Typography, Grid} from "@mui/material"
import Box from "@mui/material/Box"
import {Link as RouterLink} from "react-router-dom"
import {BaseAccountPage} from "./BaseAccountPage"
import {CHROME_EXTENSION_STORE_URL} from "../scrapers/constants"


/**
 * Displays a "thank you" message when the user signs up.
 *
 * @param social whether the sign-up was via Google, in which case we don't display a notification to confirm the email (the email is verified by default)
 */
export const SignupSuccessfulPage = ({social}) => <BaseAccountPage title='Welcome aboard!' maxWidth="xs">
    <Grid column spacing={6}>
        <Typography variant="body1">Thanks for signing up!</Typography>

        <Typography variant="body1" align="justify">
            {!social
                ? "We have just sent a confirmation email to the address you provided. Please follow the link in the email to activate your account."
                : <span>You're now ready to scrape some data. Use the <a href={CHROME_EXTENSION_STORE_URL} target="_blank" rel="noopener noreferrer">Chrome extension</a> to set up scrapers, then manage them from here.</span>}
        </Typography>

        <Box display="flex" justifyContent="center" mt={6}>
            <Button variant="contained" color="primary" component={RouterLink} to="/">Let's get started</Button>
        </Box>
    </Grid>
</BaseAccountPage>