
// Constants for Backend actions to track
export const ACTION_CREATE_SCRAPER = 'CREATE_SCRAPER';
export const ACTION_UPDATE_SCRAPER = 'UPDATE_SCRAPER';
export const ACTION_START_SCRAPER = 'START_SCRAPER';
export const ACTION_STOP_SCRAPER = 'STOP_SCRAPER';
export const ACTION_DELETE_SCRAPER = 'DELETE_SCRAPER';
export const ACTION_DATA_PREVIEW = 'DATA_PREVIEW';

// Constants for Frontend actions to track
export const ACTION_CLICKED_CREATE_SCRAPER = 'CLICKED_CREATE_SCRAPER';
export const ACTION_CANCEL_SCRAPER_SETUP = 'CANCELLED_SCRAPER_SETUP';
export const ACTION_SELECTED_TEMPLATE = 'SELECTED_TEMPLATE';
export const ACTION_TOGGLED_TOOL = 'TOGGLED_TOOL';
export const ACTION_PREV_STEP = 'PREV_STEP';
export const ACTION_NEXT_STEP = 'NEXT_STEP';
export const ACTION_TOGGLED_PREVIEW = 'TOGGLED_PREVIEW';
export const ACTION_SUBMIT_SCRAPER = 'SUBMIT_SCRAPER';
export const ACTION_SUBMIT_FIELD_SAVE = 'SUBMIT_FIELD_SAVE';
export const ACTION_CANCEL_FIELD_SAVE = 'CANCEL_FIELD_SAVE';
export const ACTION_MOVE_FIELD = 'MOVE_FIELD';
export const ACTION_DELETE_FIELD = 'DELETE_FIELD';
export const ACTION_SET_TEMPLATE_PROP = 'SET_TEMPLATE_PROP';
export const ACTION_SUBMIT_NEXT_PAGE_LINK = 'SUBMIT_NEXT_PAGE_LINK';
export const ACTION_CANCEL_NEXT_PAGE_LINK = 'CANCEL_NEXT_PAGE_LINK';
export const ACTION_SET_URLS = 'SET_URLS';
export const ACTION_NEW_DETAIL_PAGE_TEMPLATE = 'NEW_DETAIL_PAGE_TEMPLATE';
export const ACTION_EXPORT_DATA = 'EXPORT_DATA';
export const ACTION_LOGOUT = 'LOGOUT';