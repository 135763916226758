/**
 * Defines global constants.
 */

export const API_VERSION = 'v1'
export const VERSIONED_JSON_MIME_TYPE = `application/vnd.datagrab.io-${API_VERSION}+json`
const devPort = process.env.PORT || 3000
export const PORTAL_URL = process.env.NODE_ENV === 'development' ? `http://localhost:${devPort}` : process.env.PUBLIC_URL
export const API_URL = process.env.REACT_APP_API_URL

// The key used by redux-persist for storing the state in the local storage
export const APP_PERSIST_KEY = 'datagrab'

// Error codes
export const ERROR_USER_DISABLED = 'User is disabled'

// The delay, in milliseconds, before a debounced action is triggered
export const DEBOUNCE_DELAY = 700

// The default export format
export const DEFAULT_EXPORT_FORMAT = 'csv'

export const EXTENSION_NOT_INSTALLED = 'not_installed'