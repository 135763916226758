import {requestAction, successAction} from "../saga-helpers";
import {MAX_REQUESTS_KEY} from "./constants";

export const FETCH_ALL_USERS_DATA = 'admin/fetchAllUsersData';
export const FETCH_ALL_USERS_DATA_REQUEST = requestAction(FETCH_ALL_USERS_DATA);
export const FETCH_ALL_USERS_DATA_SUCCESS = successAction(FETCH_ALL_USERS_DATA);

export const FETCH_USER_DATA = 'admin/fetchUserData';
export const FETCH_USER_DATA_REQUEST = requestAction(FETCH_USER_DATA);
export const FETCH_USER_DATA_SUCCESS = successAction(FETCH_USER_DATA);

export const FETCH_SYSTEM_STATS = 'admin/fetchSystemStats';
export const FETCH_SYSTEM_STATS_REQUEST = requestAction(FETCH_SYSTEM_STATS);
export const FETCH_SYSTEM_STATS_SUCCESS = successAction(FETCH_SYSTEM_STATS);

export const FETCH_QUOTAS = 'admin/fetchQuotas';
export const FETCH_QUOTAS_REQUEST = requestAction(FETCH_QUOTAS);
export const FETCH_QUOTAS_SUCCESS = successAction(FETCH_QUOTAS);

export const UPDATE_QUOTAS = 'admin/updateQuotas';
export const UPDATE_QUOTAS_REQUEST = requestAction(UPDATE_QUOTAS);

export const FETCH_USER_SCRAPERS = 'admin/fetchUserScrapers';
export const FETCH_USER_SCRAPERS_REQUEST = requestAction(FETCH_USER_SCRAPERS);
export const FETCH_USER_SCRAPERS_SUCCESS = successAction(FETCH_USER_SCRAPERS);

export const FETCH_USER_ACTIVITY = 'admin/fetchUserActivity';
export const FETCH_USER_ACTIVITY_REQUEST = requestAction(FETCH_USER_ACTIVITY);
export const FETCH_USER_ACTIVITY_SUCCESS = successAction(FETCH_USER_ACTIVITY);

export const FETCH_USER_FEEDBACK = 'admin/fetchUserFeedback';
export const FETCH_USER_FEEDBACK_REQUEST = requestAction(FETCH_USER_FEEDBACK);
export const FETCH_USER_FEEDBACK_SUCCESS = successAction(FETCH_USER_FEEDBACK);

export const FETCH_ALL_LOGS = 'admin/fetchAllLogs';
export const FETCH_ALL_LOGS_REQUEST = requestAction(FETCH_ALL_LOGS);
export const FETCH_ALL_LOGS_SUCCESS = successAction(FETCH_ALL_LOGS);


export const fetchAllUsersData = () => ({
    type: FETCH_ALL_USERS_DATA_REQUEST
});

export const fetchUserData = (id) => ({
    type: FETCH_USER_DATA_REQUEST,
    data: id
});

export const fetchSystemStats = () => ({
    type: FETCH_SYSTEM_STATS_REQUEST
});

export const fetchQuotas = () => ({
    type: FETCH_QUOTAS_REQUEST
});

export const updateQuotas = (data) => ({
    type: UPDATE_QUOTAS_REQUEST,
    data: {
        [MAX_REQUESTS_KEY]: data.maxRequests
    }
});

export const fetchUserScrapers = (userID) => ({
    type: FETCH_USER_SCRAPERS_REQUEST,
    data: userID
});

export const fetchUserActivity = (userID) => ({
    type: FETCH_USER_ACTIVITY_REQUEST,
    data: userID
});

export const fetchUserFeedback = () => ({
    type: FETCH_USER_FEEDBACK_REQUEST
});

export const fetchAllLogs = () => ({
    type: FETCH_ALL_LOGS_REQUEST
});