import {FETCH_SYSTEM_PREFS_SUCCESS} from "./actions";

const systemPrefs = (state = {}, action = null) => {
    if (action.type === FETCH_SYSTEM_PREFS_SUCCESS) {
        return action.result;
    }

    return state;
};


export default systemPrefs;