import React from 'react'
import {DataTable} from "../common/DataTable"
import Link from "@mui/material/Link/Link"
import {Link as RouterLink} from "react-router-dom"
import {ActionsDropdown} from "./ActionsDropdown"
import {displayLastRunDate, displayScraperStatus} from "./utils"


const columns = ({startDisabled, onStart, onStop, onClone, onDelete}) => [
    {
        id: 'name',
        header: 'Name',
        width: 300,
        truncate: true,
        cell: ({row}) => <Link component={RouterLink} to={`/scrapers/${row.id}`}>{row.name}</Link>,
        sortable: true
    },
    {
        id: 'status',
        header: 'Status',
        align: 'center',
        width: 200,
        cell: ({row}) => displayScraperStatus(row),
        sortable: true
    },
    {
        id: 'lastFinished',
        header: 'Last Run',
        width: 150,
        cell: ({row}) => displayLastRunDate(row),
        sortable: true
    },
/*{
        id: 'nextRun',
        header: 'Next Run',
        width: 150,
        cell: ({row}) => calculateNextRun(row),
        render: (cell) => !cell ? "- - -" : cell.fromNow(),
        sortable: true,
        sortFunc: (a, b) => {
            if (!a && !b)  return 0;
            if (!a)  return 1;
            if (!b)  return -1;

            return a.diff(b);
        }
    },*/
    {
        id: 'actionsMenu',
        width: 40,
        cell: ({row}) => <ActionsDropdown scraper={row}
                                          startDisabled={startDisabled}
                                          onStart={onStart}
                                          onStop={onStop}
                                          onClone={onClone}
                                          onDelete={onDelete}/>
    }
]

/**
 * Display the list of scrapers in a table on SM resolution and higher. It allows starting/stopping and deleting them.
 *
 * @param scrapers the list of scrapers
 * @param startDisabledReason if starting scrapers is disabled, this will hold the reason for it
 * @param onStart called when the user starts a scraper
 * @param onStop called when the user stops a scraper
 * @param onClone called when the user clones a scraper
 * @param onDelete called when the user deletes a scraper
 */
export const ScrapersTable = ({scrapers, startDisabled, onStart, onStop, onClone, onDelete}) =>
    <DataTable columns={columns({startDisabled, onStart, onStop, onClone, onDelete})}
               data={scrapers}
               defaultSort={{by: 'lastFinished', order: 'desc'}}
               emptyMessage="There are no scrapers set up yet."/>