import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {SigninPage} from "../auth/SigninPage"
import {SignupPage} from "../account/SignupPage"
import {ForgotPasswordPage} from "../account/ForgotPasswordPage"
import ScrapersListPageContainer from "../scrapers/ScrapersListPageContainer"
import ScraperDetailsPageContainer from "../scrapers/details/ScraperDetailsPageContainer"
import {ImportScraperPageContainer} from "../scrapers/import/ImportScraperPageContainer"
import {connectedRouterRedirect} from "redux-auth-wrapper/history4/redirect"
import {hasAdminRole} from "../utils"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper"
import {UsersListPageContainer} from "../admin/UsersListPageContainer"
import {GlobalStatsPageContainer} from "../admin/GlobalStatsPageContainer"
import {QuotasPageContainer} from "../admin/QuotasPageContainer"
import {UserDetailsPageContainer} from "../admin/UserDetailsPageContainer"
import {UserFeedbackPageContainer} from "../admin/UserFeedbackPageContainer"
import {LogsPageContainer} from "../admin/LogsPageContainer"
import {AccountSettingsPage} from "../settings/AccountSettingsPage"
import {BillingPage} from "../settings/billing/BillingPage"
import {ThankYouPage} from "./ThankYouPage"
import {LoadingContainer} from "../common/LoadingContainer"
import {useSelector} from "react-redux"
import {SignupSuccessfulPage} from "../account/SignupSuccessfulPage"


const locationHelper = locationHelperBuilder({})

const isNotAuthenticated = connectedRouterRedirect({
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false,
    authenticatedSelector: state => state.firebase.auth.isEmpty,
    wrapperDisplayName: 'UserIsNotAuthenticated'
})

const isAuthenticated = connectedRouterRedirect({
    authenticatedSelector: state => !state.firebase.auth.isEmpty,
    redirectPath: '/signin',
    wrapperDisplayName: 'UserIsAuthenticated'
})

const isAdmin = connectedRouterRedirect({
    authenticatedSelector: () => hasAdminRole(),
    redirectPath: '/',
    wrapperDisplayName: 'UserIsAdmin',
    allowRedirectBack: false
})

const isNotAdmin = connectedRouterRedirect({
    authenticatedSelector: () => !hasAdminRole(),
    redirectPath: '/admin/users',
    wrapperDisplayName: 'UserIsNotAdmin',
    allowRedirectBack: false
})

/**
 * The main app component. It handles routing.
 *
 * @author Robert Balazsi
 */
export const App = () => {
    const auth = useSelector(state => state.firebase.auth)
    const {signupSuccessful, social} = useSelector(state => state.account.notify)

    return <LoadingContainer loading={!auth.isLoaded}>
        {signupSuccessful
            ? <SignupSuccessfulPage social={social}/>
            : <Switch>
                <Route path="/signin" component={isNotAuthenticated(SigninPage)}/>
                <Route path="/signup" component={isNotAuthenticated(SignupPage)}/>
                <Route path="/forgot" component={isNotAuthenticated(ForgotPasswordPage)}/>

                <Route exact path="/scrapers" component={isAuthenticated(isNotAdmin(ScrapersListPageContainer))}/>
                <Route exact path="/scrapers/import" component={isAuthenticated(isNotAdmin(ImportScraperPageContainer))}/>
                <Route path="/scrapers/:id" component={isAuthenticated(isNotAdmin(ScraperDetailsPageContainer))}/>

                <Route exact path="/settings/account" component={isAuthenticated(isNotAdmin(AccountSettingsPage))}/>
                <Route exact path="/settings/billing" component={isAuthenticated(isNotAdmin(BillingPage))}/>
                <Route path="/settings">
                    <Redirect to="/settings/account"/>
                </Route>

                <Route path="/thank-you" component={isAuthenticated(isNotAdmin(ThankYouPage))}/>

                <Route exact path="/admin/users" component={isAuthenticated(isAdmin(UsersListPageContainer))}/>
                <Route path="/admin/users/:id" component={isAuthenticated(isAdmin(UserDetailsPageContainer))}/>
                <Route exact path="/admin/stats" component={isAuthenticated(isAdmin(GlobalStatsPageContainer))}/>
                <Route exact path="/admin/quotas" component={isAuthenticated(isAdmin(QuotasPageContainer))}/>
                <Route exact path="/admin/feedback" component={isAuthenticated(isAdmin(UserFeedbackPageContainer))}/>
                <Route exact path="/admin/logs" component={isAuthenticated(isAdmin(LogsPageContainer))}/>

                <Redirect to="/scrapers"/>
            </Switch>}
    </LoadingContainer>
}