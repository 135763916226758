import { Alert } from '@mui/material'
import React from 'react'
import {CHROME_EXTENSION_STORE_URL} from "../scrapers/constants"


/**
 * Displays an info alert to install the Chrome extension.
 * @param props properties
 */
export const ExtensionMissingAlert = (props) => <Alert variant="outlined" severity="warning" {...props}>
    The <a href={CHROME_EXTENSION_STORE_URL} target="_blank" rel="noopener noreferrer">Chrome extension</a> is missing. Please install it to set up scrapers.
</Alert>