import {fork} from 'redux-saga/effects'
import {createApiRequestWatcher} from "../saga-helpers"
import {API_URL} from "../constants"
import {withAuthToken} from "../utils"
import {SEND_FEEDBACK} from "./actions"


const sendFeedbackFlow = createApiRequestWatcher({
    actionPrefix: SEND_FEEDBACK,

    fetcher: (data) => withAuthToken().then(token => fetch(`${API_URL}/feedback`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', token},
        body: JSON.stringify(data)
    }).then(resp => resp.json()))
})


export function* feedbackFlows() {
    yield fork(sendFeedbackFlow)
}