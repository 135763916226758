import React from 'react'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',

        '& > span': {
            color: theme.palette.grey[500],
            fontSize: theme.fontSize[2],
            textTransform: 'uppercase',
            lineHeight: 1,
            alignItems: 'center',
            flex: '0.2 0 auto',
            textAlign: 'center'
        },

        '&::before, &::after': {
            content: '""',
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
            flex: '1 0 auto',
        }
    },
}))


/**
 * A horizontal separator line labeled "OR" in the middle.
 */
export const OptionsSeparator = () => {
    const classes = useStyles()

    return <div className={classes.root}>
        <span>or</span>
    </div>
}