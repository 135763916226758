import {failureAction, requestAction, successAction} from "../saga-helpers"
import {API_URL} from "../constants"
import {sendMessageToExtension} from "../utils"
import {DATAGRAB_SIGNIN} from "./constants"


export const LOGIN = 'login'
export const LOGIN_REQUEST = requestAction(LOGIN)
export const LOGIN_SUCCESS = successAction(LOGIN)
export const LOGIN_FAILURE = failureAction(LOGIN)

export const SOCIAL_SIGNIN = 'signin/social'
export const SOCIAL_SIGNIN_REQUEST = requestAction(SOCIAL_SIGNIN)
export const SOCIAL_SIGNIN_SUCCESS = successAction(SOCIAL_SIGNIN)
export const SOCIAL_SIGNIN_FAILURE = failureAction(SOCIAL_SIGNIN)

export const LOGOUT = 'logout'
export const LOGOUT_REQUEST = requestAction(LOGOUT)
export const LOGOUT_SUCCESS = successAction(LOGOUT)
export const LOGOUT_FAILURE = failureAction(LOGOUT)

export const login = (email, password) => ({
    type: LOGIN_REQUEST,
    email,
    password
})

export const socialSignIn = (provider) => ({
    type: SOCIAL_SIGNIN_REQUEST,
    provider
})

export const logout = () => ({
    type: LOGOUT_REQUEST
})

export const syncExtensionAuthState = (token) => fetch(`${API_URL}/account/customToken`, {
    headers: {token}
}).then(res => res.json())
    .then(data => sendMessageToExtension({
        type: DATAGRAB_SIGNIN,
        token: data.result
    }))