import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import RobotPortrait from "../resources/icons/robot_portrait.svg"
import {EmptyStatePanel} from "../common/EmptyStatePanel"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom"
import {CHROME_EXTENSION_STORE_URL} from "./constants"


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(9),

    },
    icon: {
        fontSize: '8rem',
        color: 'hsla(215, 45%, 92%, 0.7)'
    },
    title: {
        margin: 0,
        textAlign: 'center',
        fontSize: theme.fontSize[6],
        fontWeight: 400,
        color: theme.palette.grey[900]
    },
    body: {
        margin: 0,
        textAlign: 'justify',
        fontSize: theme.fontSize[3],
        color: theme.palette.grey[600],
        lineHeight: 1.5,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }
}))

/**
 * Display content on the empty state of the scrapers list (when there are no scrapers created yet).
 * This includes some supporting text about how to get started and a CTA to create a scraper.
 *
 * Note that setting up scrapers is not supported on mobile resolutions. So in this case, different supporting text is
 * displayed and no CTA.
 */
export const EmptyScrapersList = () => {
    const classes = useStyles()

    return <EmptyStatePanel icon={<img src={RobotPortrait} alt="robot"/>}
                            title="Scrapers"
                            content={<div>
                                <p>Scrapers are the DataGrab's workhorses. They configure what to extract and how to paginate.</p>
                                <p>Use the <a className={classes.link} href={CHROME_EXTENSION_STORE_URL} target="_blank" rel="noopener noreferrer">Chrome extension</a> to set them up and upload them. Or you can also import them from here.</p>
                            </div>}
                            actions={<Button type="button"
                                             variant="contained"
                                             component={Link}
                                             color="primary"
                                             to="/scrapers/import">Import Scraper</Button>}
                            className={classes.wrapper}/>
}